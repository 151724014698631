import React from "react";
import GeovetraHeroSvg from "../../components/atoms/GeovetraHeroSvg";
import PrimaryButton from "../../components/atoms/PrimaryButton";
import { openModal } from "../../redux/reducers/uiReducer";
import { useDispatch } from "react-redux";

interface HeroSectionProps {}

const HeroSection: React.FC<HeroSectionProps> = () => {
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col-reverse md:flex-row justify-between w-full items-center max-w-7xl z-10">
      <div className="content-center justify-center items-center md:items-start text-center md:text-start flex flex-col gap-2 px-4 md:gap-4 mb-8  bg-white ">
        <p className="text-base md:text-3xl font-semibold">
          Delivery Logistics Efficiency Enabler
        </p>
        <p className="text-slate-500 font-medium text-sm md:text-xl max-w-xl">
          Lowering the cost of logistics operations & Hawkeye visibility of
          deliveries is the key to Happier Customer Deliveries
        </p>
        <PrimaryButton
          onClick={() => {
            dispatch(openModal("RegisterModal"));
          }}
        >
          Let's Start{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="3"
            stroke="currentColor"
            className="w-4 md:w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
            />
          </svg>
        </PrimaryButton>
      </div>
      <div className="is-align-content-center is-justify-content-center is-flex">
        <GeovetraHeroSvg className="h-[200px] md:h-[600px]" />
      </div>
    </div>
  );
};

export default HeroSection;
