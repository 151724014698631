import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormMode } from "../../types/CommonType";

const INITIAL_STATE = {
  formMode: "create" as FormMode,
};

const formModeReducer = createSlice({
  name: "formModeReducer",
  initialState: INITIAL_STATE,
  reducers: {
    setFormMode: (state, action: PayloadAction<FormMode>) => {
      state.formMode = action?.payload;
    },
  },
});

export const { setFormMode } = formModeReducer.actions;
export default formModeReducer.reducer;
