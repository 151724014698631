import { useSelector } from "react-redux";
import { RootState } from "../store";

const useOrganization = () => {
  const organizationForm = useSelector(
    (state: RootState) => state?.organization
  );
  return organizationForm;
};

export default useOrganization;
