interface PrivacyPolicyProps {}

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = () => {
  return (
    <div className="flex flex-col max-w-4xl px-4 gap-4 md:my-14 my-7">
      <p className="text-red-600 font-semibold md:text-base text-xs">
        IMPORTANT - READ THE FOLLOWING TERMS & CONDITIONS CAREFULLY BEFORE
        INSTALLING AND/OR USING THE PRODUCT - STRADA PLUS®
      </p>
      <div className="md:text-[12px] text-[8px] flex flex-col md:gap-4 gap-2">
        <li>
          Essen Energy Conversion Devices Private Ltd. ("ESSEN") grants you
          ("Customer"), to install the product - STRADA PLUS® respectively as
          indicated below.
        </li>
        <li>
          By installing and/or using the STRADA PLUS® you indicate your
          acceptance of the following Essen Product Agreement ("Agreement").
          India
        </li>
        <li>
          Essen warrants this product - STRADA PLUS® for a period of 90 days
          from the shipment date of the product.
        </li>
        <li>
          If within the stipulated period of 90 days, STRADA PLUS® is found to
          be defective due to any manufacturing process, Essen shall either
          repair or replace the product at it's discretion. The decision shall
          rest solely at the discretion of Essen. Any other damage caused to
          STRADA PLUS®, due to mishandling, providing wrong power source, wrong
          connection and such other issues, shall not render the STRADA PLUS® to
          be defective.
        </li>
        <li>
          Essen's monetary liability is limited to the value of the product
          STRADA PLUS®, as paid by the Customerand under no circumstances does
          Essen imply any further damages or liabilities.
        </li>
        <li>
          ESSEN DISCLAIMS ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING
          BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
          A PARTICULAR PURPOSE, AND NONINFRINGEMENT; THIS STRADA PLUS® IS
          DISTRIBUTED "AS-IS".ESSEN SHALL NOT BE LIABLE FOR ANY INDIRECT,
          INCIDENTAL, SPECIAL OF CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOSS OR
          PROFITS, REVENUE, DATA OR DATA USE, INCURRED BY CUSTOMER OR ANY THIRD
          PARTY, WHETHER IN CONTRACT OR TORT, EVEN IF CUSTOMER OR ANY OTHER
          PERSON HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.ESSEN'S
          LIABILITY FOR DAMAGES HEREUNDER SHALL IN NO EVENT EXCEED THE PRICE
          PAID BY CUSTOMER UNDER THIS AGREEMENT.
        </li>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
