import PageNotFoundSVG from "../assets/svg-icons/PageNotFoundSVG";

interface PageNotFoundProps {}

const PageNotFound: React.FC<PageNotFoundProps> = () => {
  return (
    <div className="flex m-auto">
      <PageNotFoundSVG className="w-40 md:w-80" />
    </div>
  );
};

export default PageNotFound;
