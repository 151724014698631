import { DialogPanel, DialogTitle, TransitionChild } from "@headlessui/react";
import CommonModal from "./CommonModal";
import { SubmitHandler, useForm } from "react-hook-form";
import InputField from "../atoms/InputField";
import { REGEX } from "../../constants/RegexConstants";
import PrimaryButton from "../atoms/PrimaryButton";
import { useDispatch } from "react-redux";
import {
  closeModal,
  setLoading,
  startRefetch,
} from "../../redux/reducers/uiReducer";
import { useEffect } from "react";
import api from "../../helpers/api";
import PincodeSearchableInput from "../atoms/searchableInputs/PincodeSearchableInput";
import { IAddress } from "../../types/CommonType";
import setFormErrors from "../../helpers/setFormErrors";
import toast from "react-hot-toast";
import useAddress from "../../redux/hooks/useUserAddress";
import { setUserAddress } from "../../redux/reducers/userAddressReducer";
import useFormMode from "../../redux/hooks/useFormMode";

interface AddressModalProps {}

const AddressModal: React.FC<AddressModalProps> = () => {
  const dispatch = useDispatch();
  const { address } = useAddress();
  const { formMode } = useFormMode();
  const {
    register,
    handleSubmit,
    reset,
    setError,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IAddress>();

  const onSubmit: SubmitHandler<IAddress> = (data) => {
    dispatch(setLoading({ label: "Updating Address...", isLoading: true }));
    if (formMode === "update") {
      api
        .PUT(`user-addresses/${address?.id}`, data)
        .then((res: any) => {
          if (res?.data?.data) {
            dispatch(startRefetch("AddressList"));
            handleClose();
          } else {
            setFormErrors(res?.data?.errors, setError);
          }
        })
        .catch((error) => {
          toast.error("Unable to update address");
        })
        .finally(() => {
          dispatch(setLoading({ isLoading: false }));
        });
    } else {
      api
        .POST(`user-addresses`, data)
        .then((res: any) => {
          if (res?.data?.data) {
            dispatch(startRefetch("AddressList"));
            handleClose();
          } else {
            setFormErrors(res?.data?.errors, setError);
          }
        })
        .catch((error) => {
          toast.error("Unable to create address");
        })
        .finally(() => {
          dispatch(setLoading({ isLoading: false }));
        });
    }
  };

  useEffect(() => {
    if (address?.id) {
      reset(address);
    }
  }, [address]);

  const handleClose = () => {
    reset({
      address_line_1: "",
      address_line_2: "",
      name: "",
    });
    dispatch(setUserAddress({}));
    dispatch(closeModal("AddressModal"));
  };

  return (
    <CommonModal name="AddressModal" onClose={handleClose}>
      <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 md:p-0">
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel className="rounded-2xl bg-white text-left shadow-xl transition-all md:my-8 md:w-fit justify-center items-center flex flex-col">
              <div className="flex flex-col items-center justify-center w-full">
                <div className="text-start md:mt-0 md:text-left">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex flex-col md:justify-center items-start px-4"
                  >
                    <DialogTitle
                      as="h3"
                      className="text-xl text-center font-semibold leading-6 text-gray-900 my-4"
                    >
                      Create Address
                    </DialogTitle>
                    <div className="flex flex-col p-6 gap-2 shadow bg-[#F9FAFB] rounded-2xl w-full">
                      <div className="flex flex-col w-full items-center justify-center gap-2">
                        <InputField
                          label="Name"
                          required
                          placeholder="Enter your name"
                          type="text"
                          className="text-black border p-2 rounded-md text-xs md:text-sm font-normal w-full"
                          error={errors?.name?.message}
                          register={register("name", {
                            required: "This Field is Required",
                          })}
                        />
                        <InputField
                          label="Address line 1"
                          required
                          placeholder="Enter your address"
                          type="text"
                          className="text-black border p-2 rounded-md text-xs md:text-sm font-normal w-full"
                          error={errors?.address_line_1?.message}
                          register={register("address_line_1", {
                            required: "This Field is Required",
                          })}
                        />
                        <InputField
                          label="Address line 2"
                          placeholder="Enter your address"
                          type="text"
                          className="text-black border p-2 rounded-md text-xs md:text-sm font-normal w-full"
                          error={errors?.address_line_2?.message}
                          register={register("address_line_2", {
                            // required: "This Field is Required",
                          })}
                        />
                      </div>
                      <div className="flex flex-col md:flex-row items-center justify-center gap-2">
                        {/* pincode and state */}
                        <PincodeSearchableInput
                          watch={watch}
                          formKey={"pincode"}
                          setValue={setValue}
                          error={errors?.pincode?.message}
                          register={register("pincode", {
                            required: "This Field is Required",
                          })}
                        />
                        <InputField
                          disabled
                          required
                          label="State"
                          placeholder="Enter your state"
                          type="text"
                          error={errors?.state?.message}
                          register={register("state.name")}
                        />
                      </div>
                      <div className="flex flex-col md:flex-row items-center justify-center gap-2">
                        {/* city and country */}
                        <InputField
                          disabled
                          required
                          label="City"
                          placeholder="Enter your city"
                          type="text"
                          error={errors?.city?.message}
                          register={register("city.name")}
                        />
                        <InputField
                          disabled
                          required
                          label="Country"
                          placeholder="Enter your country"
                          type="text"
                          error={errors?.country?.message}
                          register={register("country.name")}
                        />
                      </div>
                    </div>
                    <div className="md:self-end w-full md:w-full my-4 flex justify-between items-center">
                      <button
                        type="button"
                        className="py-2 px-5 text-sm font-medium text-black bg-gray-50 rounded-xl hover:bg-gray-200 inline-flex items-center border border-gray-300"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>

                      {formMode === "update" ? (
                        <PrimaryButton
                          type="submit"
                          btnClassName="w-fit text-center flex items-center justify-center rounded-[6px] h-[38px]"
                        >
                          Update
                        </PrimaryButton>
                      ) : (
                        <PrimaryButton
                          type="submit"
                          btnClassName="w-fit text-center flex items-center justify-center rounded-[6px] h-[38px]"
                        >
                          Create
                        </PrimaryButton>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </CommonModal>
  );
};

export default AddressModal;
