export const REGEX = {
  onlyAlphabets: /^[A-Za-z.\s]*$/,
  email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  phoneNumber: /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/,
  password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
  gstin: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
  domain: /^[a-z0-9_\-]+$/,
  pincode: /^[1-9]{1}[0-9]{5}$/,
  numbers: /^[0-9]*$/,
  licensePlate: /^[A-Z]{2}(0?[1-9]|[1-9][0-9])[A-Z]{1,2}[0-9]{4}$/,
};
