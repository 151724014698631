import { NewspaperIcon, StarIcon } from "@heroicons/react/24/outline";

interface OrderModalSkeletonProps {}

const OrderModalSkeleton: React.FC<OrderModalSkeletonProps> = () => {
  return (
    <div role="status" className="transition-transform ease-in">
      <div className="text-start md:mt-0 md:text-left w-full">
        <div className="flex flex-1 text-xs md:text-xl text-center justify-between w-full font-medium md:font-semibold leading-6 text-gray-900 mb-4 md:mb-2">
          <p className="h-6 bg-gray-200 rounded-full w-24 animate-pulse"></p>
          <div className="flex items-center gap-2">
            <p className="py-1.5 px-2.5 bg-blue-100 text-blue-500 rounded-xl animate-pulse h-8 w-14 md:w-28"></p>
            <p className="py-1.5 px-2.5 bg-geovetraGreen-500 rounded-xl hover:bg-geovetraGreen-600 animate-pulse h-8 w-14 md:w-28"></p>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-start w-full columns-2 gap-16 mb-4">
        <div className="md:w-1/2">
          <p className="text-xs md:text-sm font-medium">Shipping Address</p>
          <p className="h-2.5 bg-gray-200 rounded-full animate-pulse mt-3"></p>
        </div>
        <div className="md:w-1/2">
          <p className="text-xs md:text-sm font-medium">Billing Address</p>
          <p className="h-2.5 bg-gray-200 rounded-full animate-pulse mt-3"></p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-center items-start w-full columns-2 gap-4 my-4">
        <div>
          <p className="text-xs md:text-base font-bold md:font-medium">
            Transactions
          </p>
          <div className="flex justify-between items-center gap-4 mt-2 bg-white rounded-xl p-2">
            <p className="text-xs font-semibold h-2.5 bg-gray-200 rounded-full w-48 mb-4 animate-pulse"></p>
          </div>
        </div>
        <div className="md:border-l md:pl-4">
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right">
              <thead className="text-xs">
                <tr>
                  <th
                    scope="col"
                    className=" pt-3 text-xs md:text-base font-bold md:font-medium"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="px-6 pt-3 text-xs md:text-base font-bold md:font-medium"
                  >
                    Qty
                  </th>
                  <th
                    scope="col"
                    className="px-6 pt-3 text-xs md:text-base font-bold md:font-medium"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="px-6 pt-3 text-xs md:text-base font-bold md:font-medium"
                  >
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="">
                  <th
                    scope="row"
                    className="pb-2 py-2 text-xs md:text-sm font-medium"
                  >
                    <p className="h-2.5 bg-gray-200 rounded-full mb-4 animate-pulse md:w-32"></p>
                  </th>
                  <td className="pl-6 py-2 text-xs md:text-sm">
                    <p className="h-2.5 bg-gray-200 rounded-full mb-4 animate-pulse md:w-28"></p>{" "}
                  </td>
                  <td className="pl-6 py-2 text-xs md:text-sm">
                    <p className="h-2.5 bg-gray-200 rounded-full mb-4 animate-pulse md:w-20"></p>
                  </td>
                  <td className="pl-6 py-2 text-xs md:text-sm">
                    <p className="h-2.5 bg-gray-200 rounded-full mb-4 animate-pulse md:w-32"></p>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr className="">
                  <th
                    scope="row"
                    className="pb-2 py-2 text-xs md:text-sm font-medium"
                  >
                    <p className="h-2.5 bg-gray-200 rounded-full mb-4 animate-pulse md:w-32"></p>
                  </th>
                  <td className="pl-6 py-2 text-xs md:text-sm">
                    <p className="h-2.5 bg-gray-200 rounded-full mb-4 animate-pulse md:w-28"></p>{" "}
                  </td>
                  <td className="pl-6 py-2 text-xs md:text-sm">
                    <p className="h-2.5 bg-gray-200 rounded-full mb-4 animate-pulse md:w-20"></p>
                  </td>
                  <td className="pl-6 py-2 text-xs md:text-sm">
                    <p className="h-2.5 bg-gray-200 rounded-full mb-4 animate-pulse md:w-32"></p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="flex justify-between border-t mt-2 pt-2">
            <div>
              <p className="h-2.5 bg-gray-200 rounded-full mb-4 w-32 animate-pulse"></p>
              <p className="h-2.5 bg-gray-200 rounded-full mb-4 w-28 animate-pulse"></p>
            </div>
            <div>
              <p className="h-2.5 bg-gray-200 rounded-full mb-4 w-24 animate-pulse"></p>
              <p className="h-2.5 bg-gray-200 rounded-full mb-4 w-24 animate-pulse"></p>
            </div>
          </div>
          <div className="flex justify-between border-t mt-2 pt-2">
            <div>
              <p className="h-2.5 bg-gray-200 rounded-full mb-4 w-36 animate-pulse"></p>
              <p className="h-2.5 bg-gray-200 rounded-full mb-4 w-48 animate-pulse"></p>
            </div>
            <div>
              <p className="h-2.5 bg-gray-200 rounded-full mb-4 w-24 animate-pulse"></p>
              <p className="h-2.5 bg-gray-200 rounded-full mb-4 w-24 animate-pulse"></p>
            </div>
          </div>
          <div className="flex justify-between border-t mt-2 pt-2">
            <p className="h-2.5 bg-gray-200 rounded-full mb-4 w-32 animate-pulse"></p>
            <p className="h-2.5 bg-gray-200 rounded-full mb-4 w-24 animate-pulse"></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderModalSkeleton;
