import PrimaryButton from "../../components/atoms/PrimaryButton";
import testImage from "../../assets/images/test-image.jpg";
import ImageSlider from "../../components/atoms/ImageSlider";
import WorkerLoadingTruck from "../../assets/images/StepsImages/WorkerLoadingTruck.jpg";
import NavigatingCityStreets from "../../assets/images/StepsImages/NavigatingCityStreets.jpg";
import FocusedTruckDriver from "../../assets/images/StepsImages/FocusedTruckDriver.jpg";
import LogisticsRoutePlanning from "../../assets/images/StepsImages/LogisticsRoutePlanning.jpg";
import Dashboard from "../../assets/images/appScreenshot/dashboard.png";
import Tracking from "../../assets/images/appScreenshot/tracking.png";
import { openModal } from "../../redux/reducers/uiReducer";
import { useDispatch } from "react-redux";

interface InfoSectionProps {}

const InfoSection: React.FC<InfoSectionProps> = () => {
  const dispatch = useDispatch();
  const topFeatures = [
    {
      point: "Real-time Tracking",
    },
    {
      point: "Automatic Route Optimization",
    },
    {
      point: "Complete Trip Logs",
    },
    {
      point: "Driver Performance Monitoring",
    },
  ];

  const bottomFeatures = [
    {
      point: "Easy Integration",
    },
    {
      point: "Minimal Downtime",
    },
    {
      point: "User-Friendly Interface",
    },
    {
      point: "Customizable Settings",
    },
  ];

  const stepData = [
    {
      image: WorkerLoadingTruck,
    },
    {
      image: FocusedTruckDriver,
    },
    {
      image: LogisticsRoutePlanning,
    },
    {
      image: NavigatingCityStreets,
    },
  ];

  const dashboardScreenshots = [
    {
      image: Dashboard,
    },
    {
      image: Tracking,
    },
    {
      image: testImage,
    },
    {
      image: testImage,
    },
    {
      image: testImage,
    },
    {
      image: testImage,
    },
  ];

  return (
    <>
      <div className="flex flex-col md:flex-row items-center justify-center md:gap-8 columns-2 max-w-7xl w-full md:my-16 px-4 pb-4">
        <div className="bg-geovetraGreen-100 p-4 rounded-2xl self-start w-full md:w-1/2 ">
          {/* <img
            src={testImage}
            alt=""
            className="h-[250px] md:h-[300px] md:w-[720px]"
          /> */}
          <video
            src="https://uat.geovetra.com/static/media/video.f394b7db0e493d3c8d84.mp4"
            className="h-[250px] md:h-[400px] md:w-[820px]"
            controls
            autoPlay={true}
            muted
            controlsList="nodownload"
            onContextMenu={(event) => event.preventDefault()}
          />
        </div>
        <div className="flex flex-col items-start justify-start gap-4 px-2 w-full md:w-1/2">
          <p className="text-base md:text-3xl font-semibold md:mt-0 mt-4 bg-white">
            Optimize Your Delivery Network
          </p>
          <p className="text-slate-500 font-medium text-sm md:text-xl text-wrap bg-white">
            Geovetra offers unparalleled tracking capabilities, so you always
            know where your drivers are and can anticipate delivery times
            accurately.
          </p>
          {topFeatures.map((feature, index) => {
            return (
              <li
                key={index}
                className="text-black font-medium md:text-base text-xs leading-none bg-white"
              >
                {feature.point}
              </li>
            );
          })}
          <PrimaryButton
            onClick={() => {
              dispatch(openModal("RegisterModal"));
            }}
          >
            Sign Up Today
          </PrimaryButton>
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row items-center justify-center md:gap-8 columns-2 max-w-7xl w-full md:my-16 px-4 pb-4">
        <div className="flex flex-col items-start justify-start gap-4 px-2 w-full md:w-1/2">
          <p className="text-base md:text-3xl font-semibold mt-4 md:mt-0 bg-white">
            Seamless Integration with Existing Systems
          </p>
          <p className="text-slate-500 font-medium text-sm md:text-xl text-wrap bg-white">
            Geovetra seamlessly integrates with your current dispatch and
            logistics systems, making adoption swift and hassle-free. Experience
            the power of comprehensive tracking without disrupting your
            workflow.
          </p>
          {bottomFeatures.map((feature, index) => {
            return (
              <li
                key={index}
                className="text-black font-medium md:text-base text-xs leading-none bg-white"
              >
                {feature.point}
              </li>
            );
          })}
          <PrimaryButton>Learn More</PrimaryButton>
        </div>
        <div className="bg-geovetraGreen-100 p-4 rounded-2xl self-start w-full md:w-1/2 ">
          <ImageSlider imageData={stepData} />
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center justify-center md:gap-8 columns-2 max-w-7xl w-full md:my-16 px-4">
        <div className="bg-geovetraGreen-100 p-4 rounded-2xl self-start w-full md:w-1/2 ">
          {/* <img
            src={testImage}
            alt=""
            className="h-[250px] md:h-[300px] md:w-[720px]"
          /> */}
          <ImageSlider imageData={dashboardScreenshots} />
        </div>
        <div className="flex flex-col items-start justify-start gap-4 px-2 w-full md:w-1/2">
          <p className="text-base md:text-3xl font-semibold md:mt-0 mt-4 bg-white">
            Optimize Your Delivery Network
          </p>
          <p className="text-slate-500 font-medium text-sm md:text-xl text-wrap bg-white">
            Geovetra offers unparalleled tracking capabilities, so you always
            know where your drivers are and can anticipate delivery times
            accurately.
          </p>
          {bottomFeatures.map((feature, index) => {
            return (
              <li
                key={index}
                className="text-black font-medium md:text-base text-xs leading-none bg-white"
              >
                {feature.point}
              </li>
            );
          })}
          <PrimaryButton
            onClick={() => {
              dispatch(openModal("RegisterModal"));
            }}
          >
            Sign Up Today
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

export default InfoSection;
