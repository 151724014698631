import {
  Transition,
  Dialog,
  TransitionChild,
  DialogPanel,
} from "@headlessui/react";
import { IOrder } from "../../types/CommonType";
import invoice_banner from "../../assets/images/invoice_banner.png";
import PrimaryButton from "../atoms/PrimaryButton";
import { FolderArrowDownIcon } from "@heroicons/react/24/outline";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";

interface InvoiceModalProps {
  visibleInvoice: boolean;
  setVisibleInvoice: (visibleInvoice: boolean) => void;
  order: IOrder | undefined;
}

const InvoiceModal: React.FC<InvoiceModalProps> = ({
  visibleInvoice,
  setVisibleInvoice,
  order,
}) => {
  const componentRef: any = useRef();
  const generatePdf = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Transition show={visibleInvoice}>
      <Dialog className="relative z-20" onClose={setVisibleInvoice}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
        </TransitionChild>
        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 md:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="rounded-2xl bg-white text-left shadow-xl transition-all md:my-8 md:w-fit justify-center items-center flex flex-col p-8">
                <div className="text-start md:mt-0 md:text-left w-full">
                  <div className="h-screen overflow-auto">
                    <div className="flex justify-end self-end end-0 w-full items-end">
                      <PrimaryButton
                        btnClassName="justify-end self-end"
                        onClick={generatePdf}
                      >
                        <FolderArrowDownIcon className="size-5 mr-1" /> Print
                      </PrimaryButton>
                    </div>

                    <div id="pdf-content" ref={componentRef}>
                      <div className="m-3 flex-shrink-1 break-after-page">
                        <div
                          className="print-page bg-light p-4"
                          style={{
                            width: "200mm",
                            // height: "297mm",
                            fontSize: 10,
                            fontFamily: "sans-serif",
                            alignItems: "center",
                          }}
                        >
                          <table>
                            <tbody>
                              <tr>
                                <td colSpan={8}>
                                  <div className="text-center mb-3">
                                    <img
                                      src={invoice_banner}
                                      alt=""
                                      height={"160mm"}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr className="border-2 border-black">
                                <td colSpan={8}>
                                  <p className="  text-center font-bold">
                                    FINAL TAX INVOICE AND DELIVERY CHALLAN
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={2}
                                  className="border-2 border-black"
                                >
                                  <p className="  font-bold">
                                    Final Invoice No.: Order #{order?.id}
                                  </p>
                                  <p className="   font-bold">PO No. :</p>
                                </td>
                                <td
                                  colSpan={4}
                                  className="border-2 border-black"
                                >
                                  <p className="  font-bold">
                                    Client GST No.: NA
                                  </p>
                                </td>
                                <td
                                  colSpan={2}
                                  className="border-2 border-black"
                                >
                                  <p className="  font-bold">
                                    Date: {order?.created_at?.date}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={3}
                                  className="border-2 border-black w-96"
                                >
                                  <p className="  font-bold">
                                    Bill To:{" "}
                                    <span>
                                      {[
                                        order?.shipping_address_details
                                          ?.address_line_1,
                                        order?.shipping_address_details
                                          ?.address_line_2,
                                        order?.shipping_address_details?.city
                                          ?.name,
                                        order?.shipping_address_details?.pincode
                                          ?.name,
                                        order?.shipping_address_details?.state
                                          ?.name,
                                        order?.shipping_address_details?.country
                                          ?.name,
                                      ].join(", ")}
                                    </span>
                                  </p>
                                  <p className="">Tel:</p>
                                </td>
                                <td
                                  colSpan={5}
                                  className="border-2 border-black"
                                >
                                  <p className="  font-bold">
                                    Ship To:
                                    <span>
                                      {[
                                        order?.shipping_address_details
                                          ?.address_line_1,
                                        order?.shipping_address_details
                                          ?.address_line_2,
                                        order?.shipping_address_details?.city
                                          ?.name,
                                        order?.shipping_address_details?.pincode
                                          ?.name,
                                        order?.shipping_address_details?.state
                                          ?.name,
                                        order?.shipping_address_details?.country
                                          ?.name,
                                      ].join(", ")}
                                    </span>
                                  </p>
                                  <p className="">
                                    Tel:{" "}
                                    {
                                      order?.created_by_user
                                        ?.phone_number_country_code
                                    }
                                    {order?.created_by_user?.phone_number}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  rowSpan={2}
                                  className="border-2 border-black w-9"
                                >
                                  <p className="  text-center font-bold">
                                    Sr No.
                                  </p>
                                </td>
                                <td
                                  rowSpan={2}
                                  className="border-2 border-black w-64"
                                >
                                  <p className="  text-center font-bold">
                                    Description
                                  </p>
                                </td>
                                <td
                                  rowSpan={2}
                                  className="border-2 border-black w-9"
                                >
                                  <p className="  text-center font-bold">
                                    HSN / SAC
                                  </p>
                                </td>
                                <td
                                  rowSpan={2}
                                  className="border-2 border-black w-11"
                                >
                                  <p className="  text-center font-bold">QTY</p>
                                </td>
                                <td
                                  rowSpan={2}
                                  className="border-2 border-black w-24"
                                >
                                  <p className="  text-center font-bold">
                                    Unit Price{" "}
                                  </p>
                                </td>
                                <td
                                  className="border-2 border-black w-24"
                                  rowSpan={2}
                                >
                                  <p className="  text-center font-bold">
                                    Total Taxable Value
                                  </p>
                                </td>
                                <td
                                  className="border-2 border-black w-9"
                                  colSpan={2}
                                >
                                  <p className="  text-center font-bold">
                                    SGST + CGST / IGST
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td className="border-2 border-black">
                                  <p className="  text-center font-bold">
                                    {" "}
                                    Rate - %
                                  </p>
                                </td>
                                <td className="border-2 border-black">
                                  <p className="  text-center font-bold">
                                    {" "}
                                    Amt
                                  </p>
                                </td>
                              </tr>

                              <tr className="align-baseline">
                                <td className="border-2 border-black text-center">
                                  {order?.purchases?.map((purchase, index) => {
                                    return (
                                      <div key={purchase.id}>{index + 1}</div>
                                    );
                                  })}
                                  {order?.recharges?.map((recharge, index) => {
                                    return (
                                      <div key={recharge.id}>
                                        {(order?.purchases ?? [])?.length +
                                          index +
                                          1}
                                      </div>
                                    );
                                  })}
                                </td>
                                <td className="border-2 border-black h-44">
                                  {order?.purchases?.map((purchase, index) => {
                                    return (
                                      <div className="mx-2" key={purchase.id}>
                                        Purchase of {purchase.type_of_purchase}
                                      </div>
                                    );
                                  })}
                                  {order?.recharges?.map((recharge, index) => {
                                    return (
                                      <div className="mx-2" key={recharge.id}>
                                        Recharge of {recharge.number_of_days}{" "}
                                        Days
                                      </div>
                                    );
                                  })}
                                </td>
                                <td className="border-2 border-black"></td>
                                <td className="border-2 border-black">
                                  {order?.purchases?.map((purchase, index) => {
                                    return (
                                      <div key={purchase.id}>
                                        {purchase.number_of_units} Units
                                      </div>
                                    );
                                  })}
                                  {order?.recharges?.map((recharge, index) => {
                                    return (
                                      <div key={recharge.id}>
                                        {recharge.number_of_subscriptions} Units
                                      </div>
                                    );
                                  })}
                                </td>
                                <td className="border-2 border-black">
                                  {order?.purchases?.map((purchase, index) => {
                                    return (
                                      <div key={purchase.id}>
                                        {purchase.cost_per_unit} / Unit
                                      </div>
                                    );
                                  })}
                                  {order?.recharges?.map((recharge, index) => {
                                    return (
                                      <div key={recharge.id}>
                                        {recharge.cost_per_day} / Day
                                      </div>
                                    );
                                  })}
                                </td>
                                <td className="border-2 border-black">
                                  {order?.purchases?.map((purchase, index) => {
                                    return (
                                      <div key={purchase.id}>
                                        {purchase.total_purchase_cost}
                                      </div>
                                    );
                                  })}
                                  {order?.recharges?.map((recharge, index) => {
                                    return (
                                      <div key={recharge.id}>
                                        {recharge.recharge_total}
                                      </div>
                                    );
                                  })}
                                </td>
                                <td className="border-2 border-black">
                                  {order?.purchases?.map((purchase, index) => {
                                    return <div key={purchase.id}>18%</div>;
                                  })}
                                  {order?.recharges?.map((recharge, index) => {
                                    return <div key={recharge.id}>18%</div>;
                                  })}
                                </td>
                                <td className="border-2 border-black">
                                  {order?.purchases?.map((purchase, index) => {
                                    return (
                                      <div key={purchase.id}>
                                        {purchase.total_purchase_cost * 0.18}
                                      </div>
                                    );
                                  })}
                                  {order?.recharges?.map((recharge, index) => {
                                    return (
                                      <div key={recharge.id}>
                                        {recharge.recharge_total * 0.18}
                                      </div>
                                    );
                                  })}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  className="border-2 border-black"
                                  rowSpan={7}
                                ></td>
                                <td className="border-2 border-black">
                                  <p className="  text-center font-bold">
                                    Total
                                  </p>
                                </td>
                                <td className="border-2 border-black"></td>
                                <td className="border-2 border-black"></td>
                                <td className="border-2 border-black"></td>
                                <td className="border-2 border-black">
                                  {order?.order_total_amount}
                                </td>
                                <td className="border-2 border-black"></td>
                                <td className="border-2 border-black">
                                  {Number(order?.tax_amount)}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}></td>
                                <td colSpan={3}>
                                  <p className="  font-bold">Net Value</p>
                                </td>
                                <td
                                  className="border-2 border-black"
                                  colSpan={2}
                                >
                                  <p className="  text-end">
                                    {order?.order_total_amount}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}></td>
                                <td colSpan={3}>
                                  <p className="  font-bold">
                                    Discount {order?.discount_percentage ?? 0}%
                                  </p>
                                </td>
                                <td
                                  className="border-2 border-black"
                                  colSpan={2}
                                >
                                  <p className="  text-end">
                                    {order?.discount_amount}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}></td>
                                <td colSpan={3}>
                                  <p className="  font-bold">
                                    SGST @ 9 % Tax Amount
                                  </p>
                                </td>
                                <td
                                  className="border-2 border-black"
                                  colSpan={2}
                                >
                                  <p className="  text-end">
                                    {order?.tax_amount && order?.tax_amount / 2}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}></td>
                                <td colSpan={3}>
                                  <p className="  font-bold">
                                    CGST @ 9 % Tax Amount
                                  </p>
                                </td>
                                <td
                                  className="border-2 border-black"
                                  colSpan={2}
                                >
                                  <p className="  text-end">
                                    {order?.tax_amount && order?.tax_amount / 2}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                {/* <td colSpan={2}></td>
                                 <td colSpan={3}>
                                  <p className="  font-bold"></p>
                                </td> 
                                 <td
                                  className="border-2 border-black"
                                  colSpan={2}
                                ></td> */}
                              </tr>
                              <tr>
                                <td
                                  className="border-2 border-black"
                                  colSpan={2}
                                ></td>
                                <td
                                  className="border-2 border-black"
                                  colSpan={3}
                                >
                                  <p className="  font-bold">
                                    Total Invoice Value
                                  </p>
                                </td>
                                <td
                                  className="border-2 border-black"
                                  colSpan={2}
                                >
                                  <p className="  text-end">
                                    {order?.grand_total}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td colSpan={7}>
                                  <div className="p-3">
                                    {/* <p className=" ">Total Invoice Value Rupees : Eighty Eight & paise fifty only</p> */}
                                  </div>
                                </td>
                              </tr>

                              <div></div>
                              <tr>
                                <td colSpan={7}>
                                  <small>
                                    <p className="  font-bold text-underline">
                                      Rules and Provisions :
                                    </p>
                                    <ol className="list-decimal pl-2">
                                      <li>
                                        All GPS devices and components used in
                                        this proposed Project are designed &
                                        developed in-house by Essen.
                                      </li>
                                      <li>
                                        Shipment- in 1 week or earlier from PO -
                                        with advance. Installation / training
                                        additional. 2 months.
                                      </li>
                                      <li>
                                        100% payment including GST thereon,
                                        payable in advance.
                                      </li>
                                      <li>
                                        Warranty - 90 days from shipment date.
                                      </li>
                                      <li>
                                        All goods once Sold are non-returnable.
                                      </li>
                                      <li>
                                        Mounting on Windshield must be as
                                        depicted in Manual & by Client
                                      </li>
                                      <li>
                                        Training manual is provided. Single 4
                                        Hours training session provided at
                                        specified time by Seller.
                                      </li>
                                    </ol>
                                  </small>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <p className="  font-bold text-underline">
                                    Advising Bank:
                                  </p>
                                  Bank of Bahrain & Kuwait Nariman Point, Mumbai
                                  - 400 021, India. A/c Name: Essen Energy
                                  Conversion Devices Pvt. Ltd. Account No.:
                                  0011010029880001 NEFT / IFSC code: BBKM0000001
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="p-3"></div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={4}>
                                  <p className=" ">For</p>
                                  <p className="  font-bold">
                                    {order?.created_by_user?.name}
                                  </p>
                                </td>
                                <td colSpan={4}>
                                  <p className=" ">On behalf of</p>
                                  <p className="  font-bold">
                                    Essen Energy Conversion Devices Pvt. Ltd.
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={8}>
                                  <div className="p-4"></div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={4}>
                                  <p className="  font-bold">
                                    Authorised Signatory
                                  </p>
                                  <p className="  font-bold">
                                    Date: {order?.created_at?.date}
                                  </p>
                                </td>
                                <td colSpan={4}>
                                  <p className="  font-bold">
                                    Authorised Signatory
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={8}>
                                  <div className="p-4"></div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={7}>
                                  <strong className="mx-2 text-xs">
                                    I confirm that I have read the terms and
                                    conditions as printed Behind and that I
                                    confirm to the same.
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* Terms & Conditions are taken from Txt file in below section Added on -  20/6/23 */}
                      {/* //#region Old Data */}
                      <div
                        className="print-page p-3 m-3 flex-shrink-0"
                        id="page2"
                        style={{
                          width: "200mm",
                          //   height: "297mm",
                          fontSize: 10,
                          fontFamily: "sans-serif",
                        }}
                      >
                        <br />
                        <h5 className="text-center text-lg font-bold">
                          Terms & Conditions
                        </h5>
                        <br />
                        <p>
                          <strong>
                            IMPORTANT - READ THE FOLLOWING TERMS & CONDITIONS
                            CAREFULLY BEFORE INSTALLING AND/OR USING THE PRODUCT
                            - STRADA PLUS®
                          </strong>
                        </p>
                        <ul className="flex flex-col gap-4 list-disc">
                          <li>
                            <p>
                              Essen Energy Conversion Devices Private Ltd.
                              ("ESSEN") grants you ("Customer"), to install the
                              product - STRADA PLUS® respectively as indicated
                              below.
                            </p>
                          </li>
                          <li>
                            <p>
                              By installing and/or using the STRADA PLUS® you
                              indicate your acceptance of the following Essen
                              Product Agreement ("Agreement"). India
                            </p>
                          </li>
                          <li>
                            <p>
                              Essen warrants this product - STRADA PLUS® for a
                              period of 90 days from the shipment date of the
                              product.
                            </p>
                          </li>

                          <li>
                            <p>
                              If within the stipulated period of 90 days, STRADA
                              PLUS® is found to be defective due to any
                              manufacturing process, Essen shall either repair
                              or replace the product at it's discretion. The
                              decision shall rest solely at the discretion of
                              Essen. Any other damage caused to STRADA PLUS®,
                              due to mishandling, providing wrong power source,
                              wrong connection and such other issues, shall not
                              render the STRADA PLUS® to be defective.
                            </p>
                          </li>
                          <li>
                            <p>
                              Essen's monetary liability is limited to the value
                              of the product STRADA PLUS®, as paid by the
                              Customerand under no circumstances does Essen
                              imply any further damages or liabilities.
                            </p>
                          </li>
                          <li>
                            <p>
                              ESSEN DISCLAIMS ALL WARRANTIES, EITHER EXPRESS OR
                              IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED
                              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                              PARTICULAR PURPOSE, AND NONINFRINGEMENT; THIS
                              STRADA PLUS® IS DISTRIBUTED "AS-IS".ESSEN SHALL
                              NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
                              SPECIAL OF CONSEQUENTIAL DAMAGES, OR DAMAGES FOR
                              LOSS OR PROFITS, REVENUE, DATA OR DATA USE,
                              INCURRED BY CUSTOMER OR ANY THIRD PARTY, WHETHER
                              IN CONTRACT OR TORT, EVEN IF CUSTOMER OR ANY OTHER
                              PERSON HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                              DAMAGES.ESSEN'S LIABILITY FOR DAMAGES HEREUNDER
                              SHALL IN NO EVENT EXCEED THE PRICE PAID BY
                              CUSTOMER UNDER THIS AGREEMENT.
                            </p>
                          </li>

                          <li>
                            <p>
                              This Agreement is governed by the laws of the
                              Union of India and under the jurisdiction of the
                              Bombay High Court. Unless both parties have signed
                              a separate Agreement covering the STRADA PLUS®,
                              this Agreement constitutes the complete agreement
                              between the parties and supercedes all prior or
                              contemporaneous Agreements or representations,
                              written or oral, concerning the subject matter of
                              this Agreement.This Agreement may not be modified
                              or amended except in a writing signed by a duly
                              authorized representative of each party; no other
                              act, document, usage or custom shall be deemed to
                              amend or modify this Agreement.The waiver by
                              either party of any breach of this Agreement shall
                              not constitute a waiver of any other or subsequent
                              breach.By your signing this said Delivery cum
                              Invoice, you are confirming that you have read all
                              the said Terms, and further accepting the same, by
                              appending your signature on this Delivery cum
                              Invoice.You further confirm that you are
                              authorized to sign on behalf of VAR / Individual /
                              Proprietorship / Firm / Company / Trust /
                              Association / Society or any such similar entities
                              as the case may be and that all Terms contained
                              herein are fully acceptable to you.
                            </p>
                          </li>
                        </ul>
                        <div className="my-3">
                          <p className="font-bold mb-2">Declaration:</p>
                          <p className="">
                            We hereby certify that our Registration Certificate
                            under the GST Act, is in force on the date on which
                            the sale of goods specified in this Tax Invoice is
                            made by us and the transaction of sale covered by
                            this tax invoice has effected by me and it shall be
                            accounted for in the turnover of Sales while filing
                            of return and the due tax if any, payable on the
                            sale has been paid or shall be paid.
                          </p>

                          <p className="my-4">
                            Should you have any questions concerning this
                            Agreement, you may contact: Mrunal{" "}
                          </p>

                          <p className=" ">
                            Essen Energy Conversion Devices Pvt. Ltd. at
                          </p>
                          <p className=" ">24 B, Jolly Maker II,</p>
                          <p className=" ">Nariman Point,</p>
                          <p className=" ">Mumbai 400 021</p>
                          <p className=" ">India</p>
                          <p className=" ">Tel: +91 22 6632 5364</p>
                        </div>
                      </div>
                      {/* //#endregion */}
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default InvoiceModal;
