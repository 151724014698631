import { useEffect, useState } from "react";
import api from "../../helpers/api";
import { IOrder } from "../../types/CommonType";
import { IResponse, useAuth } from "../../providers/AuthContext";
import { stopRefetch } from "../../redux/reducers/uiReducer";
import { useDispatch } from "react-redux";
import OrderModal from "../../components/modals/OrderModal";
import StatusBadges from "../../components/atoms/StatusBadges";
import OrdersSvg from "../../assets/svg-icons/OrdersSvg";
import { NavLink, useParams } from "react-router-dom";
import useUI from "../../redux/hooks/useUi";
import { times } from "lodash";
import PrimaryButton from "../../components/atoms/PrimaryButton";
import { PlusIcon } from "@heroicons/react/24/outline";

interface OrdersProps {}

const Orders: React.FC<OrdersProps> = () => {
  const [orders, setOrders] = useState<IOrder[]>();
  const [order, setOrder] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const { selectedCompany } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { refetch } = useUI();

  const toggleModal = (visible: boolean) => {
    if (visible) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  };

  const fetchOrders = () => {
    setLoading(true);
    api
      .GET<IResponse<IOrder[]>>(`${selectedCompany?.domain}/orders`)
      .then((response) => {
        const ordersData = response?.data?.data;
        if (ordersData) {
          setOrders(ordersData);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        dispatch(stopRefetch("orderList"));
      });
  };

  useEffect(() => {
    if (id) {
      setOrder(id);
      setOpenModal(true);
    }
    fetchOrders();
  }, [selectedCompany]);

  useEffect(() => {
    if (refetch.includes("orderList")) {
      fetchOrders();
    }
  }, [refetch]);

  const renderSkeleton = (i: number) => (
    <div
      className="px-2 py-4 md:p-4 md:px-6 justify-center items-center w-full rounded-xl bg-gray-200 animate-pulse shadow mb-4"
      key={i}
    >
      <div className="flex justify-between items-center mb-2">
        <div className="h-5 bg-red-200 w-12 rounded-xl mb-2"></div>
        <div className="h-5 bg-green-200 w-12 rounded-xl mb-2"></div>
      </div>
      <div className="flex flex-row justify-between items-start">
        <div className="h-4 bg-gray-300 rounded w-28"></div>
        <div className="flex flex-col justify-center items-end flex-1">
          <div className="h-4 bg-gray-300 rounded w-24"></div>
          <div className="h-4 bg-gray-300 rounded w-20 mt-2"></div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="w-full md:h-svh md:overflow-scroll">
        <p className="text-gray-600 font-semibold text-base mb-4">
          Your Orders
        </p>

        {loading ? (
          times(4).map((i) => renderSkeleton(i))
        ) : orders?.length == 0 ? (
          <div className="w-full flex justify-center">
            <p className="flex flex-col w-fit justify-center items-center gap-4 p-8 rounded-lg font-semibold text-xs md:text-base">
              <OrdersSvg className="w-40 md:w-72" />
              You don't have any order
              <NavLink to={"/checkout"}>
                <PrimaryButton>
                  Get Subscriptions
                </PrimaryButton>
              </NavLink>
            </p>
          </div>
        ) : (
          orders?.map((item, index) => {
            return (
              <div
                key={index}
                className="px-2 py-4 md:p-4 md:px-6 justify-center items-center w-full rounded-xl bg-[#F9FAFB] shadow mb-4 cursor-pointer hover:bg-gray-200"
                onClick={() => {
                  setOrder(item?.id);
                  setOpenModal(true);
                }}
              >
                <div className="flex justify-between items-center mb-2">
                  <p className="bg-red-100 p-1 px-2 rounded-xl w-fit text-xs text-end text-red-500 font-medium">
                    #{item.id}
                  </p>
                  <StatusBadges status={item?.status} />
                </div>
                <div className="flex justify-between items-start">
                  <p className="text-sm font-medium">
                    Total: ₹ {item?.grand_total}
                  </p>
                  <p className="flex flex-col text-sm font-medium">
                    {item?.created_at?.date}
                    <span className="text-end">{item?.created_at?.time}</span>
                  </p>
                </div>
              </div>
            );
          })
        )}
      </div>

      <OrderModal
        visible={openModal}
        setVisible={toggleModal}
        orderId={order}
      />
    </>
  );
};

export default Orders;
