interface VerifyEmailSvgProps {
  className?: string | undefined;
}

const VerifyEmailSvg: React.FC<VerifyEmailSvgProps> = ({
  className,
  ...props
}) => (
  <svg
    className={`animated ${className}`}
    id="freepik_stories-verified"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    {...props}
  >
    <style>
      {
        "svg#freepik_stories-verified:not(.animated) .animable {opacity: 0;}svg#freepik_stories-verified.animated #freepik--Screen--inject-3 {animation: 1.5s Infinite  linear floating;animation-delay: 0s;}svg#freepik_stories-verified.animated #freepik--speech-bubble--inject-3 {animation: 1.5s Infinite  linear floating;animation-delay: 0s;}            @keyframes floating {                0% {                    opacity: 1;                    transform: translateY(0px);                }                50% {                    transform: translateY(-10px);                }                100% {                    opacity: 1;                    transform: translateY(0px);                }            }        "
      }
    </style>
    <g
      id="freepik--Floor--inject-3"
      className="animable"
      style={{
        transformOrigin: "250px 347.532px",
      }}
    >
      <path
        d="M409,255.09c-88.41-51-231.28-51-319.12,0S2.55,388.9,91,439.94s231.28,51.05,319.12,0S497.45,306.14,409,255.09Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "250px 347.532px",
        }}
        id="elwhrwflz7zni"
        className="animable"
      />
    </g>
    <g
      id="freepik--Shadows--inject-3"
      className="animable"
      style={{
        transformOrigin: "246.205px 342.827px",
      }}
    >
      <path
        d="M439.8,392.15c2.84,1.64,2.85,4.3,0,5.94l-11.6,6.69a11.27,11.27,0,0,1-10.24,0L290.81,331.35c-2.84-1.63-2.86-4.29,0-5.93l11.59-6.69a11.29,11.29,0,0,1,10.25,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "365.303px 361.755px",
        }}
        id="el8pagv2bjs8"
        className="animable"
      />
      <path
        d="M404.12,402.69c2.85,1.65,2.86,4.3,0,5.94l-11.6,6.69a11.29,11.29,0,0,1-10.25,0l-108.6-62.73c-2.84-1.64-2.86-4.3,0-5.94l11.6-6.69a11.29,11.29,0,0,1,10.25,0Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "338.897px 377.64px",
        }}
        id="elirvam11fwe"
        className="animable"
      />
      <path
        d="M90.22,397.76c-7.79-4.5-20.39-4.5-28.14,0s-7.7,11.8.09,16.3,20.4,4.5,28.14,0S98,402.26,90.22,397.76Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "76.1912px 405.91px",
        }}
        id="elo8qmbnhnnrr"
        className="animable"
      />
      <path
        d="M129.29,387.2c24.07-13.9,63-13.9,86.9,0s23.79,36.44-.29,50.34-63,13.9-86.9,0S105.21,401.1,129.29,387.2Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "172.595px 412.37px",
        }}
        id="elcpxsgzyp4yb"
        className="animable"
      />
      <path
        d="M100.66,342.6l51.72-29.86a2.29,2.29,0,0,0,0-4L106.8,282.29a2.29,2.29,0,0,0-2.29,0L51.65,312.81a2.29,2.29,0,0,0,0,4l44.43,25.82A4.59,4.59,0,0,0,100.66,342.6Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "102.015px 312.605px",
        }}
        id="el9t9fihkewcu"
        className="animable"
      />
      <path
        d="M261.65,242.94c-12.2-7-31.92-7-44,0s-12.06,18.47.14,25.51,31.92,7.05,44,0S273.85,250,261.65,242.94Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "239.72px 255.712px",
        }}
        id="ela9s1dtnkkf6"
        className="animable"
      />
    </g>
    <g
      id="freepik--Window--inject-3"
      className="animable"
      style={{
        transformOrigin: "101px 133.655px",
      }}
    >
      <polygon
        points="160.13 86.89 156.21 84.61 114.8 108.43 114.8 112.96 160.13 86.89"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "137.465px 98.785px",
        }}
        id="el7s1d6p22ttc"
        className="animable"
      />
      <polygon
        points="160.13 86.89 160.13 86.89 160.13 86.89 114.8 112.96 114.8 118.14 160.14 92.09 160.13 86.89"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "137.47px 102.515px",
        }}
        id="eln1q7lz0tm3q"
        className="animable"
      />
      <g id="elxs5srxe2y4">
        <rect
          x={111.31}
          y={99.93}
          width={52.3}
          style={{
            fill: "rgb(224, 224, 224)",
            transformOrigin: "137.46px 99.93px",
            transform: "rotate(-29.91deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="91.12 126.59 87.2 124.31 45.78 148.13 45.78 152.67 91.12 126.59"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "68.45px 138.49px",
        }}
        id="elsb2wxupc7wo"
        className="animable"
      />
      <polygon
        points="91.12 126.59 91.12 126.59 91.12 126.59 45.78 152.67 45.78 157.84 91.13 131.79 91.12 126.59"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "68.455px 142.215px",
        }}
        id="elsfmdg2umhj"
        className="animable"
      />
      <g id="elvf8ojeu92p">
        <rect
          x={42.3}
          y={139.63}
          width={52.3}
          style={{
            fill: "rgb(224, 224, 224)",
            transformOrigin: "68.45px 139.63px",
            transform: "rotate(-29.91deg)",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="159.91 43.36 155.99 41.08 156.22 158.35 160.14 160.63 159.91 43.36"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "158.065px 100.855px",
        }}
        id="elufep5dzjvvf"
        className="animable"
      />
      <polygon
        points="160.14 160.63 156.22 158.35 111.11 184.25 115.03 186.53 160.14 160.63"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "135.625px 172.44px",
        }}
        id="el4v4l7uorkkk"
        className="animable"
      />
      <path
        d="M164.66,163.21l-54.13,31.1-.26-127.63L164.4,35.57ZM115,186.53l45.11-25.9-.23-117.27L114.8,69.25,115,186.53"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "137.465px 114.94px",
        }}
        id="elxdoickwwcjo"
        className="animable"
      />
      <polygon
        points="164.4 35.57 160.49 33.3 106.35 64.4 110.27 66.68 164.4 35.57"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "135.375px 49.99px",
        }}
        id="eldiz3yo53aqu"
        className="animable"
      />
      <polygon
        points="110.27 66.68 106.35 64.4 106.61 192.03 110.53 194.31 110.27 66.68"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "108.44px 129.355px",
        }}
        id="elmlad8pjb7un"
        className="animable"
      />
      <polygon
        points="90.9 83.06 86.98 80.78 87.21 198.06 91.13 200.34 90.9 83.06"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "89.055px 140.56px",
        }}
        id="elgycr468bbhn"
        className="animable"
      />
      <polygon
        points="91.13 200.34 87.21 198.06 42.1 223.95 46.01 226.23 91.13 200.34"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "66.615px 212.145px",
        }}
        id="elf4wapo5jc2c"
        className="animable"
      />
      <path
        d="M95.65,202.91,41.52,234l-.26-127.63,54.13-31.1ZM46,226.23l45.12-25.89L90.9,83.06,45.78,109,46,226.23"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "68.455px 154.635px",
        }}
        id="elh36ku800aj8"
        className="animable"
      />
      <polygon
        points="95.39 75.28 91.47 73 37.34 104.1 41.26 106.38 95.39 75.28"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "66.365px 89.69px",
        }}
        id="el8324vt2jbm"
        className="animable"
      />
      <polygon
        points="41.26 106.38 37.34 104.1 37.6 231.74 41.52 234.01 41.26 106.38"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "39.43px 169.055px",
        }}
        id="el82222vhyq8r"
        className="animable"
      />
    </g>
    <g
      id="freepik--Furniture--inject-3"
      className="animable"
      style={{
        transformOrigin: "100.845px 280.69px",
      }}
    >
      <polygon
        points="140.87 308.97 145.28 311.54 145.27 306.43 140.87 308.97"
        style={{
          fill: "rgb(230, 230, 230)",
          transformOrigin: "143.075px 308.985px",
        }}
        id="elwi9zt3ydzy"
        className="animable"
      />
      <polygon
        points="145.16 281.7 145.16 281.65 145.16 281.65 145.16 281.65 145.16 281.7"
        style={{
          fill: "rgb(120, 120, 120)",
          transformOrigin: "145.16px 281.675px",
        }}
        id="el5zljc5d2jae"
        className="animable"
      />
      <rect
        x={123.67}
        y={269.24}
        height={49.63}
        style={{
          fill: "rgb(207, 207, 207)",
          transformOrigin: "123.67px 294.055px",
        }}
        id="el2eoc9p2aaa"
        className="animable"
      />
      <polygon
        points="145.09 256.84 102.11 281.66 102.11 281.66 145.09 256.84"
        style={{
          fill: "rgb(207, 207, 207)",
          transformOrigin: "123.6px 269.25px",
        }}
        id="elsetcgssf6wp"
        className="animable"
      />
      <path
        d="M145.27,306.43v5.11l4.6-2.66-.17-60L97.45,279l.17,60.05,4.6-2.66v-5.11L140.87,309Zm-43.16-24.77h0l43-24.82.06,19.48-43,24.82Zm.07,24.81h0l43-24.82h0v.06l.06,19.41-43,24.82Z"
        style={{
          fill: "rgb(230, 230, 230)",
          transformOrigin: "123.66px 293.965px",
        }}
        id="el4fjgk3v7kgl"
        className="animable"
      />
      <polygon
        points="145.22 301.12 145.16 281.7 145.16 281.65 102.18 306.47 102.18 306.47 102.23 325.94 145.22 301.12"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "123.7px 303.795px",
        }}
        id="eljcey1i4gtrc"
        className="animable"
      />
      <polygon
        points="145.15 276.32 145.09 256.84 102.11 281.66 102.11 281.66 102.16 301.14 145.15 276.32"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "123.63px 278.99px",
        }}
        id="elyvwvhmy0dgp"
        className="animable"
      />
      <polygon
        points="97.45 279.01 51.82 252.49 51.99 312.54 97.62 339.06 97.45 279.01"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "74.72px 295.775px",
        }}
        id="el30tnqzstisu"
        className="animable"
      />
      <polygon
        points="97.45 279.01 149.71 248.83 104.09 222.32 51.82 252.49 97.45 279.01"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "100.765px 250.665px",
        }}
        id="elo0wlpo8pax"
        className="animable"
      />
      <polygon
        points="140.87 308.97 145.28 311.54 145.27 306.43 140.87 308.97"
        style={{
          fill: "rgb(230, 230, 230)",
          transformOrigin: "143.075px 308.985px",
        }}
        id="elv2cx0ioesk"
        className="animable"
      />
      <polygon
        points="145.16 281.7 145.16 281.65 145.16 281.65 145.16 281.65 145.16 281.7"
        style={{
          fill: "rgb(120, 120, 120)",
          transformOrigin: "145.16px 281.675px",
        }}
        id="elatlebrwz6a4"
        className="animable"
      />
      <rect
        x={123.67}
        y={269.24}
        height={49.63}
        style={{
          fill: "rgb(207, 207, 207)",
          transformOrigin: "123.67px 294.055px",
        }}
        id="elo6kpe30w6or"
        className="animable"
      />
      <polygon
        points="145.09 256.84 102.11 281.66 102.11 281.66 145.09 256.84"
        style={{
          fill: "rgb(207, 207, 207)",
          transformOrigin: "123.6px 269.25px",
        }}
        id="elhzl1wuokeb"
        className="animable"
      />
      <g id="elpuiy1yujywr">
        <polygon
          points="145.22 301.12 145.16 281.7 145.16 281.65 102.18 306.47 102.18 306.47 102.23 325.94 145.22 301.12"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.8,
            transformOrigin: "123.7px 303.795px",
          }}
          className="animable"
        />
      </g>
      <g id="eloheunynp439">
        <polygon
          points="145.15 276.32 145.09 256.84 102.11 281.66 102.11 281.66 102.16 301.14 145.15 276.32"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.8,
            transformOrigin: "123.63px 278.99px",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="131.08 272.18 131.06 269.49 116.17 278.08 116.17 278.08 116.19 280.77 131.08 272.18"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "123.625px 275.13px",
        }}
        id="elk53xfhyasl"
        className="animable"
      />
      <polygon
        points="131.08 296.64 131.06 293.95 116.17 302.54 116.17 302.54 116.19 305.23 131.08 296.64"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "123.625px 299.59px",
        }}
        id="el9vm9wckfnh6"
        className="animable"
      />
      <polygon
        points="97.45 279.01 51.82 252.49 51.99 312.54 97.62 339.06 97.45 279.01"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "74.72px 295.775px",
        }}
        id="el340tx6h13vc"
        className="animable"
      />
      <polygon
        points="97.45 279.01 149.71 248.83 104.09 222.32 51.82 252.49 97.45 279.01"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "100.765px 250.665px",
        }}
        id="el93a1ltigogj"
        className="animable"
      />
    </g>
    <g
      id="freepik--Lamp--inject-3"
      className="animable"
      style={{
        transformOrigin: "239.693px 174.345px",
      }}
    >
      <g id="elac7pasqjgr">
        <path
          d="M251.65,88.24a7.89,7.89,0,0,0-3.17-3.07c-4.88-2.81-12.77-2.81-17.62,0a7.76,7.76,0,0,0-3.13,3.07c-1.22,2.45-.16,5.21,3.19,7.14,4.88,2.82,12.77,2.82,17.62,0C251.87,93.45,252.9,90.69,251.65,88.24Z"
          style={{
            opacity: 0.1,
            transformOrigin: "239.702px 90.2787px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M252.15,254.48c-.08,1.77-1.28,3.53-3.61,4.88-4.85,2.82-12.74,2.82-17.62,0-2.35-1.35-3.56-3.11-3.67-4.88h0v3.93h0c0,1.85,1.23,3.7,3.67,5.11,4.88,2.81,12.77,2.81,17.62,0,2.43-1.41,3.63-3.26,3.61-5.11h0v-3.93Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "239.7px 260.054px",
        }}
        id="elb2n6kcf0v6f"
        className="animable"
      />
      <path
        d="M230.92,259.36c4.88,2.82,12.77,2.82,17.62,0,2.33-1.35,3.53-3.11,3.61-4.88a4.49,4.49,0,0,0-.5-2.26,7.89,7.89,0,0,0-3.17-3.07c-4.88-2.81-12.77-2.81-17.62,0a7.68,7.68,0,0,0-3.13,3.06,4.57,4.57,0,0,0-.48,2.26C227.36,256.25,228.57,258,230.92,259.36Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "239.7px 254.259px",
        }}
        id="elcsk1a58wolq"
        className="animable"
      />
      <rect
        x={229.93}
        y={124.55}
        width={0.37}
        height={32.85}
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "230.115px 140.975px",
        }}
        id="elx3rfmqtmyw"
        className="animable"
      />
      <path
        d="M231,157.76a.91.91,0,1,1-.91-.91A.91.91,0,0,1,231,157.76Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "230.09px 157.76px",
        }}
        id="elu38r3316z2j"
        className="animable"
      />
      <path
        d="M241.94,112.71h-4.48V253.18h0a1.11,1.11,0,0,0,.65.92,3.48,3.48,0,0,0,3.17,0,1.08,1.08,0,0,0,.64-.92h0Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "239.7px 183.596px",
        }}
        id="elg70nviejhp"
        className="animable"
      />
      <path
        d="M265.43,118.76h0c-2.87-10-13.8-30.51-13.8-30.51h0c1.25,2.45.22,5.21-3.11,7.14-4.85,2.82-12.74,2.82-17.62,0-3.35-1.93-4.41-4.69-3.19-7.14h0s-10.93,20.56-13.79,30.51h0c-1.94,5,.44,10.4,7.14,14.27,10.35,6,27.08,6,37.37,0C265.1,129.15,267.42,123.72,265.43,118.76Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "239.693px 112.89px",
        }}
        id="elglmoxpmhqfp"
        className="animable"
      />
      <path
        d="M251.65,88.24a7.89,7.89,0,0,0-3.17-3.07c-4.88-2.81-12.77-2.81-17.62,0a7.76,7.76,0,0,0-3.13,3.07c-1.22,2.45-.16,5.21,3.19,7.14,4.88,2.82,12.77,2.82,17.62,0C251.87,93.45,252.9,90.69,251.65,88.24Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "239.702px 90.2787px",
        }}
        id="elwshm50bksp"
        className="animable"
      />
    </g>
    <g
      id="freepik--plant-2--inject-3"
      className="animable"
      style={{
        transformOrigin: "102.216px 222.506px",
      }}
    >
      <g id="eliqu3nbkbrzj">
        <path
          d="M91.38,241.22c5.81-3.35,15.18-3.35,21,0s5.73,8.78-.07,12.13-15.18,3.35-21,0S85.58,244.57,91.38,241.22Z"
          style={{
            opacity: 0.05,
            transformOrigin: "101.845px 247.285px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M86.35,220.13l15.92.47,15.92-.47c.69,10.76-1.41,21.71-4.31,27.06h0a7.25,7.25,0,0,1-3.15,3.21c-4.7,2.71-12.29,2.71-17,0a7.09,7.09,0,0,1-3.14-3.29C87.74,241.73,85.67,230.83,86.35,220.13Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "102.271px 236.281px",
        }}
        id="el3mkejmfsl"
        className="animable"
      />
      <path
        d="M91.05,213.61c-6.24,3.61-6.27,9.44-.07,13s16.27,3.61,22.51,0,6.27-9.44.07-13S97.29,210,91.05,213.61Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "102.27px 220.11px",
        }}
        id="el64tkq2iwevx"
        className="animable"
      />
      <path
        d="M93.52,215.05c-4.87,2.81-4.9,7.36-.06,10.17s12.7,2.81,17.56,0,4.9-7.36.06-10.17S98.38,212.24,93.52,215.05Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "102.268px 220.135px",
        }}
        id="el380dejzvuyy"
        className="animable"
      />
      <g id="el11mtutrgna0q">
        <g
          style={{
            opacity: 0.15,
            transformOrigin: "102.268px 220.135px",
          }}
          className="animable"
        >
          <path
            d="M93.52,215.05c-4.87,2.81-4.9,7.36-.06,10.17s12.7,2.81,17.56,0,4.9-7.36.06-10.17S98.38,212.24,93.52,215.05Z"
            id="elf863ru6j2z"
            className="animable"
            style={{
              transformOrigin: "102.268px 220.135px",
            }}
          />
        </g>
      </g>
      <path
        d="M103.6,220.58a10.49,10.49,0,0,1-9.34,2.13,9.78,9.78,0,0,1-6.54-6.62,19.16,19.16,0,0,0,5.11-3.55,13.14,13.14,0,0,0-7.24.26,7.38,7.38,0,0,1-.54-6.9,12.64,12.64,0,0,1,8.63-.6A11.46,11.46,0,0,0,85,202.55a33.34,33.34,0,0,1,2.1-13.4c5.93.3,11.69,2.86,13.63,4.7a21.66,21.66,0,0,1-.66,6.31,5.12,5.12,0,0,0,2.76-4.08c3.71.93,5.3,2.78,5.88,4.2a15.51,15.51,0,0,1-3.52,7.12c3.49-1.05,4.91-4.94,4.91-4.94C113.48,210.59,112.82,219.79,103.6,220.58Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "98.2045px 206.093px",
        }}
        id="eljhcjcum14cq"
        className="animable"
      />
      <g id="elajrsuy5b7o">
        <path
          d="M103.6,220.58a10.49,10.49,0,0,1-9.34,2.13,9.78,9.78,0,0,1-6.54-6.62,19.16,19.16,0,0,0,5.11-3.55,13.14,13.14,0,0,0-7.24.26,7.38,7.38,0,0,1-.54-6.9,12.64,12.64,0,0,1,8.63-.6A11.46,11.46,0,0,0,85,202.55a33.34,33.34,0,0,1,2.1-13.4c5.93.3,11.69,2.86,13.63,4.7a21.66,21.66,0,0,1-.66,6.31,5.12,5.12,0,0,0,2.76-4.08c3.71.93,5.3,2.78,5.88,4.2a15.51,15.51,0,0,1-3.52,7.12c3.49-1.05,4.91-4.94,4.91-4.94C113.48,210.59,112.82,219.79,103.6,220.58Z"
          style={{
            opacity: 0.2,
            transformOrigin: "98.2045px 206.093px",
          }}
          className="animable"
        />
      </g>
      <g id="el3wo3shgzsqw">
        <path
          d="M103.6,220.58a10.49,10.49,0,0,1-9.34,2.13,9.78,9.78,0,0,1-6.54-6.62,19.16,19.16,0,0,0,5.11-3.55,13.14,13.14,0,0,0-7.24.26,7.38,7.38,0,0,1-.54-6.9,12.64,12.64,0,0,1,8.63-.6A11.46,11.46,0,0,0,85,202.55a33.34,33.34,0,0,1,2.1-13.4c5.93.3,11.69,2.86,13.63,4.7a21.66,21.66,0,0,1-.66,6.31,5.12,5.12,0,0,0,2.76-4.08c3.71.93,5.3,2.78,5.88,4.2a15.51,15.51,0,0,1-3.52,7.12c3.49-1.05,4.91-4.94,4.91-4.94C113.48,210.59,112.82,219.79,103.6,220.58Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.3,
            transformOrigin: "98.2045px 206.093px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M103.81,221.46h0a.34.34,0,0,0,.32-.36c-.57-7.5-4.86-19.87-15.08-30.08a.35.35,0,1,0-.49.49c10.1,10.07,14.33,22.26,14.88,29.64A.35.35,0,0,0,103.81,221.46Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "96.2753px 206.17px",
        }}
        id="elavcqocby748"
        className="animable"
      />
      <path
        d="M101,224.6a9.75,9.75,0,0,0,15.24-4,16.38,16.38,0,0,1-4.61-3.14,13.12,13.12,0,0,1,6.84.2,6.39,6.39,0,0,0,1-6.15,12.6,12.6,0,0,0-8.15-.49,12,12,0,0,1,8.45-2.5,27.3,27.3,0,0,0-1.14-12c-5.66.34-11.26,2.68-13.2,4.34a17.76,17.76,0,0,0,.23,5.6,4.11,4.11,0,0,1-2.35-3.6c-3.54.86-5.14,2.52-5.77,3.77a12.44,12.44,0,0,0,2.86,6.29c-3.2-.91-4.29-4.34-4.29-4.34C92.39,215.8,92.44,223.91,101,224.6Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "106.876px 211.657px",
        }}
        id="elfne2yx6byum"
        className="animable"
      />
      <g id="eljz9f3l46jv">
        <path
          d="M101,224.6a9.75,9.75,0,0,0,15.24-4,16.38,16.38,0,0,1-4.61-3.14,13.12,13.12,0,0,1,6.84.2,6.39,6.39,0,0,0,1-6.15,12.6,12.6,0,0,0-8.15-.49,12,12,0,0,1,8.45-2.5,27.3,27.3,0,0,0-1.14-12c-5.66.34-11.26,2.68-13.2,4.34a17.76,17.76,0,0,0,.23,5.6,4.11,4.11,0,0,1-2.35-3.6c-3.54.86-5.14,2.52-5.77,3.77a12.44,12.44,0,0,0,2.86,6.29c-3.2-.91-4.29-4.34-4.29-4.34C92.39,215.8,92.44,223.91,101,224.6Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.3,
            transformOrigin: "106.876px 211.657px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M100.77,225.41a.34.34,0,0,0,.34-.29c1-6.53,5.72-17.36,15.88-26.41a.36.36,0,0,0,0-.49.35.35,0,0,0-.49,0c-10.29,9.17-15.1,20.18-16.09,26.82a.34.34,0,0,0,.28.39Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "108.746px 211.775px",
        }}
        id="el31nyhqcepaf"
        className="animable"
      />
    </g>
    <g
      id="freepik--plant-1--inject-3"
      className="animable"
      style={{
        transformOrigin: "77.345px 350.738px",
      }}
    >
      <path
        d="M95.07,371.86l-18.87.55-18.88-.55c-.81,12.75,1.68,25.73,5.11,32.08h0a8.63,8.63,0,0,0,3.74,3.8c5.57,3.22,14.57,3.22,20.1,0a8.43,8.43,0,0,0,3.74-3.9C93.42,397.46,95.88,384.54,95.07,371.86Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "76.1957px 391.007px",
        }}
        id="elw0g7293mcto"
        className="animable"
      />
      <path
        d="M89.5,364.13c7.39,4.27,7.43,11.19.09,15.46s-19.3,4.27-26.7,0-7.43-11.19-.08-15.46S82.11,359.86,89.5,364.13Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "76.1938px 371.86px",
        }}
        id="elgpz3tp2reqo"
        className="animable"
      />
      <path
        d="M86.58,365.83c5.77,3.33,5.8,8.73.06,12.06s-15,3.33-20.82,0-5.8-8.73-.07-12.06S80.81,362.5,86.58,365.83Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "76.19px 371.86px",
        }}
        id="eltx71thdng8j"
        className="animable"
      />
      <g id="el0moooums2qyr">
        <g
          style={{
            opacity: 0.15,
            transformOrigin: "76.19px 371.86px",
          }}
          className="animable"
        >
          <path
            d="M86.58,365.83c5.77,3.33,5.8,8.73.06,12.06s-15,3.33-20.82,0-5.8-8.73-.07-12.06S80.81,362.5,86.58,365.83Z"
            id="elne0yb1rod2"
            className="animable"
            style={{
              transformOrigin: "76.19px 371.86px",
            }}
          />
        </g>
      </g>
      <path
        d="M107.23,291.32C64.7,305.25,64.31,333.7,73.9,354c2.42,4.11,9.74,6.31,16.11-18.89L78.83,336.5l13-6.6,3.39-10.11-10.86.81,13.78-5.8A205,205,0,0,1,107.23,291.32Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "88.0215px 323.925px",
        }}
        id="el3lkuk0qszog"
        className="animable"
      />
      <g id="elbelxdvo2ns">
        <path
          d="M107.23,291.32C64.7,305.25,64.31,333.7,73.9,354c2.42,4.11,9.74,6.31,16.11-18.89L78.83,336.5l13-6.6,3.39-10.11-10.86.81,13.78-5.8A205,205,0,0,1,107.23,291.32Z"
          style={{
            opacity: 0.2,
            transformOrigin: "88.0215px 323.925px",
          }}
          className="animable"
        />
      </g>
      <g id="elswa2b6vh39d">
        <path
          d="M107.23,291.32C64.7,305.25,64.31,333.7,73.9,354c2.42,4.11,9.74,6.31,16.11-18.89L78.83,336.5l13-6.6,3.39-10.11-10.86.81,13.78-5.8A205,205,0,0,1,107.23,291.32Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            transformOrigin: "88.0215px 323.925px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M95.2,298.44c-22.43,17-24.46,36.54-18.12,56.91C74.84,337.41,72.42,319.27,95.2,298.44Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "84.5776px 326.895px",
        }}
        id="el44pxhmcl1mw"
        className="animable"
      />
      <path
        d="M35.19,320.22c41.92,5.91,47,32.34,41.45,52.81-1.57,4.22-8,7.49-18.14-14.92l10.64-.53-13.22-4-4.83-8.85,10.24-1.05-13.79-3.11A193.26,193.26,0,0,0,35.19,320.22Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "56.8388px 348.146px",
        }}
        id="ela5z22n1sp7b"
        className="animable"
      />
      <g id="elpkww3n7bxr">
        <path
          d="M35.19,320.22c41.92,5.91,47,32.34,41.45,52.81-1.57,4.22-8,7.49-18.14-14.92l10.64-.53-13.22-4-4.83-8.85,10.24-1.05-13.79-3.11A193.26,193.26,0,0,0,35.19,320.22Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            transformOrigin: "56.8388px 348.146px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M47.57,324.85c23.72,12.13,28.85,30,26.33,50C73,357.77,72.25,340.47,47.57,324.85Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "61.0254px 349.85px",
        }}
        id="elsgvlqisbzoa"
        className="animable"
      />
      <g id="el3pefvixwavg">
        <path
          d="M35.19,320.22c41.92,5.91,47,32.34,41.45,52.81-1.57,4.22-8,7.49-18.14-14.92l10.64-.53-13.22-4-4.83-8.85,10.24-1.05-13.79-3.11A193.26,193.26,0,0,0,35.19,320.22Z"
          style={{
            opacity: 0.1,
            transformOrigin: "56.8388px 348.146px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M119.5,330.13c-38.45.63-46,23.89-43.27,43,.94,4,6.39,7.64,18-11.42L84.72,360l12.36-2.11,5.34-7.43-9.11-2.1,12.78-1.26A176.28,176.28,0,0,1,119.5,330.13Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "97.6134px 353.229px",
        }}
        id="el5nhu1raxttn"
        className="animable"
      />
      <path
        d="M107.82,332.92C85.08,341.19,78.47,356.7,78.49,375,81.21,359.74,83.83,344.23,107.82,332.92Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "93.155px 353.96px",
        }}
        id="el255vu8k1d2b"
        className="animable"
      />
      <g id="elji2aynh1jvl">
        <path
          d="M119.5,330.13c-38.45.63-46,23.89-43.27,43,.94,4,6.39,7.64,18-11.42L84.72,360l12.36-2.11,5.34-7.43-9.11-2.1,12.78-1.26A176.28,176.28,0,0,1,119.5,330.13Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            transformOrigin: "97.6134px 353.229px",
          }}
          className="animable"
        />
      </g>
    </g>
    <g
      id="freepik--Device--inject-3"
      className="animable"
      style={{
        transformOrigin: "371.09px 207.778px",
      }}
    >
      <path
        d="M441.74,105a11.63,11.63,0,0,0-4.7-5L314.79,30.45A5.35,5.35,0,0,0,313,30.1c-6.3-.13-12.62,5.8-13.51,8.91h0a2.8,2.8,0,0,0-.31,1.27V304.62a11.69,11.69,0,0,0,5.9,10.16l122.26,69.67a3.85,3.85,0,0,0,.47.19l0,0c7.29,3.09,15.06-3.34,15.06-10.07V110.16A11.7,11.7,0,0,0,441.74,105Z"
        style={{
          fill: "rgb(55, 71, 79)",
          transformOrigin: "371.025px 207.768px",
        }}
        id="elxfpysmgflth"
        className="animable"
      />
      <path
        d="M299.53,39h0c.89-3.11,7.21-9,13.51-8.91a5.35,5.35,0,0,1,1.76.35L437,100A11.87,11.87,0,0,1,443,110.16V374.6c0,6.73-7.77,13.16-15.06,10.07l0,0a3.85,3.85,0,0,1-.47-.19L305.12,314.78a11.69,11.69,0,0,1-5.9-10.16V40.29A2.8,2.8,0,0,1,299.53,39Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "371.11px 207.778px",
        }}
        id="elcto9otefk1p"
        className="animable"
      />
      <path
        d="M425.9,138.11A5.86,5.86,0,0,0,423,133L309.44,68.45A2.92,2.92,0,0,0,305.07,71V284.22A5.84,5.84,0,0,0,308,289.3L421.53,354a2.92,2.92,0,0,0,4.37-2.54Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "365.485px 211.225px",
        }}
        id="el8b1p8is8cc5"
        className="animable"
      />
      <g id="el38sr0r7bokd">
        <path
          d="M425.9,138.11A5.86,5.86,0,0,0,423,133L309.44,68.45A2.92,2.92,0,0,0,305.07,71V284.22A5.84,5.84,0,0,0,308,289.3L421.53,354a2.92,2.92,0,0,0,4.37-2.54Z"
          style={{
            opacity: 0.2,
            transformOrigin: "365.485px 211.225px",
          }}
          className="animable"
        />
      </g>
      <g id="el6px1xu6ktg">
        <path
          d="M443,110.16V374.6c0,6.73-7.77,13.16-15.06,10.07l0,0a2.91,2.91,0,0,0,3.9-2.73V117.47a11.64,11.64,0,0,0-2-6.46l12-6A11.7,11.7,0,0,1,443,110.16Z"
          style={{
            opacity: 0.3,
            transformOrigin: "435.47px 245.239px",
          }}
          className="animable"
        />
      </g>
      <g id="els7fn2kmtaf">
        <path
          d="M313,30.1a5.35,5.35,0,0,1,1.76.35L437,100a11.63,11.63,0,0,1,4.7,5l-12,6a11.53,11.53,0,0,0-3.93-3.7L303.59,37.75A2.92,2.92,0,0,0,299.53,39h0C300.41,35.9,306.73,30,313,30.1Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.1,
            transformOrigin: "370.615px 70.5494px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M350.42,81.42c-.86-.5-1.57-.11-1.58.88a3.47,3.47,0,0,0,1.57,2.7l24,13.85c.86.5,1.55.08,1.56-.91a3.4,3.4,0,0,0-1.55-2.67Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "362.405px 90.1319px",
        }}
        id="elnjivp85a8yh"
        className="animable"
      />
      <path
        d="M380.56,98.82c-.86-.49-1.55-.1-1.55.89a3.41,3.41,0,0,0,1.54,2.7c.86.49,1.57.09,1.57-.9A3.42,3.42,0,0,0,380.56,98.82Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "380.565px 100.614px",
        }}
        id="el8kq8xy40jwe"
        className="animable"
      />
      <path
        d="M365.21,79.41c-1.72-1-3.1-.21-3.11,1.77a6.85,6.85,0,0,0,3.09,5.38c1.71,1,3.14.18,3.14-1.79A6.85,6.85,0,0,0,365.21,79.41Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "365.215px 82.982px",
        }}
        id="el146n26z9wsa"
        className="animable"
      />
      <path
        d="M365.2,328.35c-3.49-2-6.31-.41-6.32,3.6a13.93,13.93,0,0,0,6.28,11c3.49,2,6.39.36,6.4-3.65A14,14,0,0,0,365.2,328.35Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "365.22px 335.645px",
        }}
        id="elmzmivsfp4mo"
        className="animable"
      />
    </g>
    <g
      id="freepik--Screen--inject-3"
      className="animable"
      style={{
        transformOrigin: "342.14px 237.58px",
      }}
    >
      <path
        d="M406.71,145.4c-.14-.37-.3-.74-.47-1.1a10.85,10.85,0,0,0-2.93-3.67,11.19,11.19,0,0,0-1.34-.9L291,76.6a2.56,2.56,0,0,0-.57-.18,6.56,6.56,0,0,0-1-.13c-3.57-.07-7.15,2-9.53,4.25a10,10,0,0,0-2.73,3.84h0a3.62,3.62,0,0,0-.2.55,2.43,2.43,0,0,0-.08.61V325.47h0a10.57,10.57,0,0,0,1.44,5.33,11,11,0,0,0,.77,1.15,10.68,10.68,0,0,0,3.15,2.74l111,63.23a3.79,3.79,0,0,0,.42.18s0,0,.05,0c6.62,2.8,13.67-3,13.67-9.15V149h0A10.62,10.62,0,0,0,406.71,145.4Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "342.14px 237.556px",
        }}
        id="elg26jpnkqba"
        className="animable"
      />
      <path
        d="M277.15,84.38h0c.81-2.83,6.55-8.21,12.26-8.09a4.82,4.82,0,0,1,1.61.31l111,63.13a10.75,10.75,0,0,1,5.37,9.23V389c0,6.12-7,11.95-13.67,9.15,0,0,0,0-.05,0a3.79,3.79,0,0,1-.42-.18l-111-63.23a10.63,10.63,0,0,1-5.36-9.22V85.54A2.61,2.61,0,0,1,277.15,84.38Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "342.14px 237.58px",
        }}
        id="elfk98w5ozgt5"
        className="animable"
      />
      <g id="elw6ed9bi9clp">
        <path
          d="M407.34,149V389c0,6.12-7,11.95-13.67,9.15,0,0,0,0-.05,0a2.65,2.65,0,0,0,3.55-2.48v-240a10.58,10.58,0,0,0-1.8-5.86l10.87-5.43A10.58,10.58,0,0,1,407.34,149Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            transformOrigin: "400.48px 271.626px",
          }}
          className="animable"
        />
      </g>
      <g id="elrjskzo73c3g">
        <path
          d="M289.4,76.29a4.82,4.82,0,0,1,1.61.31l111,63.13a10.62,10.62,0,0,1,4.27,4.57l-10.87,5.43a10.46,10.46,0,0,0-3.57-3.36l-111-63.14a2.66,2.66,0,0,0-3.69,1.15h0C278,81.55,283.69,76.17,289.4,76.29Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.4,
            transformOrigin: "341.715px 113.009px",
          }}
          className="animable"
        />
      </g>
      <g id="elilnr6se2jyi">
        <path
          d="M277.15,84.38h0c.81-2.83,6.55-8.21,12.26-8.09a4.82,4.82,0,0,1,1.61.31l111,63.13a10.75,10.75,0,0,1,5.37,9.23V389c0,6.12-7,11.95-13.67,9.15,0,0,0,0-.05,0a3.79,3.79,0,0,1-.42-.18l-111-63.23a10.63,10.63,0,0,1-5.36-9.22V85.54A2.61,2.61,0,0,1,277.15,84.38Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.3,
            transformOrigin: "342.14px 237.58px",
          }}
          className="animable"
        />
      </g>
      <g id="elsgonbkkps2">
        <path
          d="M286.62,149h0a10.39,10.39,0,0,0-.64-3.56A10.39,10.39,0,0,1,286.62,149Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.3,
            transformOrigin: "286.3px 147.22px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M385.78,199.11,286.88,142c-.95-.55-1.72-.11-1.72,1L285,254.47a3.8,3.8,0,0,0,1.72,3l98.89,57.1c.95.54,1.72.11,1.73-1l.14-111.48A3.77,3.77,0,0,0,385.78,199.11Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "336.24px 228.283px",
        }}
        id="eltnl8nd9xu3j"
        className="animable"
      />
      <polygon
        points="291.41 104.96 292.59 105.64 292.6 100.59 291.43 99.91 291.41 104.96"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "292.005px 102.775px",
        }}
        id="elc359xcvos5r"
        className="animable"
      />
      <polygon
        points="289.39 103.79 290.56 104.47 290.58 100.38 289.4 99.71 289.39 103.79"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "289.985px 102.09px",
        }}
        id="elepgjwvyilsu"
        className="animable"
      />
      <polygon
        points="287.37 102.62 288.54 103.3 288.55 100.27 287.38 99.59 287.37 102.62"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "287.96px 101.445px",
        }}
        id="el0pmhp7wb7h8"
        className="animable"
      />
      <polygon
        points="285.35 101.46 286.52 102.13 286.53 100.09 285.35 99.41 285.35 101.46"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "285.94px 100.77px",
        }}
        id="el7abtxd9fmph"
        className="animable"
      />
      <polygon
        points="295.79 107.48 302.98 111.64 302.99 107.4 295.8 103.24 295.79 107.48"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "299.39px 107.44px",
        }}
        id="elppowt641zbl"
        className="animable"
      />
      <polygon
        points="306.56 113.7 310.16 115.78 310.17 111.54 306.57 109.47 306.56 113.7"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "308.365px 112.625px",
        }}
        id="elydjbfimdg79"
        className="animable"
      />
      <polygon
        points="380.82 156.05 380.83 152.63 384.69 154.86 380.83 152.63 380.82 156.05"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "382.755px 154.34px",
        }}
        id="elrsgdp632x4"
        className="animable"
      />
      <polygon
        points="380.82 156.05 384.69 158.28 384.69 154.86 380.83 152.63 380.82 156.05"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "382.755px 155.455px",
        }}
        id="eleingfxfzgb9"
        className="animable"
      />
      <path
        d="M387.41,157v-1.34l-7.29-4.21v5l7.29,4.21v-1.33l.93.54v-2.38Zm-2.72,1.33-3.87-2.23v-3.42l3.87,2.23Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "384.23px 156.055px",
        }}
        id="elvq9h4popey"
        className="animable"
      />
      <polygon
        points="369.67 150.06 376.86 154.21 376.88 149.97 369.68 145.82 369.67 150.06"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "373.275px 150.015px",
        }}
        id="elrk8x1txz2x"
        className="animable"
      />
      <polygon
        points="335.33 129.98 342.52 134.14 342.54 129.9 335.34 125.74 335.33 129.98"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "338.935px 129.94px",
        }}
        id="el8apnuuimrol"
        className="animable"
      />
      <polygon
        points="318.5 310.24 318.49 314.47 336.93 325.12 336.95 320.89 318.5 310.24"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "327.72px 317.68px",
        }}
        id="eld8wnu7mv13"
        className="animable"
      />
      <polygon
        points="345.4 311.95 326.42 300.98 326.4 305.21 345.39 316.17 345.4 311.95"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "335.9px 308.575px",
        }}
        id="elq7eq6kesuxb"
        className="animable"
      />
      <polygon
        points="286.74 288.9 310.05 302.45 310.06 298.23 286.75 284.68 286.74 288.9"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "298.4px 293.565px",
        }}
        id="elbl3qmuprim"
        className="animable"
      />
      <polygon
        points="321.31 298.04 286.77 278 286.76 282.22 321.3 302.26 321.31 298.04"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "304.035px 290.13px",
        }}
        id="elce3pmafx3ck"
        className="animable"
      />
      <polygon
        points="315.14 305.39 357.2 329.67 357.21 325.45 315.16 301.17 315.14 305.39"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "336.175px 315.42px",
        }}
        id="elm2p35cjg9i"
        className="animable"
      />
      <polygon
        points="314.02 307.66 286.77 291.93 286.76 296.15 314.01 311.88 314.02 307.66"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "300.39px 301.905px",
        }}
        id="elfpstkwcr13v"
        className="animable"
      />
      <polygon
        points="350.29 319 383.9 338.41 383.92 334.18 350.3 314.77 350.29 319"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "367.105px 326.59px",
        }}
        id="elqorz992du3d"
        className="animable"
      />
      <polygon
        points="362.1 332.5 383.89 345.08 383.9 340.86 362.12 328.28 362.1 332.5"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "373px 336.68px",
        }}
        id="elbr9jssxetm7"
        className="animable"
      />
      <polygon
        points="286.76 310.67 328.82 334.95 328.83 330.73 286.77 306.44 286.76 310.67"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "307.795px 320.695px",
        }}
        id="elot2b39o184g"
        className="animable"
      />
      <polygon
        points="286.77 316.42 286.76 320.65 314.02 336.38 314.02 332.15 286.77 316.42"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "300.39px 326.4px",
        }}
        id="elfxa7weet5ls"
        className="animable"
      />
      <polygon
        points="335.06 344.29 318.44 334.7 318.44 338.93 335.05 348.52 335.06 344.29"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "326.75px 341.61px",
        }}
        id="el54260wdbz5x"
        className="animable"
      />
      <path
        d="M289.28,264.68a1.77,1.77,0,0,0-1.79-.18c-1,.56-1,2.39,0,4.09l.57,1,3.56,6.16,3.58-2,.58-.33c1-.56,1-2.4,0-4.1A5.23,5.23,0,0,0,294,267.4a1.82,1.82,0,0,0-1.79-.18l-.58.33-.57-1A5.11,5.11,0,0,0,289.28,264.68Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "291.635px 270.043px",
        }}
        id="elies5kq7uqrq"
        className="animable"
      />
      <path
        d="M304.12,273.45a9.68,9.68,0,0,1,4.39,7.6,3.76,3.76,0,0,1-.6,2.18l.58,2.87-2.2-1.94a3.89,3.89,0,0,1-2.2-.59,9.68,9.68,0,0,1-4.38-7.6C299.72,273.17,301.69,272.05,304.12,273.45Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "304.111px 279.474px",
        }}
        id="elvl2y5r0uqgj"
        className="animable"
      />
      <polygon
        points="341.79 183.75 346.9 193.09 354.33 195.68 357.47 205.4 364.98 210.76 365.85 219.9 372.71 227.51 371.2 235.18 376.74 244.28 373.02 249.73 376.7 259.45 371.13 262.14 372.58 271.52 365.7 271.19 364.79 279.31 357.27 275.99 354.09 282.06 346.67 276.07 341.52 279.5 334.93 271.44 328.32 271.88 323.2 262.53 315.78 259.95 312.64 250.22 305.13 244.87 304.27 235.72 297.41 228.12 298.91 220.44 293.37 211.34 297.09 205.89 293.41 196.17 298.99 193.48 297.53 184.1 304.41 184.44 305.32 176.32 312.84 179.63 316.03 173.56 323.44 179.55 328.59 176.13 335.18 184.19 341.79 183.75"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "335.055px 227.81px",
        }}
        id="elnz7yjv4ia6"
        className="animable"
      />
      <g id="elc666wsdl6do">
        <g
          style={{
            opacity: 0.4,
            transformOrigin: "335.055px 227.81px",
          }}
          className="animable"
        >
          <polygon
            points="341.79 183.75 346.9 193.09 354.33 195.68 357.47 205.4 364.98 210.76 365.85 219.9 372.71 227.51 371.2 235.18 376.74 244.28 373.02 249.73 376.7 259.45 371.13 262.14 372.58 271.52 365.7 271.19 364.79 279.31 357.27 275.99 354.09 282.06 346.67 276.07 341.52 279.5 334.93 271.44 328.32 271.88 323.2 262.53 315.78 259.95 312.64 250.22 305.13 244.87 304.27 235.72 297.41 228.12 298.91 220.44 293.37 211.34 297.09 205.89 293.41 196.17 298.99 193.48 297.53 184.1 304.41 184.44 305.32 176.32 312.84 179.63 316.03 173.56 323.44 179.55 328.59 176.13 335.18 184.19 341.79 183.75"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "335.055px 227.81px",
            }}
            id="elp9bzuz0wbr"
            className="animable"
          />
        </g>
      </g>
      <path
        d="M335.16,191.31c17.54,10.13,31.71,34.68,31.65,54.84S352.49,274.43,335,264.31s-31.71-34.68-31.66-54.84S317.61,181.19,335.16,191.31Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "335.075px 227.81px",
        }}
        id="el4tk7nlhb84"
        className="animable"
      />
      <g id="elcv62s9sy3tu">
        <g
          style={{
            opacity: 0.2,
            transformOrigin: "335.075px 227.81px",
          }}
          className="animable"
        >
          <path
            d="M335.16,191.31c17.54,10.13,31.71,34.68,31.65,54.84S352.49,274.43,335,264.31s-31.71-34.68-31.66-54.84S317.61,181.19,335.16,191.31Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "335.075px 227.81px",
            }}
            id="elnjsk0m4dmjd"
            className="animable"
          />
        </g>
      </g>
      <path
        d="M356.76,223c.77.44,1.23,1.89.54,2.14-6.31,2.3-19.68,7.81-27.84,15.73a1.64,1.64,0,0,1-2,0,5.21,5.21,0,0,1-2-2.22,209.89,209.89,0,0,0-12.57-21.89c-.84-1.28-.3-2.49.7-1.91a1.38,1.38,0,0,1,.23.16,69.61,69.61,0,0,1,13.11,14.65c12.36-7.94,23.58-7.73,29.5-6.78A1,1,0,0,1,356.76,223Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "335.057px 227.955px",
        }}
        id="el0fmyggra6dnk"
        className="animable"
      />
      <path
        d="M293.46,126.18a14.08,14.08,0,0,1,6.38,11.06c0,4.06-2.89,5.7-6.42,3.66A14.08,14.08,0,0,1,287,129.84C287.05,125.78,289.92,124.14,293.46,126.18Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "293.42px 133.54px",
        }}
        id="elm4096vio3vq"
        className="animable"
      />
      <polygon
        points="340.22 157.92 305.68 137.88 305.67 142.11 340.21 162.15 340.22 157.92"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "322.945px 150.015px",
        }}
        id="elf7jgqtdis2j"
        className="animable"
      />
    </g>
    <g
      id="freepik--Character--inject-3"
      className="animable"
      style={{
        transformOrigin: "177.894px 260.526px",
      }}
    >
      <path
        d="M186.11,165.05h1c6.5.08,14.71,4.5,18.73,15.84l15.61,30.22L203.6,219l-9.75-28.53Z"
        style={{
          fill: "rgb(255, 168, 167)",
          transformOrigin: "203.78px 192.025px",
        }}
        id="eleuig8bsgfw"
        className="animable"
      />
      <path
        d="M198.62,185.12c1.24,3.57,1.32,7.42,1.52,11.19s.63,8.34,2.36,11.7a10.73,10.73,0,0,1,12.84.47,9.88,9.88,0,0,1,2.89,4.06L203.6,219l-9.75-28.53-4.79-15.73A18.2,18.2,0,0,1,198.62,185.12Z"
        style={{
          fill: "rgb(242, 143, 143)",
          transformOrigin: "203.645px 196.87px",
        }}
        id="elnt24ite44"
        className="animable"
      />
      <path
        d="M203.44,213.57a10.65,10.65,0,0,1,8.06-6.52c3.56-.57,7.61,1.29,9.92,4.07,1.94,2.35,1.64,5.53.41,8.32a10.37,10.37,0,0,1-11.89,5.63C205.34,223.79,201.7,218,203.44,213.57Z"
        style={{
          fill: "rgb(255, 168, 167)",
          transformOrigin: "212.906px 216.163px",
        }}
        id="el0qs7ayjrqgac"
        className="animable"
      />
      <path
        d="M146.71,408.44a4.67,4.67,0,0,1-4.32,2.59,4.6,4.6,0,0,1-3.48-3,12.9,12.9,0,0,1-.71-4.71q-.16-8,.38-15.94a53.92,53.92,0,0,0,11.13,1.16L148,403.29A13.72,13.72,0,0,1,146.71,408.44Z"
        style={{
          fill: "rgb(255, 168, 167)",
          transformOrigin: "143.937px 399.206px",
        }}
        id="elr5nuynnp2xr"
        className="animable"
      />
      <path
        d="M135.09,400.79c1.38-4.43,2.89-9,2.57-13.59l.92.22q-.54,7.95-.38,15.94a12.9,12.9,0,0,0,.71,4.71,4.6,4.6,0,0,0,3.48,3,4.67,4.67,0,0,0,4.32-2.59,13.72,13.72,0,0,0,1.29-5.15l1.71-14.71,1.18,0c-1,5.5-.29,11.15.06,16.74s.29,11.41-2.11,16.46a7.45,7.45,0,0,1-2.72,3.36,5.62,5.62,0,0,1-4.42.4,9.88,9.88,0,0,1-3.84-2.38,15.83,15.83,0,0,1-4.65-8.75C132.43,409.86,133.71,405.21,135.09,400.79Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "142.039px 406.529px",
        }}
        id="elgc7pjp3gxij"
        className="animable"
      />
      <path
        d="M193.08,396.55l-.91,5.48c-4.35-2.08-5.75-4.41-5.75-4.41-.11-4.2-.06-8.53,0-10.64,1.16,2.56,3.69,4.29,5.27,6.65A13.15,13.15,0,0,1,193.08,396.55Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "189.717px 394.505px",
        }}
        id="el8227p890rgq"
        className="animable"
      />
      <path
        d="M194.48,401.43l-2.31.6.91-5.48C193.65,398.12,194,399.8,194.48,401.43Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "193.325px 399.29px",
        }}
        id="eli2827lf0jx"
        className="animable"
      />
      <g id="elnaad66ifqhk">
        <path
          d="M193.08,396.55l-.91,5.48c-4.35-2.08-5.75-4.41-5.75-4.41-.11-4.2-.06-8.53,0-10.64,1.16,2.56,3.69,4.29,5.27,6.65A13.15,13.15,0,0,1,193.08,396.55Z"
          style={{
            opacity: 0.2,
            transformOrigin: "189.717px 394.505px",
          }}
          className="animable"
        />
      </g>
      <g id="elgh4ngg72y2q">
        <path
          d="M194.48,401.43l-2.31.6.91-5.48C193.65,398.12,194,399.8,194.48,401.43Z"
          style={{
            opacity: 0.3,
            transformOrigin: "193.325px 399.29px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M186.18,380.9a23,23,0,0,1,1.8-4.18,12.06,12.06,0,0,0,2,6.32c1.56,2.52,3.65,4.69,5.16,7.26,1.83,3.12,2.78,6.79,5.18,9.49a11.37,11.37,0,0,0,9.47,3.69,5.08,5.08,0,0,0,3.48-1.56,2.73,2.73,0,0,0,.57-1.65,25.5,25.5,0,0,0,3.91,3.42c1.58,1.1,3.48,2.26,3.74,4.17a4.11,4.11,0,0,1-2.27,3.89,10.78,10.78,0,0,1-4.63,1,29.83,29.83,0,0,1-10.19-.87,14.16,14.16,0,0,1-8.12-5.9,18.1,18.1,0,0,1-1.82-4.59c-.45-1.63-.83-3.31-1.4-4.88a13.15,13.15,0,0,0-1.42-2.92c-1.58-2.36-4.11-4.09-5.27-6.65-.06-.14-.13-.27-.19-.41A9.15,9.15,0,0,1,186.18,380.9Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "203.616px 394.792px",
        }}
        id="eluxy4hyzz4c"
        className="animable"
      />
      <path
        d="M209.81,403.48a11.37,11.37,0,0,1-9.47-3.69c-2.4-2.7-3.35-6.37-5.18-9.49-1.51-2.57-3.6-4.74-5.16-7.26a12.06,12.06,0,0,1-2-6.32l.57-1.06c4.73.84,9.91,1.14,14.46,2.68a92.78,92.78,0,0,0,5.34,13.94,35.21,35.21,0,0,0,5.51,8,2.73,2.73,0,0,1-.57,1.65A5.08,5.08,0,0,1,209.81,403.48Z"
        style={{
          fill: "rgb(255, 168, 167)",
          transformOrigin: "200.94px 389.594px",
        }}
        id="elrpdysm11pgq"
        className="animable"
      />
      <path
        d="M197,206.1q.39,6,.77,12c9.05,37.31,6.35,93.05,6.35,93.05l-.28,68a11.55,11.55,0,0,1-16.1-3.49c-1.54-16.71-9.31-43-7-63.49C177.61,296,175.28,273,175.28,273l-7.61,39.78-15.85,76.77c-7,3-13.21.94-15.23-1.78,1.62-42.35.93-62.71,7.46-82.29-2-52.53-2.64-76.27,10.16-94.32l-.16-6.82Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "170.544px 297.685px",
        }}
        id="elqkexjqi8tj"
        className="animable"
      />
      <g id="el0dclt6kytbur">
        <path
          d="M197,206.1q.39,6,.77,12c9.05,37.31,6.35,93.05,6.35,93.05l-.28,68a11.55,11.55,0,0,1-16.1-3.49c-1.54-16.71-9.31-43-7-63.49C177.61,296,175.28,273,175.28,273l-7.61,39.78-15.85,76.77c-7,3-13.21.94-15.23-1.78,1.62-42.35.93-62.71,7.46-82.29-2-52.53-2.64-76.27,10.16-94.32l-.16-6.82Z"
          style={{
            opacity: 0.3,
            transformOrigin: "170.544px 297.685px",
          }}
          className="animable"
        />
      </g>
      <g id="elwq5qf05m2yc">
        <path
          d="M177,264.13a29.82,29.82,0,0,0,13-5.81,13.05,13.05,0,0,1-9.49,8.47l-4,16.58-1.2-10.26Z"
          style={{
            opacity: 0.2,
            transformOrigin: "182.655px 270.845px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M181.2,164.35l4.59.34a5.86,5.86,0,0,1,4.42,2.56l10.6,15.7A12.71,12.71,0,0,1,200,198.3h0a7.42,7.42,0,0,0-1.7,5.77l3.62,35.83c-20.84,15.47-49.26,6.19-58.74,1.14.36-7.77,3.65-17.9,7.59-24.82a19.12,19.12,0,0,0,1.83-14.66l-3-10.77,4.34-25.64Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "173.093px 206.397px",
        }}
        id="elqqve4fjhig"
        className="animable"
      />
      <path
        d="M198.55,128c.36,1.93,1.88,7.74.36,11-.73,1.55-1.55,2.49-1,4.12s2.84,2.57,3.15,4.37c.26,1.45-.88,2.71-1.58,4a7.07,7.07,0,0,0,4.14,10.09,9.13,9.13,0,0,1-6.79-3.72,8.49,8.49,0,0,1-.92-7.41,9.39,9.39,0,0,0,.67-2.17c.12-1.48-.87-2.79-1.65-4.06a14,14,0,0,1-2.09-9.27C193.29,131.7,195.68,129.46,198.55,128Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "198.176px 144.79px",
        }}
        id="el93mt4ms71rq"
        className="animable"
      />
      <path
        d="M198.91,131.65a18.9,18.9,0,0,0-2.28-8.92c-4-7.75-12.18-13.39-20.89-13.52a18.73,18.73,0,0,0-11.49,3.52c-3.64,2.72-6,6.89-7.31,11.24a22.49,22.49,0,0,0-.5,12.79c1.21,4.15,4.25,7.9,8.35,9.28a19.44,19.44,0,0,0,8.29.49,70.66,70.66,0,0,0,17.38-4.12c2.1-.79,4.23-1.4,5.66-3.19A12.49,12.49,0,0,0,198.91,131.65Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "177.327px 127.984px",
        }}
        id="elfltdxopg6pm"
        className="animable"
      />
      <path
        d="M181.2,155v10.18s8.31,5.31,4.81,8.12c-6.6,5.31-20.76-8.18-20.76-8.18V148.14Z"
        style={{
          fill: "rgb(255, 168, 167)",
          transformOrigin: "176.06px 161.339px",
        }}
        id="el48q42wqsdov"
        className="animable"
      />
      <path
        d="M165.25,148.14c1.95,8.92,11.37,14.41,15.95,15.52V155Z"
        style={{
          fill: "rgb(242, 143, 143)",
          transformOrigin: "173.225px 155.9px",
        }}
        id="el99b2s35lpwt"
        className="animable"
      />
      <path
        d="M154.57,138.25a5,5,0,0,1,9.09,4.12,2.87,2.87,0,0,0,2.68-.27,4.56,4.56,0,0,0,1.72-2.28,27.36,27.36,0,0,0,.63-3.78c2.54-.4,4-4.58,4-5,.62.12,12.4.66,16.2-6.17,5.13.87,6.87,6.88,7,6.85a30.73,30.73,0,0,1,.49,6.42,49.21,49.21,0,0,1-2.33,13c-1,3.33-1.88,6.73-5.26,8.46a11.35,11.35,0,0,1-6.33,1c-7.93-.81-15.41-6.92-19-13.87a4.23,4.23,0,0,1-5.32,1,11.67,11.67,0,0,1-3.46-4.19A6.26,6.26,0,0,1,154.57,138.25Z"
        style={{
          fill: "rgb(255, 168, 167)",
          transformOrigin: "175.215px 142.783px",
        }}
        id="elyg6foz0h83b"
        className="animable"
      />
      <polygon
        points="185.43 138.84 189.53 146.43 184.24 147.2 185.43 138.84"
        style={{
          fill: "rgb(242, 143, 143)",
          transformOrigin: "186.885px 143.02px",
        }}
        id="elj7tcakpsudh"
        className="animable"
      />
      <path
        d="M179.08,149.55l3.91,2.1a2.61,2.61,0,0,1-3.06.74A2.35,2.35,0,0,1,179.08,149.55Z"
        style={{
          fill: "rgb(242, 143, 143)",
          transformOrigin: "180.946px 151.078px",
        }}
        id="el1jf5wix2eqr"
        className="animable"
      />
      <g id="elvo8nssfy11o">
        <path
          d="M179.08,149.55l3.91,2.1a2.61,2.61,0,0,1-3.06.74A2.35,2.35,0,0,1,179.08,149.55Z"
          style={{
            opacity: 0.3,
            transformOrigin: "180.946px 151.078px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M181.31,152.58a2.46,2.46,0,0,1-1.38-.19,2.11,2.11,0,0,1-1-2.14,2.4,2.4,0,0,1,2.36,2.33Z"
        style={{
          fill: "rgb(242, 143, 143)",
          transformOrigin: "180.107px 151.429px",
        }}
        id="elbaei73m6ege"
        className="animable"
      />
      <path
        d="M177.81,138.82a1.51,1.51,0,1,0,1.71-1.28A1.51,1.51,0,0,0,177.81,138.82Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "179.305px 139.035px",
        }}
        id="elqq3p57vn3eg"
        className="animable"
      />
      <path
        d="M190.42,140.12a1.51,1.51,0,1,0,1.71-1.29A1.51,1.51,0,0,0,190.42,140.12Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "191.916px 140.325px",
        }}
        id="el9fdd7cf74rv"
        className="animable"
      />
      <path
        d="M192.72,134.37l3,2.76a2.13,2.13,0,0,0-.32-2.43A2.56,2.56,0,0,0,192.72,134.37Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "194.341px 135.632px",
        }}
        id="el0br4s33ewafb"
        className="animable"
      />
      <path
        d="M180,132.38l-3.64,1.83a2.18,2.18,0,0,1,1-2.26A2.58,2.58,0,0,1,180,132.38Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "178.161px 132.986px",
        }}
        id="elc3l1q374ui"
        className="animable"
      />
      <path
        d="M168.53,135.22c.58,2.12,1.15,4.47.16,6.44-.6,1.19-1.72,2.07-2.32,3.26a7.37,7.37,0,0,0-.44,4.39,29.16,29.16,0,0,1,.57,4.44,8.23,8.23,0,0,1-8.75,8.06c1.7.24,3.76-1.31,4.64-2.79a8.23,8.23,0,0,0,.89-5c-.16-1.71-.63-3.38-.93-5.07a11.16,11.16,0,0,1,0-5.11c.49-1.71,1.64-3.35,1.29-5.09-.19-.95-.81-1.78-.91-2.74a2.7,2.7,0,0,1,2-2.81C166,132.92,168.19,134,168.53,135.22Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "163.489px 147.495px",
        }}
        id="el88r627f56m3"
        className="animable"
      />
      <g id="elf9pmq9l1os">
        <path
          d="M182.16,114.8a11.31,11.31,0,0,1-3.26,2.61,27.1,27.1,0,0,1-14.57,3.53,7.72,7.72,0,0,1-3-.57,4.48,4.48,0,0,1-2.43-3.39,17.19,17.19,0,0,1,5.43-6,19.6,19.6,0,0,1,11-3,23.43,23.43,0,0,1,6.78,1.13,4.27,4.27,0,0,1,1.06,2A4.62,4.62,0,0,1,182.16,114.8Z"
          style={{
            opacity: 0.3,
            transformOrigin: "171.064px 114.468px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M185.2,104.86c-.49-4.07-3.87-7.37-7.74-8.76a17.14,17.14,0,0,0-11.91.34,22.28,22.28,0,0,0-9.47,7.41c-1.76,2.33-3.17,5.17-2.83,8.08.38,3.38,3.17,6.17,6.42,7.21a18,18,0,0,0,10.17-.12c2.18-.64,9.15-3.44,10.9-5.3C183,111.3,185.59,108.1,185.2,104.86Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "169.219px 107.505px",
        }}
        id="elljavn5g516"
        className="animable"
      />
      <g id="el9wzaqy4mvok">
        <path
          d="M162.52,193.57a16.78,16.78,0,0,0,20.15,5.92,12.47,12.47,0,0,1-11.5,3.2C167.16,201.66,163.72,197.53,162.52,193.57Z"
          style={{
            opacity: 0.2,
            transformOrigin: "172.595px 198.291px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M190.08,238.86a.14.14,0,0,0,0,.07.13.13,0,0,0,0,.09l1.6,1.83h0a.42.42,0,0,1,0-.12c.06.28.48.27,1.17-.12l13.41-7.54a7.57,7.57,0,0,0,2.57-2.44l11.66-21.71c.19-.36.18-.57,0-.67h.05L219,206.42a.33.33,0,0,0-.21-.08h-.09l-.08,0h-.08l-.07,0-.07,0-.07,0-.07,0-.07,0-.08,0-.07,0-.07,0-.08,0,0,0-13.41,7.54,0,0-.07,0-.08,0-.07.05-.06,0-.06,0-.05,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0h0l0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0-.17.15,0,.05,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0a.1.1,0,0,1,0,0l0,.05-.05.06-.05.06,0,.07-.05.07,0,.07-.05.07-.05.07,0,.07,0,.06,0,.06-11.66,21.71h0l0,.06a.14.14,0,0,0,0,.06l0,.06a.14.14,0,0,0,0,.06s0,0,0,0a.18.18,0,0,0,0,.06.08.08,0,0,0,0,0h0v0A.13.13,0,0,0,190.08,238.86Zm30.1-30.65,0,0h0s.05,0,.09,0A.65.65,0,0,0,220.18,208.21Zm-15.6,9h0c.11-.11.24-.21.37-.32C204.82,217,204.69,217.07,204.58,217.18Zm-.64.67.17-.17-.17.17Zm-12.25,22.76h0a.21.21,0,0,1,0-.07.43.43,0,0,0,0,.11S191.69,240.62,191.69,240.61Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "205.14px 223.632px",
        }}
        id="el5gnxnyj5z1"
        className="animable"
      />
      <g id="elkvc0fpr4kq">
        <path
          d="M190.08,238.86a.14.14,0,0,0,0,.07.13.13,0,0,0,0,.09l1.6,1.83h0a.42.42,0,0,1,0-.12c.06.28.48.27,1.17-.12l13.41-7.54a7.57,7.57,0,0,0,2.57-2.44l11.66-21.71c.19-.36.18-.57,0-.67h.05L219,206.42a.33.33,0,0,0-.21-.08h-.09l-.08,0h-.08l-.07,0-.07,0-.07,0-.07,0-.07,0-.08,0-.07,0-.07,0-.08,0,0,0-13.41,7.54,0,0-.07,0-.08,0-.07.05-.06,0-.06,0-.05,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0h0l0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0-.17.15,0,.05,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0a.1.1,0,0,1,0,0l0,.05-.05.06-.05.06,0,.07-.05.07,0,.07-.05.07-.05.07,0,.07,0,.06,0,.06-11.66,21.71h0l0,.06a.14.14,0,0,0,0,.06l0,.06a.14.14,0,0,0,0,.06s0,0,0,0a.18.18,0,0,0,0,.06.08.08,0,0,0,0,0h0v0A.13.13,0,0,0,190.08,238.86Zm30.1-30.65,0,0h0s.05,0,.09,0A.65.65,0,0,0,220.18,208.21Zm-15.6,9h0c.11-.11.24-.21.37-.32C204.82,217,204.69,217.07,204.58,217.18Zm-.64.67.17-.17-.17.17Zm-12.25,22.76h0a.21.21,0,0,1,0-.07.43.43,0,0,0,0,.11S191.69,240.62,191.69,240.61Z"
          style={{
            opacity: 0.2,
            transformOrigin: "205.14px 223.632px",
          }}
          className="animable"
        />
      </g>
      <g id="elz1fkj3w2cxf">
        <path
          d="M190.09,238.8v0h0a.08.08,0,0,1,0,0,.18.18,0,0,1,0-.06s0,0,0,0a.14.14,0,0,1,0-.06l0-.06a.14.14,0,0,1,0-.06l0-.06h0l11.66-21.71,1.6,1.83L191.84,240.2a1.26,1.26,0,0,0-.15.4h0a.27.27,0,0,0,.05.24h0l-1.6-1.83a.13.13,0,0,1,0-.09.14.14,0,0,1,0-.07A.13.13,0,0,1,190.09,238.8Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.15,
            transformOrigin: "196.718px 228.815px",
          }}
          className="animable"
        />
      </g>
      <g id="el5y73ych2z1r">
        <path
          d="M201.93,216.6l0-.06,0-.07.05-.07.05-.07,0-.07.05-.07,0-.07.05-.06.05-.06,0-.05,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0-.05.17-.15,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0h0l0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,.05,0,.06,0,.06,0,.07-.05.08,0,.07,0,0,0,13.41-7.54,0,0,.08,0,.07,0,.07,0,.08,0,.07,0,.07,0,.07,0,.07,0,.07,0h.08l.08,0h.09a.33.33,0,0,1,.21.08l1.59,1.83q-.1-.12-.39-.06h0a2.57,2.57,0,0,0-.73.31L206.08,216a5.61,5.61,0,0,0-.76.51h0a8.67,8.67,0,0,0-.73.63h0a7.92,7.92,0,0,0-.64.67h0a4.93,4.93,0,0,0-.44.64l-1.6-1.83Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            transformOrigin: "210.38px 213.305px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M143.47,175.09c1.11-8,6.65-9.65,10.42-9.94,0,0,4.73,3.25,4.73,17.73l-.4,36.23h0l2.66,1.11a108.86,108.86,0,0,0,23.3,7,23.11,23.11,0,0,1,14.7-5l-2,3.69c3.75,2.4,12.22-1.93,12.22-1.93.62,5.28-2.8,10.76-7.34,13.54s-47.85-1.3-57-7.25C138.7,226.41,141.13,192.09,143.47,175.09Z"
        style={{
          fill: "rgb(255, 168, 167)",
          transformOrigin: "175.062px 201.781px",
        }}
        id="el75hc579rru9"
        className="animable"
      />
      <path
        d="M218.26,213.1a24.55,24.55,0,0,1,.49,4.33,15.79,15.79,0,0,1-4.17,9.26,4.81,4.81,0,0,1-1.8,1.27,2.12,2.12,0,0,1-2.09-.33,3.13,3.13,0,0,1-.72-2.18,11.8,11.8,0,0,1,.19-3.51A4.91,4.91,0,0,1,212,219a11.19,11.19,0,0,1,2.34-1,5.85,5.85,0,0,0,2.1-1.51Z"
        style={{
          fill: "rgb(255, 168, 167)",
          transformOrigin: "214.333px 220.6px",
        }}
        id="elr5n2td3pikl"
        className="animable"
      />
    </g>
    <g
      id="freepik--speech-bubble--inject-3"
      className="animable"
      style={{
        transformOrigin: "234.045px 136.026px",
      }}
    >
      <path
        d="M221.18,129.09l27.28-15.66c2.39-1.37,8,.49,8,4.33a1.78,1.78,0,0,0-2.66-1.54l-27.29,15.66a5.24,5.24,0,0,0-2.07,2.23l-5.3-3A5.26,5.26,0,0,1,221.18,129.09Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "237.8px 123.546px",
        }}
        id="el9vwmkvxm9o"
        className="animable"
      />
      <path
        d="M224.49,134.11a5.29,5.29,0,0,0-.6,2.39v20.26a1.77,1.77,0,0,0,2.65,1.54,5.26,5.26,0,0,1-8-4.33V133.71a5.3,5.3,0,0,1,.69-2.61Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "222.54px 145.086px",
        }}
        id="elswb5b3b75el"
        className="animable"
      />
      <g id="elvsrzu3kg49q">
        <path
          d="M221.18,129.09l27.28-15.66c2.39-1.37,8,.49,8,4.33a1.78,1.78,0,0,0-2.66-1.54l-27.29,15.66a5.24,5.24,0,0,0-2.07,2.23l-5.3-3A5.26,5.26,0,0,1,221.18,129.09Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.4,
            transformOrigin: "237.8px 123.546px",
          }}
          className="animable"
        />
      </g>
      <g id="elci85lor2g8j">
        <path
          d="M224.49,134.11a5.29,5.29,0,0,0-.6,2.39v20.26a1.77,1.77,0,0,0,2.65,1.54,5.26,5.26,0,0,1-8-4.33V133.71a5.3,5.3,0,0,1,.69-2.61Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            transformOrigin: "222.54px 145.086px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M253.85,116.22l-27.29,15.66a5.33,5.33,0,0,0-2.67,4.62v20.26a1.77,1.77,0,0,0,2.65,1.54l27.29-15.59a5.34,5.34,0,0,0,2.68-4.62V117.76A1.78,1.78,0,0,0,253.85,116.22Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "240.2px 137.261px",
        }}
        id="ele69xghpd60n"
        className="animable"
      />
      <path
        d="M217.32,146.83l6.56,1.23v-8.41l-6.79,6.41A.45.45,0,0,0,217.32,146.83Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "220.415px 143.855px",
        }}
        id="elb4cba4l67fk"
        className="animable"
      />
      <path
        d="M223.88,139.65l-5.38-3.08-6.78,6.48a.45.45,0,0,0,.09.72l5.36,3Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "217.73px 141.67px",
        }}
        id="elcu1sh92bg5t"
        className="animable"
      />
      <g id="elvy9arb0pnop">
        <path
          d="M223.88,139.65l-5.38-3.08-6.78,6.48a.45.45,0,0,0,.09.72l5.36,3Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            transformOrigin: "217.73px 141.67px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M249.7,122.77c.67-.39,1.14.38.53,1.35a156.7,156.7,0,0,0-11.48,21.72,4.3,4.3,0,0,1-1.7,2.06,1.34,1.34,0,0,1-1.66,0,33.49,33.49,0,0,0-5.28-4.1c-.52-.33-.09-1.54.58-1.93a.82.82,0,0,1,.26-.1,18,18,0,0,1,6-.17,89.51,89.51,0,0,1,12.33-18.48A1.54,1.54,0,0,1,249.7,122.77Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "240.196px 135.427px",
        }}
        id="elehbzf5j7v3"
        className="animable"
      />
    </g>
    <defs>
      <filter id="active" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter id="hover" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#ff0000" floodOpacity={0.5} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
        <feColorMatrix
          type="matrix"
          values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
        />
      </filter>
    </defs>
  </svg>
);

export default VerifyEmailSvg;
