interface GeovetraHeroSvgProps {
  className?: string | undefined;
}

const GeovetraHeroSvg: React.FC<GeovetraHeroSvgProps> = ({
  className,
  ...props
}) => (
  <svg
    id="freepik_stories-logistics"
    viewBox="0 0 500 500"
    className={`animated ${className}`}
    {...props}
  >
    <style>
      {
        "\n\t\tsvg#freepik_stories-logistics:not(.animated) .animable {opacity: 0;}svg#freepik_stories-logistics.animated #freepik--background-complete--inject-11 {animation: 6s Infinite  linear floating;animation-delay: 0s;}svg#freepik_stories-logistics.animated #freepik--Truck--inject-11 {animation: 3s Infinite  linear wind;animation-delay: 0s;}svg#freepik_stories-logistics.animated #freepik--Character--inject-11 {animation: 1.5s Infinite  linear floating;animation-delay: 0s;}            @keyframes floating {                0% {                    opacity: 1;                    transform: translateY(0px);                }                50% {                    transform: translateY(-10px);                }                100% {                    opacity: 1;                    transform: translateY(0px);                }            }                    @keyframes wind {                0% {                    transform: rotate( 0deg );                }                25% {                    transform: rotate( 1deg );                }                75% {                    transform: rotate( -1deg );                }            }\n\t"
      }
    </style>
    <g
      id="freepik--background-complete--inject-11"
      className="animable"
      style={{
        transformOrigin: "250px 224.695px",
      }}
    >
      <rect
        y={382.4}
        width={500}
        height={0.25}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "250px 382.525px",
        }}
        id="eloesgd7xhhp"
        className="animable"
      />
      <rect
        x={150.04}
        y={388.78}
        width={23.18}
        height={0.25}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "161.63px 388.905px",
        }}
        id="elyq2ii2d77h"
        className="animable"
      />
      <rect
        x={402.1}
        y={394.14}
        width={56.67}
        height={0.25}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "430.435px 394.265px",
        }}
        id="elrbs6rh0tel"
        className="animable"
      />
      <rect
        x={42.72}
        y={394.14}
        width={56.67}
        height={0.25}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "71.055px 394.265px",
        }}
        id="el5zj4h9rr2lr"
        className="animable"
      />
      <path
        d="M237,337.8H43.91a5.71,5.71,0,0,1-5.7-5.71V60.66A5.71,5.71,0,0,1,43.91,55H237a5.71,5.71,0,0,1,5.71,5.71h0V332.09A5.71,5.71,0,0,1,237,337.8ZM43.91,55.2a5.46,5.46,0,0,0-5.45,5.46V332.09a5.46,5.46,0,0,0,5.45,5.46H237a5.48,5.48,0,0,0,5.46-5.46V60.66A5.48,5.48,0,0,0,237,55.2Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "140.46px 196.4px",
        }}
        id="el1rnv8icezyl"
        className="animable"
      />
      <path
        d="M453.31,337.8H260.21a5.73,5.73,0,0,1-5.71-5.71V60.66A5.72,5.72,0,0,1,260.21,55h193.1A5.71,5.71,0,0,1,459,60.66V332.09A5.7,5.7,0,0,1,453.31,337.8ZM260.21,55.2a5.48,5.48,0,0,0-5.46,5.46V332.09a5.48,5.48,0,0,0,5.46,5.46h193.1a5.48,5.48,0,0,0,5.46-5.46V60.66a5.48,5.48,0,0,0-5.46-5.46Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "356.75px 196.4px",
        }}
        id="eljy79jt4hcdo"
        className="animable"
      />
      <path
        d="M98.7,130.41H182a6.37,6.37,0,0,1,6.38,6.38V382.17h-96V136.78A6.38,6.38,0,0,1,98.7,130.41Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "140.38px 256.29px",
        }}
        id="el37f3moud27i"
        className="animable"
      />
      <rect
        x={100.3}
        y={145.28}
        width={12.38}
        height={27.56}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "106.49px 159.06px",
        }}
        id="elzld5358qwb"
        className="animable"
      />
      <rect
        x={168}
        y={145.28}
        width={12.38}
        height={27.56}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "174.19px 159.06px",
        }}
        id="el03xi4nfvm363"
        className="animable"
      />
      <rect
        x={122.86}
        y={145.28}
        width={12.38}
        height={27.56}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "129.05px 159.06px",
        }}
        id="elrf4pyt5syjb"
        className="animable"
      />
      <rect
        x={145.43}
        y={145.28}
        width={12.38}
        height={27.56}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "151.62px 159.06px",
        }}
        id="elfwegu3iz6pw"
        className="animable"
      />
      <rect
        x={100.3}
        y={188.79}
        width={12.38}
        height={27.56}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "106.49px 202.57px",
        }}
        id="el5iir3lizjyx"
        className="animable"
      />
      <rect
        x={168}
        y={188.79}
        width={12.38}
        height={27.56}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "174.19px 202.57px",
        }}
        id="elsk40xy8avcb"
        className="animable"
      />
      <rect
        x={122.86}
        y={188.79}
        width={12.38}
        height={27.56}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "129.05px 202.57px",
        }}
        id="ele1807kmcv7r"
        className="animable"
      />
      <rect
        x={145.43}
        y={188.79}
        width={12.38}
        height={27.56}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "151.62px 202.57px",
        }}
        id="eljflk917n1bi"
        className="animable"
      />
      <rect
        x={100.3}
        y={232.29}
        width={12.38}
        height={27.56}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "106.49px 246.07px",
        }}
        id="elfto70xecnqv"
        className="animable"
      />
      <rect
        x={168}
        y={232.29}
        width={12.38}
        height={27.56}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "174.19px 246.07px",
        }}
        id="el9h8zk55c4c6"
        className="animable"
      />
      <rect
        x={122.86}
        y={232.29}
        width={12.38}
        height={27.56}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "129.05px 246.07px",
        }}
        id="elnxcldo1brvb"
        className="animable"
      />
      <rect
        x={145.43}
        y={232.29}
        width={12.38}
        height={27.56}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "151.62px 246.07px",
        }}
        id="eljews3f2w6e"
        className="animable"
      />
      <rect
        x={100.3}
        y={275.8}
        width={12.38}
        height={27.56}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "106.49px 289.58px",
        }}
        id="elv0m1dpztywl"
        className="animable"
      />
      <rect
        x={168}
        y={275.8}
        width={12.38}
        height={27.56}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "174.19px 289.58px",
        }}
        id="el5f37gdghkt3"
        className="animable"
      />
      <rect
        x={122.86}
        y={275.8}
        width={12.38}
        height={27.56}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "129.05px 289.58px",
        }}
        id="eltx14ypigtfp"
        className="animable"
      />
      <rect
        x={145.43}
        y={275.8}
        width={12.38}
        height={27.56}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "151.62px 289.58px",
        }}
        id="elih1l7agaool"
        className="animable"
      />
      <rect
        x={100.3}
        y={319.3}
        width={12.38}
        height={27.56}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "106.49px 333.08px",
        }}
        id="elk9w8ny33wx9"
        className="animable"
      />
      <rect
        x={168}
        y={319.3}
        width={12.38}
        height={27.56}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "174.19px 333.08px",
        }}
        id="el3k2876sg5u7"
        className="animable"
      />
      <rect
        x={122.86}
        y={319.3}
        width={12.38}
        height={27.56}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "129.05px 333.08px",
        }}
        id="elwkz3uvmpyvf"
        className="animable"
      />
      <rect
        x={145.43}
        y={319.3}
        width={12.38}
        height={27.56}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "151.62px 333.08px",
        }}
        id="elfaf8a74fkzf"
        className="animable"
      />
      <path
        d="M393.94,153.28h59.64a5.2,5.2,0,0,1,5.19,5.19v223.7h-70V158.48A5.2,5.2,0,0,1,393.94,153.28Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "423.77px 267.725px",
        }}
        id="el7lkyumr6ebw"
        className="animable"
      />
      <rect
        x={397.67}
        y={166.81}
        width={11.25}
        height={25.05}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "403.295px 179.335px",
        }}
        id="eljq0w14gjd0e"
        className="animable"
      />
      <rect
        x={418.19}
        y={166.81}
        width={11.25}
        height={25.05}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "423.815px 179.335px",
        }}
        id="elxl4g2quur8e"
        className="animable"
      />
      <rect
        x={438.7}
        y={166.81}
        width={11.25}
        height={25.05}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "444.325px 179.335px",
        }}
        id="elcm7gknpdhh"
        className="animable"
      />
      <rect
        x={397.67}
        y={206.36}
        width={11.25}
        height={25.05}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "403.295px 218.885px",
        }}
        id="elmd9ci39xds"
        className="animable"
      />
      <rect
        x={418.19}
        y={206.36}
        width={11.25}
        height={25.05}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "423.815px 218.885px",
        }}
        id="elyjdjpv5diue"
        className="animable"
      />
      <rect
        x={438.7}
        y={206.36}
        width={11.25}
        height={25.05}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "444.325px 218.885px",
        }}
        id="ely9h6xfsn39j"
        className="animable"
      />
      <rect
        x={397.67}
        y={245.92}
        width={11.25}
        height={25.05}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "403.295px 258.445px",
        }}
        id="elpwk6p5ui6c"
        className="animable"
      />
      <rect
        x={418.19}
        y={245.92}
        width={11.25}
        height={25.05}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "423.815px 258.445px",
        }}
        id="el5hfa9lj5g75"
        className="animable"
      />
      <rect
        x={438.7}
        y={245.92}
        width={11.25}
        height={25.05}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "444.325px 258.445px",
        }}
        id="el25m2rh5yk9v"
        className="animable"
      />
      <rect
        x={397.67}
        y={285.46}
        width={11.25}
        height={25.05}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "403.295px 297.985px",
        }}
        id="eluf9tkw7jgnd"
        className="animable"
      />
      <rect
        x={418.19}
        y={285.46}
        width={11.25}
        height={25.05}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "423.815px 297.985px",
        }}
        id="eloe8h8jrurjm"
        className="animable"
      />
      <rect
        x={438.7}
        y={285.46}
        width={11.25}
        height={25.05}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "444.325px 297.985px",
        }}
        id="eltt789azdh7k"
        className="animable"
      />
      <rect
        x={397.67}
        y={325.02}
        width={11.25}
        height={25.05}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "403.295px 337.545px",
        }}
        id="elcj8c7iyzi3"
        className="animable"
      />
      <rect
        x={418.19}
        y={325.02}
        width={11.25}
        height={25.05}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "423.815px 337.545px",
        }}
        id="elpkwryxlapys"
        className="animable"
      />
      <rect
        x={438.7}
        y={325.02}
        width={11.25}
        height={25.05}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "444.325px 337.545px",
        }}
        id="elo8ro01dj7br"
        className="animable"
      />
      <path
        d="M257.31,89.26h96.88a7.42,7.42,0,0,1,7.42,7.42V382.17H249.9V96.68A7.42,7.42,0,0,1,257.31,89.26Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "305.755px 235.715px",
        }}
        id="el5zlfzcyofrf"
        className="animable"
      />
      <rect
        x={259.17}
        y={106.57}
        width={14.4}
        height={32.06}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "266.37px 122.6px",
        }}
        id="el7a0rqueyv4"
        className="animable"
      />
      <rect
        x={337.93}
        y={106.57}
        width={14.4}
        height={32.06}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "345.13px 122.6px",
        }}
        id="el32cy4iwvl3v"
        className="animable"
      />
      <rect
        x={285.42}
        y={106.57}
        width={14.4}
        height={32.06}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "292.62px 122.6px",
        }}
        id="el659unhwami"
        className="animable"
      />
      <rect
        x={311.68}
        y={106.57}
        width={14.4}
        height={32.06}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "318.88px 122.6px",
        }}
        id="el9ddl1uvxnb"
        className="animable"
      />
      <rect
        x={259.17}
        y={157.19}
        width={14.4}
        height={32.06}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "266.37px 173.22px",
        }}
        id="elpgnua6xgsgk"
        className="animable"
      />
      <rect
        x={337.93}
        y={157.19}
        width={14.4}
        height={32.06}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "345.13px 173.22px",
        }}
        id="elkzt29fzccy"
        className="animable"
      />
      <rect
        x={285.42}
        y={157.19}
        width={14.4}
        height={32.06}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "292.62px 173.22px",
        }}
        id="elljghgc1wqsl"
        className="animable"
      />
      <rect
        x={311.68}
        y={157.19}
        width={14.4}
        height={32.06}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "318.88px 173.22px",
        }}
        id="elu51vf6obi2i"
        className="animable"
      />
      <rect
        x={259.17}
        y={207.81}
        width={14.4}
        height={32.06}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "266.37px 223.84px",
        }}
        id="elrcfuhspy2v"
        className="animable"
      />
      <rect
        x={337.93}
        y={207.81}
        width={14.4}
        height={32.06}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "345.13px 223.84px",
        }}
        id="eliafbctzs3d"
        className="animable"
      />
      <rect
        x={285.42}
        y={207.81}
        width={14.4}
        height={32.06}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "292.62px 223.84px",
        }}
        id="elrlw23n705el"
        className="animable"
      />
      <rect
        x={311.68}
        y={207.81}
        width={14.4}
        height={32.06}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "318.88px 223.84px",
        }}
        id="elpotatc7a4ig"
        className="animable"
      />
      <rect
        x={259.17}
        y={258.42}
        width={14.4}
        height={32.06}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "266.37px 274.45px",
        }}
        id="elhgevzc455zr"
        className="animable"
      />
      <rect
        x={337.93}
        y={258.42}
        width={14.4}
        height={32.06}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "345.13px 274.45px",
        }}
        id="elff8wr22m6l"
        className="animable"
      />
      <rect
        x={285.42}
        y={258.42}
        width={14.4}
        height={32.06}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "292.62px 274.45px",
        }}
        id="el2zu88hx35n6"
        className="animable"
      />
      <rect
        x={311.68}
        y={258.42}
        width={14.4}
        height={32.06}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "318.88px 274.45px",
        }}
        id="el6gz07kgoc7o"
        className="animable"
      />
      <rect
        x={259.17}
        y={309.03}
        width={14.4}
        height={32.06}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "266.37px 325.06px",
        }}
        id="el46qqz48z5st"
        className="animable"
      />
      <rect
        x={337.93}
        y={309.03}
        width={14.4}
        height={32.06}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "345.13px 325.06px",
        }}
        id="el6nd03px34"
        className="animable"
      />
      <rect
        x={285.42}
        y={309.03}
        width={14.4}
        height={32.06}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "292.62px 325.06px",
        }}
        id="ellgrpb8gv79l"
        className="animable"
      />
      <rect
        x={311.68}
        y={309.03}
        width={14.4}
        height={32.06}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "318.88px 325.06px",
        }}
        id="elcc0ae74w2kd"
        className="animable"
      />
      <rect
        x={188.35}
        y={194.17}
        width={61.54}
        height={188.01}
        style={{
          fill: "rgb(230, 230, 230)",
          transformOrigin: "219.12px 288.175px",
        }}
        id="el5c7py5pzjqb"
        className="animable"
      />
      <rect
        x={361.62}
        y={214.3}
        width={27.19}
        height={167.87}
        style={{
          fill: "rgb(230, 230, 230)",
          transformOrigin: "375.215px 298.235px",
        }}
        id="elkvicol7hu4"
        className="animable"
      />
      <polygon
        points="92.32 382.17 38.46 382.17 38.46 303.68 92.32 285.46 92.32 382.17"
        style={{
          fill: "rgb(230, 230, 230)",
          transformOrigin: "65.39px 333.815px",
        }}
        id="elbo1d929w3e7"
        className="animable"
      />
    </g>
    <g
      id="freepik--Shadow--inject-11"
      className="animable"
      style={{
        transformOrigin: "250px 411.67px",
      }}
    >
      <g
        id="freepik--Shadow--inject-62--inject-11"
        className="animable"
        style={{
          transformOrigin: "250px 411.67px",
        }}
      >
        <ellipse
          id="freepik--path--inject-62--inject-11"
          cx={250}
          cy={411.67}
          rx={193.89}
          ry={15.89}
          style={{
            fill: "rgb(245, 245, 245)",
            transformOrigin: "250px 411.67px",
          }}
          className="animable"
        />
      </g>
    </g>
    <g
      id="freepik--Truck--inject-11"
      className="animable"
      style={{
        transformOrigin: "237.13px 285.465px",
      }}
    >
      <path
        d="M297.68,348.92h-17c-8.85,0-16,12.83-16,28.66s7.17,28.65,16,28.65h17c8.85,0,16-12.83,16-28.65S306.53,348.92,297.68,348.92Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "289.18px 377.575px",
        }}
        id="elx90q4z6edcf"
        className="animable"
      />
      <g
        style={{
          clipPath: "url(&quot",
          transformOrigin: "309.18px 377.575px",
        }}
        id="elfaw4ticd5jq"
        className="animable"
      >
        <g id="elnhe9rpu7qu">
          <path
            d="M317.68,348.92h-17c-8.85,0-16,12.83-16,28.66s7.17,28.65,16,28.65h17c8.85,0,16-12.83,16-28.65S326.53,348.92,317.68,348.92Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.05,
              transformOrigin: "309.18px 377.575px",
            }}
            className="animable"
          />
        </g>
      </g>
      <polygon
        points="435.39 355.06 137.7 354.85 137.7 164.7 435.39 164.7 435.39 355.06"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "286.545px 259.88px",
        }}
        id="elh2brpw9kpq"
        className="animable"
      />
      <g id="elvrwtc4agzd">
        <rect
          x={233.63}
          y={164.7}
          width={201.76}
          height={190.35}
          style={{
            fill: "rgb(38, 50, 56)",
            opacity: 0.3,
            isolation: "isolate",
            transformOrigin: "334.51px 259.875px",
          }}
          className="animable"
        />
      </g>
      <g id="elg22o657z25m">
        <rect
          x={137.7}
          y={164.7}
          width={95.93}
          height={194.11}
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            isolation: "isolate",
            transformOrigin: "185.665px 261.755px",
          }}
          className="animable"
        />
      </g>
      <g id="elbm8chq4tl7v">
        <line
          x1={138.42}
          y1={173.54}
          x2={435.39}
          y2={173.54}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 173.54px",
          }}
          className="animable"
        />
      </g>
      <g id="eldwr27qf7h5">
        <line
          x1={138.42}
          y1={181.77}
          x2={435.39}
          y2={181.77}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 181.77px",
          }}
          className="animable"
        />
      </g>
      <g id="elystynm8t78">
        <line
          x1={138.42}
          y1={189.99}
          x2={435.39}
          y2={189.99}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 189.99px",
          }}
          className="animable"
        />
      </g>
      <g id="el1std297wmvk">
        <line
          x1={138.42}
          y1={198.21}
          x2={435.39}
          y2={198.21}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 198.21px",
          }}
          className="animable"
        />
      </g>
      <g id="elpey59nb1a2b">
        <line
          x1={138.42}
          y1={206.44}
          x2={435.39}
          y2={206.44}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 206.44px",
          }}
          className="animable"
        />
      </g>
      <g id="elsglvscmo3ie">
        <line
          x1={138.42}
          y1={214.66}
          x2={435.39}
          y2={214.66}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 214.66px",
          }}
          className="animable"
        />
      </g>
      <g id="el2jt8otwegr6">
        <line
          x1={138.42}
          y1={222.88}
          x2={435.39}
          y2={222.88}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 222.88px",
          }}
          className="animable"
        />
      </g>
      <g id="elsre82z5be5b">
        <line
          x1={138.42}
          y1={231.1}
          x2={435.39}
          y2={231.1}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 231.1px",
          }}
          className="animable"
        />
      </g>
      <g id="elt7m3dxkmqff">
        <line
          x1={138.42}
          y1={239.33}
          x2={435.39}
          y2={239.33}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 239.33px",
          }}
          className="animable"
        />
      </g>
      <g id="elq80c12ro8qg">
        <line
          x1={138.42}
          y1={247.55}
          x2={435.39}
          y2={247.55}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 247.55px",
          }}
          className="animable"
        />
      </g>
      <g id="elt4x0dlk9ghn">
        <line
          x1={138.42}
          y1={255.77}
          x2={435.39}
          y2={255.77}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 255.77px",
          }}
          className="animable"
        />
      </g>
      <g id="elbqtg9x484j6">
        <line
          x1={138.42}
          y1={264}
          x2={435.39}
          y2={264}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 264px",
          }}
          className="animable"
        />
      </g>
      <g id="elj2erke2vumr">
        <line
          x1={138.42}
          y1={272.22}
          x2={435.39}
          y2={272.22}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 272.22px",
          }}
          className="animable"
        />
      </g>
      <g id="ele6hrqoicdc9">
        <line
          x1={138.42}
          y1={280.44}
          x2={435.39}
          y2={280.44}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 280.44px",
          }}
          className="animable"
        />
      </g>
      <g id="el268zd3tshna">
        <line
          x1={138.42}
          y1={288.66}
          x2={435.39}
          y2={288.66}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 288.66px",
          }}
          className="animable"
        />
      </g>
      <g id="el4vr45s2t19w">
        <line
          x1={138.42}
          y1={296.89}
          x2={435.39}
          y2={296.89}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 296.89px",
          }}
          className="animable"
        />
      </g>
      <g id="elgqrzd8gf1qn">
        <line
          x1={138.42}
          y1={305.11}
          x2={435.39}
          y2={305.11}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 305.11px",
          }}
          className="animable"
        />
      </g>
      <g id="elkpd9ojc8aw">
        <line
          x1={138.42}
          y1={313.33}
          x2={435.39}
          y2={313.33}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 313.33px",
          }}
          className="animable"
        />
      </g>
      <g id="elw965pudhtoh">
        <line
          x1={138.42}
          y1={321.56}
          x2={435.39}
          y2={321.56}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 321.56px",
          }}
          className="animable"
        />
      </g>
      <g id="elgfu5ia1yxp">
        <line
          x1={138.42}
          y1={329.78}
          x2={435.39}
          y2={329.78}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 329.78px",
          }}
          className="animable"
        />
      </g>
      <g id="el1dbrtkmlxth">
        <line
          x1={138.42}
          y1={338}
          x2={435.39}
          y2={338}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 338px",
          }}
          className="animable"
        />
      </g>
      <g id="elod3mn8v6th">
        <line
          x1={138.42}
          y1={346.23}
          x2={435.39}
          y2={346.23}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
            opacity: 0.1,
            transformOrigin: "286.905px 346.23px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M83.07,352.66H67.34c-8.2,0-14.84,11.88-14.84,26.54s6.64,26.53,14.84,26.53H83.07c8.2,0,14.84-11.88,14.84-26.53S91.27,352.66,83.07,352.66Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "75.205px 379.195px",
        }}
        id="elw8j98kpjntq"
        className="animable"
      />
      <g id="elhq6zibjkj1o">
        <path
          d="M103.08,352.66H87.34c-8.2,0-14.84,11.88-14.84,26.54s6.64,26.53,14.84,26.53h15.74c8.19,0,14.84-11.88,14.84-26.53S111.27,352.66,103.08,352.66Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.05,
            transformOrigin: "95.21px 379.195px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M74.32,300.83H66.88c-1.46.05-2.66-1.51-2.69-3.49a3.22,3.22,0,0,1,0-.62l2-22.47c.25-2.31,1.68-4,3.4-4.11H77.1c1.45,0,2.65,1.53,2.68,3.51q0,.3,0,.6l-2,22.47C77.48,299,76,300.77,74.32,300.83Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "71.9775px 285.486px",
        }}
        id="elacecpkk4n6d"
        className="animable"
      />
      <path
        d="M81.76,226.61,67.41,304,223.64,365.2l-1.09-152.52-34.36-1H94.07C88.41,211.63,83.42,217.71,81.76,226.61Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "145.525px 288.44px",
        }}
        id="els84wbb9rtzd"
        className="animable"
      />
      <path
        d="M198,239.06c-5.83-.26-11.06,4.52-12.77,11.65l-11.82,49.52H150.66l-1.24,65.33h74.83"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "186.835px 302.305px",
        }}
        id="el3gj7ydegjym"
        className="animable"
      />
      <path
        d="M84,296.07c-2.29,0-4.14-2.94-4.14-6.56a9.74,9.74,0,0,1,.18-1.88l8.59-45.44c.72-3.77,2.92-6.35,5.41-6.34h82.18c.89,0,1.62,1.14,1.62,2.56a3.74,3.74,0,0,1-.07.75c-1.8,9.36-7.65,40.37-9.89,52.23-.52,2.78-2.14,4.68-4,4.68Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "128.85px 265.96px",
        }}
        id="elkapac7590m9"
        className="animable"
      />
      <path
        d="M159.62,316.21a17.21,17.21,0,0,1-.17,2.38l-.43,2.93-.25,1.73-1.84,12.64-3,20.55-.83,5.71H36.81l0-5.71.08-20.55L37,323.6v-5c-1-6.6,2.19-13,7.05-14.33a6.52,6.52,0,0,1,1.75-.23H150.66C155.61,304,159.62,309.48,159.62,316.21Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "98.215px 333.095px",
        }}
        id="eltnxdafbueu"
        className="animable"
      />
      <path
        d="M218.73,242v48.71c0,2.95-1.51,5.35-3.38,5.36H186.93c-1.87,0-3.38-2.4-3.38-5.36a8.57,8.57,0,0,1,.12-1.44l8.76-49.8c.42-2.39,1.82-4,3.38-3.91l19.66,1.08C217.29,236.75,218.73,239.12,218.73,242Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "201.14px 265.813px",
        }}
        id="el0li1vu8tvji"
        className="animable"
      />
      <g id="elf4y2b7igk1q">
        <polygon
          points="131.94 235.85 116.24 235.85 98.21 296.07 113.92 296.07 131.94 235.85"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.1,
            isolation: "isolate",
            transformOrigin: "115.075px 265.96px",
          }}
          className="animable"
        />
      </g>
      <g id="el24wyaz4pp5r">
        <polygon
          points="144.16 235.85 136.66 235.85 118.64 296.07 126.13 296.07 144.16 235.85"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.1,
            isolation: "isolate",
            transformOrigin: "131.4px 265.96px",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="189.75 335.89 189.75 356.44 36.83 356.44 36.91 335.89 189.75 335.89"
        id="elief9v44qggm"
        className="animable"
        style={{
          transformOrigin: "113.29px 346.165px",
        }}
      />
      <polygon
        points="168.45 335.89 154.31 335.89 128.88 350.83 128.88 356.44 189.75 356.44 189.75 335.89 168.45 335.89"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "159.315px 346.165px",
        }}
        id="elceq8ka078l"
        className="animable"
      />
      <polygon
        points="63.19 350.83 63.19 356.44 36.83 356.44 36.91 335.89 37.77 335.89 63.19 350.83"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "50.01px 346.165px",
        }}
        id="el2mrzp1ge4ke"
        className="animable"
      />
      <g id="el4ozdagux749">
        <path
          d="M223.71,365.56l-1.16-152.88-21-1c-5.24-.26-11.29,5.83-12.82,12.89L173.51,304h-5.93c-6.66,0-11.22,4.85-11.21,10l.09,51.58h67.25"
          style={{
            fill: "rgb(38, 50, 56)",
            opacity: 0.3,
            isolation: "isolate",
            transformOrigin: "190.04px 288.626px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M159.23,322.24a1.35,1.35,0,0,1-.46,1,1.31,1.31,0,0,1-.9.35H37v-2.72h120.9a1.36,1.36,0,0,1,1.15.64A1.38,1.38,0,0,1,159.23,322.24Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "98.1154px 322.23px",
        }}
        id="elxc39dp7frgk"
        className="animable"
      />
      <polygon
        points="58.32 320.88 58.32 323.6 36.96 323.6 36.97 320.88 58.32 320.88"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "47.64px 322.24px",
        }}
        id="el1xmsxpormky"
        className="animable"
      />
      <path
        d="M159.23,322.24a1.35,1.35,0,0,1-.46,1,1.31,1.31,0,0,1-.9.35H128.55v-2.72h29.32a1.36,1.36,0,0,1,1.15.64A1.38,1.38,0,0,1,159.23,322.24Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "143.89px 322.23px",
        }}
        id="el1t09090e0qf"
        className="animable"
      />
      <path
        d="M440.73,360v.54a5,5,0,0,1-5,5H38.53a5,5,0,0,1-5-5V360a5,5,0,0,1,5-5h397.2A5,5,0,0,1,440.73,360Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "237.13px 360.27px",
        }}
        id="eljhyks912ee"
        className="animable"
      />
      <rect
        x={67.41}
        y={359.11}
        width={59.2}
        height={2.89}
        id="elqcfu7jepwm"
        className="animable"
        style={{
          transformOrigin: "97.01px 360.555px",
        }}
      />
      <path
        d="M51.66,360.55A1.65,1.65,0,1,1,50,358.9,1.65,1.65,0,0,1,51.66,360.55Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "50.01px 360.55px",
        }}
        id="el9k4ppqqoorh"
        className="animable"
      />
      <path
        d="M140.75,360.55a1.65,1.65,0,1,1-1.65-1.65A1.65,1.65,0,0,1,140.75,360.55Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "139.1px 360.55px",
        }}
        id="elj3apqpmj07f"
        className="animable"
      />
      <path
        d="M188.59,376.93c0-15.91,7.21-28.81,16.11-28.81H187.61c-8.89,0-16.1,12.9-16.1,28.81s7.21,28.8,16.1,28.8H204.7C195.8,405.76,188.59,392.87,188.59,376.93Z"
        id="eljahotg3iqw"
        className="animable"
        style={{
          transformOrigin: "188.105px 376.925px",
        }}
      />
      <g id="elciu59qjm1rs">
        <path
          d="M188.59,376.93c0-15.91,7.21-28.81,16.11-28.81H187.61c-8.89,0-16.1,12.9-16.1,28.81s7.21,28.8,16.1,28.8H204.7C195.8,405.76,188.59,392.87,188.59,376.93Z"
          style={{
            fill: "rgb(38, 50, 56)",
            opacity: 0.7,
            isolation: "isolate",
            transformOrigin: "188.105px 376.925px",
          }}
          className="animable"
        />
      </g>
      <ellipse
        cx={204.7}
        cy={376.96}
        rx={16.11}
        ry={28.81}
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "204.7px 376.96px",
        }}
        id="elfrt6pkaqrfa"
        className="animable"
      />
      <ellipse
        cx={205.99}
        cy={376.96}
        rx={11.42}
        ry={22.46}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "205.99px 376.96px",
        }}
        id="el6s0ybirpft5"
        className="animable"
      />
      <ellipse
        cx={204.95}
        cy={376.96}
        rx={10.93}
        ry={19.44}
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "204.95px 376.96px",
        }}
        id="el4hgl34dkdl7"
        className="animable"
      />
      <ellipse
        cx={202.55}
        cy={376.96}
        rx={7.98}
        ry={14.19}
        id="elagcw0g8dgrw"
        className="animable"
        style={{
          transformOrigin: "202.55px 376.96px",
        }}
      />
      <g id="elx60uhiy98la">
        <path
          d="M440.73,360v.54a5,5,0,0,1-5,5H233.63V355h202.1A5,5,0,0,1,440.73,360Z"
          style={{
            opacity: 0.5,
            transformOrigin: "337.18px 360.27px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M377.92,376.93c0-15.91,7.21-28.81,16.1-28.81H376.94c-8.9,0-16.11,12.9-16.11,28.81s7.21,28.8,16.11,28.8H394C385.13,405.76,377.92,392.87,377.92,376.93Z"
        id="eleqarpkxyl7p"
        className="animable"
        style={{
          transformOrigin: "377.425px 376.925px",
        }}
      />
      <g id="elc0g1ooqnufs">
        <path
          d="M377.92,376.93c0-15.91,7.21-28.81,16.1-28.81H376.94c-8.9,0-16.11,12.9-16.11,28.81s7.21,28.8,16.11,28.8H394C385.13,405.76,377.92,392.87,377.92,376.93Z"
          style={{
            fill: "rgb(38, 50, 56)",
            opacity: 0.7,
            isolation: "isolate",
            transformOrigin: "377.425px 376.925px",
          }}
          className="animable"
        />
      </g>
      <ellipse
        cx={394.02}
        cy={376.96}
        rx={16.11}
        ry={28.81}
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "394.02px 376.96px",
        }}
        id="elem7gucgk1tu"
        className="animable"
      />
      <ellipse
        cx={395.32}
        cy={376.96}
        rx={11.42}
        ry={22.46}
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "395.32px 376.96px",
        }}
        id="eljffzxwvq258"
        className="animable"
      />
      <ellipse
        cx={394.27}
        cy={376.96}
        rx={10.93}
        ry={19.44}
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "394.27px 376.96px",
        }}
        id="elav8nt9k091b"
        className="animable"
      />
      <ellipse
        cx={391.87}
        cy={376.96}
        rx={7.98}
        ry={14.19}
        id="elosb2lra03bd"
        className="animable"
        style={{
          transformOrigin: "391.87px 376.96px",
        }}
      />
      <g transform="translate(250 140)">
        <svg
          viewBox="0 0 2048 321"
          width={175}
          height={128}
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            transform="translate(586,1)"
            d="m0 0h22l22 3 21 6 21 9 16 10 14 11 17 17 11 15 8 14 8 17 6 20 3 17 1 10v18l-2 17-5 21-6 16-8 16-9 14-8 10-11 12-14 12-19 12-17 8-18 6-19 4-9 1h-29l-19-3-18-5-21-9-18-11-16-13-13-13-10-13-8-13-8-16-7-19-4-20-1-9v-28l2-16 6-22 9-21 11-18 9-11 11-12 10-9 14-10 14-8 15-7 20-6 16-3zm14 13v2l3 2v2l-7 1-5-1 1-3 3-2h-12l-21 3-16 5v1l12-1-16 5-2 4 3 5 6-1 17-8 5-1-17 9-5 5 4 3 9 3 4-2 15-15 6-4-9 11-7 10 1 2 11 2h10l1-2 3-24 3 24 1 2h11l11-2-2-5-10-13-4-5 10 8 12 12 4 1 12-5-2-4-18-10-3-2 26 11 4-1 2-3-1-4-18-6 16 2-3-2-15-5-22-3zm-74 21-17 9-11 9-10 10-1 4 4 9 2 3 4-2 15-15 14-11 7-6-5-10zm141 0-6 10 8 7 11 9 17 17 4 1 5-7 1-8-7-8-11-9-13-8zm-130 13-9 7-16 15-7 8-4 5 2 4 8 5 11 5 9 2 5-8 9-16 11-17-1-3-17-7zm119 0-18 8 2 5 11 18 10 18 2 2 7-1 19-9 4-3-2-5-11-12-15-15-8-6zm-92 10-8 14-12 26-1 4 8 3 20 3 18 1 1-1 2-27v-20l-24-3zm62 0-24 3v22l2 25 1 1 18-1 25-4 2-1-2-9-12-26-6-9zm-149 19-9 15-7 16-5 14 1 5 4 6 3-3 11-22 11-17-2-5-5-9zm238 0-7 14 11 18 9 17 4 8 4-4 2-4-1-9-8-20-8-15-4-5zm-223 19-6 9-10 18-6 15v5l7 6 14 8 16 7h2l5-16 11-34-1-3-18-8zm208 1-17 9-15 6 3 9 9 28 4 14 3 1 17-7 15-9 5-4-1-9-12-25-7-11zm-161 19-4 8-8 30-2 10v5l5 2 28 5 19 2h9l1-1 1-8 1-27v-20l-34-4-11-2zm112 0-28 4-18 2v33l1 22 1 1 21-1 27-4 12-3 1-1v-8l-6-26-6-18zm-198 24-1 5v23l2-1 4-20-2-5zm287 0-4 6 4 25h2l1-12-1-17zm-275 15-3 11-2 17v18l12 10 17 10 11 5h2l2-23 2-19v-7l-16-8-15-8-8-6zm261 1-10 7-19 10-9 4v11l2 20 1 18 6-1 19-10 11-8 7-6v-17l-3-22-2-6zm-204 27-2 13-1 15v21l8 3 25 5 24 3h10l1-18v-33l-15-2-24-3-20-4zm145 0-27 5-29 3-4 1v50l1 1h10l25-3 24-5 7-2v-30l-2-19zm63 31-14 10-16 9-9 5-4 16-5 19 6-1 9-7 10-10 13-17 12-23zm-266 1 1 6 11 19 11 14 16 16 6 3h2l-2-9-5-25-3-3-23-12-12-9zm56 29v8l4 20 4 8 18 5 25 4 16 1v-35l-1-1-33-4-25-5zm151 0-24 5-22 3-18 2-1 10v25l1 1 16-1 25-4 15-4 4-2 4-13 2-11v-11zm-139 43v2l16 7 13 4 22 4h5v-7l-1-1-36-5zm129 0-23 5-31 4-2 1v7l13-1 23-5 15-6 6-3z"
            fill="#d5f0db"
          />
          <path
            transform="translate(1325,58)"
            d="m0 0h47v162l-3 11-5 10-8 9-14 8-13 3h-4v-5h-11l1 12 4 4v34l-3 3h-4v8l-2 2h-19l-3-3v-7h-52v8l-2 2h-20l-2-2v-8l-5-1-1-1v-36l4-2v-13h-13v-9h-5l-1-1v-20l5-1 1-8h13v-16l2-2 23-1h50l28 1 1 1 1 16 10 1zm-106 149v32h86v-32zm-19 12-1 1v5l5 2v18l-5 2v6h11v-33zm115 0-1 1v32l1 1h10v-6l-4-1-1-2v-18l5-1v-5zm-76 41-1 4 1 3h47v-7zm0 9-1 7h48v-6l-1-1zm-26 7-1 1v11l1 1h19v-13zm80 0-1 1v12h20v-13zm-54 3-1 5 1 2h47v-7zm0 10-1 5 1 1h47v-6zm-24 8-3 3 2 5h5l2-5-3-3zm11 0-3 3 2 5h5l2-4-4-4zm70 0-3 2 1 5 5 1 2-2-1-5zm11 0-3 2 1 6h5l2-2-1-5z"
            fill="#d5f0db"
          />
          <path
            transform="translate(1854,1)"
            d="m0 0 66 1 3 9 3 11v5l-14 6-11 8-9 11-6 12-9 32-17 64-20 75-22 82h-49l4-17 42-158 25-94 12-45z"
            fill="#d5f0db"
          />
          <path
            transform="translate(738,1)"
            d="m0 0 48 1 7 24 15 57 19 71 26 97 8 16 8 10 10 7 16 8-2 10-4 14-1 1h-66l-3-8-29-109-23-86-27-101-2-7z"
            fill="#d5f0db"
          />
          <path
            transform="translate(1626,1)"
            d="m0 0 21 1 19 4 18 8 15 10 11 10 10 13 9 17 6 18 2 18v9l-3 20-7 19-6 11-11 14-3 3-4-2-14-9-17-8-12-4 5-4 8-6 6-7 7-14 2-8 1-11-2-14-6-14-7-9-10-8-12-6-7-2-98-1-12-2-12-5-10-9-6-8-4-9-2-10v-4z"
            fill="#d5f0db"
          />
          <path
            transform="translate(924,1)"
            d="m0 0h48l-1 9-25 94-44 165-3 11-3 1-14-7-10-10-6-10-3-13v-10l3-14 19-72 27-101 11-42z"
            fill="#d5f0db"
          />
          <path
            transform="translate(281,34)"
            d="m0 0 5 5 12 10 10 5 14 3 6 1 1 229 4 13 7 10 5 5 1 2h-65z"
            fill="#d5f0db"
          />
          <path
            transform="translate(1008,34)"
            d="m0 0 5 5 10 9 12 6 6 2 14 2 1 230 4 12 7 11 5 6h-64z"
            fill="#d5f0db"
          />
          <path
            transform="translate(1501,34)"
            d="m0 0 7 6 10 9 11 5 19 4v259h-47z"
            fill="#d5f0db"
          />
          <path
            transform="translate(1558,159)"
            d="m0 0h81l15 2 19 5 20 11 13 11 10 11 9 14 7 17 4 15 1 10v61l-1 1h-46l-1-1-1-66-4-12-7-11-9-9-12-7-10-3-7-1-81-1z"
            fill="#d5f0db"
          />
          <path
            transform="translate(64,13)"
            d="m0 0 2 1-6 18-5 19-5 29-2 25v102l2 12 6 15 8 12 10 9 9 6v1l-29 5-25 6-5-1-7-10-8-16-5-13-3-16-1-7v-103l4-21 6-17 8-15 10-13 14-14 15-10z"
            fill="#d5f0db"
          />
          <path
            transform="translate(1231,2)"
            d="m0 0h235v46h-235z"
            fill="#d5f0db"
          />
          <path
            transform="translate(188,215)"
            d="m0 0h47l-1 10-5 17-8 17-10 14-9 10-12 11-11 7-16 8-16 5-19 3h-16l-18-2-19-5-17-8-14-10-11-10 3-2 25-6 22-3 50-3 18-6 15-10 7-7 8-13 6-16z"
            fill="#d5f0db"
          />
          <path
            transform="translate(109,1)"
            d="m0 0h15l18 2 16 4 16 7 16 10 15 14 8 9 8 13 7 14 5 15 2 11v9l-46 1-2-6-4-13-7-13-7-9-8-7-12-7-15-5-6-1h-20l-16 4-14 7-10 8-7 8h-1l1-10 6-27 8-25 3-7 15-4z"
            fill="#d5f0db"
          />
          <path
            transform="translate(1853,223)"
            d="m0 0h126l3 8 22 82v4h-49l-12-45v-2h-103l4-17 8-29z"
            fill="#d5f0db"
          />
          <path
            transform="translate(1008,2)"
            d="m0 0h188v46l-1 1h-141l-16-3-11-6-8-7-6-9-4-9-1-4z"
            fill="#d5f0db"
          />
          <path
            transform="translate(281,2)"
            d="m0 0h188v47h-142l-16-3-9-5-8-6-8-11-5-14z"
            fill="#d5f0db"
          />
          <path
            transform="translate(1928,37)"
            d="m0 0 2 1 15 56 25 94 6 22v4h-48l-4-13-24-91-5-19-1-9 3-16 6-11 8-9 12-7z"
            fill="#d5f0db"
          />
          <path
            transform="translate(1064,270)"
            d="m0 0h132v47h-101l-11-4-6-4-5-5-4-5-4-9-1-5z"
            fill="#d5f0db"
          />
          <path
            transform="translate(337,270)"
            d="m0 0h132v47h-101l-11-4-8-6-6-7-4-8-2-11z"
            fill="#d5f0db"
          />
          <path
            transform="translate(125,159)"
            d="m0 0h111v47h-111l-1-1v-45z"
            fill="#d5f0db"
          />
          <path
            transform="translate(1385,180)"
            d="m0 0h105l2 2v97l-4 2h-6v6h-89l1-6h-7l-4-3v-96zm2 3-1 1v93h7l1-36 1-20h86l1 56h7v-94zm17 43-4 2v28l2 2h71l2-3v-26l-2-3zm-2 48-1 1v8l1 1h17l1-1v-8l-1-1zm53 0-1 1v7l2 2h16l2-2v-6l-1-2z"
            fill="#d5f0db"
          />
          <path
            transform="translate(1064,136)"
            d="m0 0h85v47h-85z"
            fill="#d5f0db"
          />
          <path
            transform="translate(337,136)"
            d="m0 0h85v47h-84l-1-1z"
            fill="#d5f0db"
          />
          <path
            transform="translate(1371,246)"
            d="m0 0h1v71h-47v-47l16-3 14-6 9-7z"
            fill="#d5f0db"
          />
          <path
            transform="translate(2e3 5)"
            d="m0 0h15l8 16 3 7 8-16 3-7h16v50h-13l-1-31-8 19h-10l-8-18-1 30h-12z"
            fill="#d5f0db"
          />
          <path
            transform="translate(1391,290)"
            d="m0 0h94l1 1v11l-33 1h-39l-25-1v-10zm0 3v7h15l-2-3-10-4zm89 0-12 6v1h15v-7z"
            fill="#d5f0db"
          />
          <path
            transform="translate(1942,5)"
            d="m0 0h45v9h-16v41h-13l-1-40-15-1z"
            fill="#d5f0db"
          />
          <path
            transform="translate(1397,304)"
            d="m0 0h13l1 1-1 16h-13z"
            fill="#d5f0db"
          />
          <path
            transform="translate(1466,304)"
            d="m0 0h13v17h-13l-1-16z"
            fill="#d5f0db"
          />
          <path transform="translate(562,21)" d="m0 0 2 1z" fill="#d5f0db" />
          <path transform="translate(59,76)" d="m0 0" fill="#d5f0db" />
          <path transform="translate(587,25)" d="m0 0" fill="#d5f0db" />
          <path transform="translate(618,24)" d="m0 0" fill="#d5f0db" />
          <path transform="translate(605,24)" d="m0 0" fill="#d5f0db" />
          <path transform="translate(574,24)" d="m0 0" fill="#d5f0db" />
        </svg>
      </g>
    </g>
    <g
      id="freepik--Character--inject-11"
      className="animable animator-active"
      style={{
        transformOrigin: "350.309px 303.426px",
      }}
    >
      <path
        d="M328.86,236c-.2,3.85-.55,7.57-1.12,11.36a63.37,63.37,0,0,1-2.56,11.53,19.5,19.5,0,0,1-1.43,3.16,9,9,0,0,1-1.44,1.86l-.28.26-.16.12-.08.07-.34.23-.67.47a36.37,36.37,0,0,1-5.34,2.83,61.16,61.16,0,0,1-10.93,3.62l-1.3-3.56c3.18-1.76,6.44-3.56,9.41-5.43a41.78,41.78,0,0,0,4.18-2.93l.44-.37.22-.19v0h0s.09-.15.2-.39a13.52,13.52,0,0,0,.72-2,44.58,44.58,0,0,0,1.07-5c.31-1.74.56-3.51.77-5.31.41-3.57.76-7.23,1-10.79Z"
        style={{
          fill: "rgb(206, 122, 99)",
          transformOrigin: "316.035px 253.525px",
        }}
        id="elr3sg6sd8n2m"
        className="animable"
      />
      <path
        d="M305.58,268l-6.14-1.9,1.15,7.43s4-.5,6-2.74Z"
        style={{
          fill: "rgb(206, 122, 99)",
          transformOrigin: "303.015px 269.815px",
        }}
        id="elxy1r1t0a5rp"
        className="animable"
      />
      <path
        d="M292,270.88l.34,1a2.7,2.7,0,0,0,2.69,1.81l3.13-.14L297,266.1l-3.44,1.35A2.72,2.72,0,0,0,292,270.88Z"
        style={{
          fill: "rgb(206, 122, 99)",
          transformOrigin: "295.003px 269.897px",
        }}
        id="eli4ljzp42rwj"
        className="animable"
      />
      <path
        d="M336.12,228.79c0,2.32-1.12,6.9-2.08,10.34-.71,2.47-1.3,4.35-1.3,4.35l-1.41-.35-11.62-2.94c-.18-6,.05-12.53,6-16.45C329.43,221.28,336.09,224.66,336.12,228.79Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "327.896px 233.194px",
        }}
        id="el9kip19yvltr"
        className="animable"
      />
      <g id="elije3sucon3h">
        <path
          d="M336.11,229.19c-.76-.8-2.48-.94-6.23.25-5.37,1.7-6.87,7.58-6.94,11.57l8.39,2.12.72.19.69.16s.29-.94.71-2.37h0c.17-.58.38-1.26.58-2A54.34,54.34,0,0,0,336.11,229.19Z"
          style={{
            opacity: 0.3,
            isolation: "isolate",
            transformOrigin: "329.525px 236.024px",
          }}
          className="animable"
        />
      </g>
      <polygon
        points="312.27 415.47 305.18 412.99 309.64 397.63 316.72 400.12 312.27 415.47"
        style={{
          fill: "rgb(206, 122, 99)",
          transformOrigin: "310.95px 406.55px",
        }}
        id="elef6zuhw4p3n"
        className="animable"
      />
      <polygon
        points="407 397.27 401.23 401.57 389.42 389.6 395.19 385.3 407 397.27"
        style={{
          fill: "rgb(206, 122, 99)",
          transformOrigin: "398.21px 393.435px",
        }}
        id="eleu1pji9jec8"
        className="animable"
      />
      <path
        d="M398.87,399.82l6.23-5.15a.58.58,0,0,1,.76,0l4.78,4.34a1.15,1.15,0,0,1,0,1.63l-.1.09c-2.2,1.76-3.36,2.5-6.1,4.76-1.68,1.39-5.21,4.72-7.54,6.64s-4.35-.27-3.55-1.28c3.57-4.52,4.4-7.5,4.82-9.94A1.93,1.93,0,0,1,398.87,399.82Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "402.075px 403.71px",
        }}
        id="eldy6pjme0tf"
        className="animable"
      />
      <path
        d="M304.7,410.77l7.88,3.29a.71.71,0,0,1,.42.74l-.89,6.95a1.06,1.06,0,0,1-1.18.92,1,1,0,0,1-.3-.08c-2.79-1.13-4.1-1.69-7.56-3.14L292.45,415c-2.88-1.21-2.26-4.37-1-4.14,5.83,1.06,9.59.71,11.89-.16A1.77,1.77,0,0,1,304.7,410.77Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "301.7px 416.637px",
        }}
        id="el1fbw8bzji8l"
        className="animable"
      />
      <g id="el538lwmasq57">
        <polygon
          points="309.64 397.64 307.35 405.55 314.25 408.67 316.73 400.12 309.64 397.64"
          style={{
            opacity: 0.2,
            isolation: "isolate",
            transformOrigin: "312.04px 403.155px",
          }}
          className="animable"
        />
      </g>
      <g id="eluektd9eiic9">
        <polygon
          points="395.2 385.3 389.42 389.6 395.51 395.77 401.28 391.47 395.2 385.3"
          style={{
            opacity: 0.2,
            isolation: "isolate",
            transformOrigin: "395.35px 390.535px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M357.92,266.33l-29.2.42c-4-22.61-5-33.4-5-38.59a5.56,5.56,0,0,1,4.5-5.48c1.78-.35,4-.75,6.23-1.06.76-.1,1.53-.2,2.29-.28a93.16,93.16,0,0,1,13.26-.19c.66,0,1.31.06,2,.1,3,.19,5.74.57,7.69.87a2.68,2.68,0,0,1,2.22,3.07.77.77,0,0,1,0,.15c-.86,4-1.56,7.55-2.12,10.71-.89,5-1.45,9.08-1.78,12.64A110.52,110.52,0,0,0,357.92,266.33Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "342.832px 243.875px",
        }}
        id="el2rv2cy9uvcv"
        className="animable"
      />
      <g id="ely9lamb10x7j">
        <path
          d="M358,248.72c-1.47-4.41-1.39-13.56.19-16.52l1.61,3.87C358.89,241.1,358.33,245.16,358,248.72Z"
          style={{
            opacity: 0.1,
            isolation: "isolate",
            transformOrigin: "358.374px 240.46px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M328.72,266.75s-5,42.51-9.51,69c-3.57,20.74-12.59,63.91-12.59,63.91l11.71,4.15s13.41-45,15.76-64.22c9.45-27.71,16.25-73.05,16.25-73.05Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "328.48px 335.175px",
        }}
        id="elnjmu2et782p"
        className="animable"
      />
      <polygon
        points="304.72 402.56 318.27 407.33 320.08 402.98 305.38 397.36 304.72 402.56"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "312.4px 402.345px",
        }}
        id="elxsv5usx4rot"
        className="animable"
      />
      <g id="el1n0u5c85yw6">
        <path
          d="M343.52,279.38l-4.38,8.39c-1.6,6.32-1.22,21.7.81,31.42,3.64-14.65,6.55-30.07,8.36-40.42Z"
          style={{
            opacity: 0.1,
            isolation: "isolate",
            transformOrigin: "343.229px 298.98px",
          }}
          className="animable"
        />
      </g>
      <path
        d="M335.06,266.75s5.23,47.58,15,74c4.9,13.25,41.25,52.53,41.25,52.53l8.2-6.56s-23.05-39.63-33.13-50c-4.11-23,1.06-57.48-8.49-70.38Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "367.285px 329.81px",
        }}
        id="elp2r51dxgl6h"
        className="animable"
      />
      <polygon
        points="392 396.09 401.62 388.21 399.02 383.94 388.49 393.11 392 396.09"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "395.055px 390.015px",
        }}
        id="elh79yrmiz20f"
        className="animable"
      />
      <path
        d="M358.38,264.62l1.35,2.59c.1.2-.13.42-.47.42l-30.64.44c-.27,0-.48-.13-.51-.29l-.29-2.61c0-.18.21-.34.49-.34l29.58-.43A.58.58,0,0,1,358.38,264.62Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "343.787px 266.234px",
        }}
        id="eljqx4miyzvik"
        className="animable"
      />
      <path
        d="M354.41,268h.81c.15,0,.27-.09.26-.18l-.43-3.4c0-.1-.14-.17-.3-.17H354c-.16,0-.28.09-.26.18l.42,3.39C354.11,267.9,354.26,268,354.41,268Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "354.609px 266.125px",
        }}
        id="el20qtq744hpt"
        className="animable"
      />
      <path
        d="M330.82,268.32h.8c.16,0,.27-.09.26-.19l-.41-3.39c0-.1-.16-.18-.32-.18h-.8c-.16,0-.28.09-.27.19l.42,3.39C330.52,268.24,330.66,268.32,330.82,268.32Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "330.98px 266.44px",
        }}
        id="elsomc9nxmbyi"
        className="animable"
      />
      <path
        d="M342.62,268.13h.8c.16,0,.28-.09.27-.19l-.42-3.39c0-.11-.15-.19-.31-.18h-.8c-.16,0-.27.09-.27.19l.42,3.39C342.3,268.07,342.46,268.13,342.62,268.13Z"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "342.79px 266.25px",
        }}
        id="ellmgodgq2un"
        className="animable"
      />
      <polygon
        points="289.64 236.82 332.98 230.87 338.98 273.42 296.42 279.26 289.64 236.82"
        style={{
          fill: "rgb(46, 180, 76)",
          transformOrigin: "314.31px 255.065px",
        }}
        id="elbb4h3unyhxj"
        className="animable"
      />
      <g id="elsgptgln9ogp">
        <polygon
          points="289.64 236.82 332.98 230.87 338.98 273.42 296.42 279.26 289.64 236.82"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.8,
            isolation: "isolate",
            transformOrigin: "314.31px 255.065px",
          }}
          className="animable"
        />
      </g>
      <g id="elf73l3pv55ut">
        <rect
          x={324.28}
          y={230.89}
          width={11.69}
          height={44.34}
          style={{
            fill: "rgb(46, 180, 76)",
            opacity: 0.2,
            isolation: "isolate",
            transformOrigin: "330.125px 253.06px",
            transform: "rotate(-8.04906deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M345.27,268.06l-6-2.35.59,7.5s4-.2,6.15-2.29Z"
        style={{
          fill: "rgb(206, 122, 99)",
          transformOrigin: "342.64px 269.46px",
        }}
        id="elupoob42uv3i"
        className="animable"
      />
      <path
        d="M364.14,230.72c1.61,4.5,3,9,4.18,13.6.6,2.31,1.16,4.63,1.61,7,.22,1.2.41,2.42.55,3.69a25.66,25.66,0,0,1,.17,4,10.67,10.67,0,0,1-.4,2.57,7.42,7.42,0,0,1-1.61,2.77,12.77,12.77,0,0,1-3.75,2.77,33.27,33.27,0,0,1-7.24,2.53,76.06,76.06,0,0,1-14.25,1.91l-.47-3.77c2.19-.52,4.43-1.1,6.61-1.71s4.35-1.29,6.43-2.05a32.44,32.44,0,0,0,5.72-2.61,6.64,6.64,0,0,0,1.74-1.47c.13-.2.13-.25.11-.25a6.61,6.61,0,0,0,0-.89,21.46,21.46,0,0,0-.31-2.89c-.16-1-.38-2.12-.62-3.22-.5-2.14-1.06-4.33-1.69-6.52-1.25-4.34-2.66-8.75-4.07-13Z"
        style={{
          fill: "rgb(206, 122, 99)",
          transformOrigin: "356.798px 251.14px",
        }}
        id="el1u19vefydcw"
        className="animable"
      />
      <path
        d="M333.74,269.41l.47,1.74a2.65,2.65,0,0,0,2.49,2l3.22.09-.59-7.49-3.28.37a2.66,2.66,0,0,0-2.38,2.92A2.7,2.7,0,0,0,333.74,269.41Z"
        style={{
          fill: "rgb(206, 122, 99)",
          transformOrigin: "336.788px 269.495px",
        }}
        id="elowj4yrm7uvi"
        className="animable"
      />
      <path
        d="M360.73,222.34c3.36,1.37,6.11,12.41,6.11,12.41L357.56,242s-6.58-10.3-5.24-14.77C353.46,223.45,356.45,220.61,360.73,222.34Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "359.49px 231.91px",
        }}
        id="el7awlt4qhgg9"
        className="animable"
      />
      <path
        d="M396.6,402.94h-.12a1,1,0,0,1-.81-.52.59.59,0,0,1-.05-.59c.41-.8,3-1.12,3.28-1.15a.16.16,0,0,1,.17.08.17.17,0,0,1,0,.19C398.53,401.68,397.48,402.88,396.6,402.94Zm1.95-1.87c-1,.15-2.41.48-2.61.92a.23.23,0,0,0,0,.25.62.62,0,0,0,.53.36c.5,0,1.3-.54,2.1-1.53Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "397.331px 401.809px",
        }}
        id="elxwfsn25usj"
        className="animable"
      />
      <path
        d="M398.78,401c-.87,0-2.61-.31-3-1a.6.6,0,0,1,.14-.73.89.89,0,0,1,.7-.29c1.05,0,2.38,1.61,2.42,1.7a.15.15,0,0,1,0,.16.15.15,0,0,1-.13.11Zm-2.22-1.66a.59.59,0,0,0-.37.18c-.15.16-.12.26-.08.34.23.44,1.6.8,2.48.8a4,4,0,0,0-1.95-1.33Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "397.388px 399.989px",
        }}
        id="elr9xtxjd8a7"
        className="animable"
      />
      <path
        d="M300.67,410.61a2.82,2.82,0,0,1-1.39-1,1.1,1.1,0,0,1-.08-.92.52.52,0,0,1,.38-.35c.92-.17,3.09,2.16,3.51,2.63a.18.18,0,0,1,0,.2.16.16,0,0,1-.18.08A11.51,11.51,0,0,1,300.67,410.61Zm-.69-1.88a.63.63,0,0,0-.33-.06s-.09,0-.13.12a.72.72,0,0,0,.06.64c.31.54,1.38,1.05,2.9,1.38A10.42,10.42,0,0,0,300,408.73Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "301.127px 409.793px",
        }}
        id="elbe40jqf3gu"
        className="animable"
      />
      <path
        d="M302.9,411.23l-.06,0c-.73-.73-1.92-2.92-1.51-3.62.09-.15.28-.31.71-.19,1.61.47,1.12,3.59,1.09,3.72a.13.13,0,0,1-.12.13A.11.11,0,0,1,302.9,411.23Zm-.8-3.49H302c-.16-.05-.28,0-.32,0-.24.41.53,2.06,1.23,2.94C302.9,409.91,303,408.14,302.1,407.74Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "302.235px 409.325px",
        }}
        id="elhkxyz6zc0ff"
        className="animable"
      />
      <path
        d="M333.08,191.23s-4,6.69-1,9.62,3.88-7.46,3.88-7.46Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "333.462px 196.3px",
        }}
        id="ells8v3iujec"
        className="animable"
      />
      <path
        d="M351.66,206.93s5-7.82,2.92-15.59-11.8-2.75-15.9-4.88a23.46,23.46,0,0,0-4.77-2.21,1.83,1.83,0,0,0-1.59.27c-1,.88.74,2.53,1.28,3.15a5.52,5.52,0,0,0-1.14-1c-.53-.35-1.24-.38-1,.51a4.4,4.4,0,0,0,2.08,2.2c-.39-.22-2.7-1.39-2.86-.66a.72.72,0,0,0,.13.5c1.5,2.8,4.52,6.32,8,6.39C343,195.67,347,207.46,351.66,206.93Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "342.881px 195.561px",
        }}
        id="elscg0ndzc0x"
        className="animable"
      />
      <path
        d="M353.43,207.28c-.26,2.64-4.18,2.67-4.18,2.67a8.48,8.48,0,0,1-3.07,3.72,22,22,0,0,0,.16,4.78c.66,2.49,1.5,3.35,2.55,3.91h0l-.84.83a11,11,0,0,1-5.28,1.47l-1.1-.64-1.44.23c-4-1.31-3-4-3-4a8.09,8.09,0,0,0,.11-5.71c-3.84-1.2-5.41-4.54-5.95-10.16s2.62-11,2.62-11c4.34,4.19,12.94,1.5,12.94,1.5-.37,7.88,3.31,10.79,3.31,10.79C351.47,204.59,353.68,204.65,353.43,207.28Z"
        style={{
          fill: "rgb(206, 122, 99)",
          transformOrigin: "342.389px 209.02px",
        }}
        id="elfahrpznzvue"
        className="animable"
      />
      <path
        d="M339.47,208a4.42,4.42,0,0,0,2.51-1.84.33.33,0,0,0-.08-.45.32.32,0,0,0-.45.07,3.25,3.25,0,0,1-3.51,1.6.32.32,0,0,0-.4.21.33.33,0,0,0,.21.41A3.4,3.4,0,0,0,339.47,208Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "339.78px 206.879px",
        }}
        id="el6kfxq2dbu89"
        className="animable"
      />
      <path
        d="M340.61,201.12c0,.51-.2.95-.54,1s-.65-.37-.69-.88.21-1,.55-1S340.57,200.6,340.61,201.12Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "339.993px 201.182px",
        }}
        id="eloljn7n7iipk"
        className="animable"
      />
      <path
        d="M334.49,200.86c0,.51-.2.95-.54,1s-.64-.37-.68-.88.2-.95.54-1S334.46,200.34,334.49,200.86Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "333.878px 200.92px",
        }}
        id="el74d7h88ph36"
        className="animable"
      />
      <path
        d="M336.11,205.81l-.07-3.7-1.84,3.12A2.3,2.3,0,0,0,336.11,205.81Z"
        style={{
          fill: "rgb(186, 77, 60)",
          transformOrigin: "335.155px 203.974px",
        }}
        id="elrcut2d3xmr"
        className="animable"
      />
      <path
        d="M343.4,198a.34.34,0,0,0,.12-.06.42.42,0,0,0,.14-.59,3.47,3.47,0,0,0-3.27-1.68.42.42,0,0,0-.37.48.43.43,0,0,0,.48.37h0a2.64,2.64,0,0,1,2.43,1.28A.43.43,0,0,0,343.4,198Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "341.871px 196.835px",
        }}
        id="el57uv5va1kg9"
        className="animable"
      />
      <path
        d="M332.55,197.59a.45.45,0,0,0,.27-.2,1.68,1.68,0,0,1,1.68-.73.42.42,0,0,0,.5-.34.43.43,0,0,0-.34-.5,2.5,2.5,0,0,0-2.58,1.14.44.44,0,0,0,.15.59A.45.45,0,0,0,332.55,197.59Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "333.515px 196.691px",
        }}
        id="eln26nyifq0v"
        className="animable"
      />
      <polygon
        points="350.52 222.71 348.37 224.81 344.54 228.57 343.1 226.28 341.67 224.02 346.67 218.06 350.31 222.46 350.52 222.71"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "346.095px 223.315px",
        }}
        id="el1da6r0frsnj"
        className="animable"
      />
      <polygon
        points="341.67 224.02 340.56 225.87 338.93 228.57 335.19 221.82 337.58 219.01 341.67 224.02"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "338.43px 223.79px",
        }}
        id="eltiy6u97wahd"
        className="animable"
      />
    </g>
    <defs>
      <filter id="active" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter id="hover" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#ff0000" floodOpacity={0.5} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
        <feColorMatrix
          type="matrix"
          values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
        />
      </filter>
    </defs>
    <defs>
      <clipPath id="freepik--clip-path--inject-11">
        <path
          d="M297.68,348.92h-17c-8.85,0-16,12.83-16,28.66s7.17,28.65,16,28.65h17c8.85,0,16-12.83,16-28.65S306.53,348.92,297.68,348.92Z"
          style={{
            fill: "#263238",
          }}
        />
      </clipPath>
    </defs>
  </svg>
);
export default GeovetraHeroSvg;
