interface PageNotFoundSVGProps {
  className?: string | undefined;
}

const PageNotFoundSVG: React.FC<PageNotFoundSVGProps> = ({
  className,
  ...props
}) => {
  return (
    <svg
      className={`animated ${className}`}
      id="freepik_stories-404-error-page-not-found-with-people-connecting-a-plug"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      {...props}
    >
      <style>
        {
          "svg#freepik_stories-404-error-page-not-found-with-people-connecting-a-plug:not(.animated) .animable {opacity: 0;}svg#freepik_stories-404-error-page-not-found-with-people-connecting-a-plug.animated #freepik--Trees--inject-2 {animation: 3s Infinite  linear wind;animation-delay: 0s;}svg#freepik_stories-404-error-page-not-found-with-people-connecting-a-plug.animated #freepik--Text--inject-2 {animation: 1.5s Infinite  linear floating;animation-delay: 0s;}            @keyframes wind {                0% {                    transform: rotate( 0deg );                }                25% {                    transform: rotate( 1deg );                }                75% {                    transform: rotate( -1deg );                }            }                    @keyframes floating {                0% {                    opacity: 1;                    transform: translateY(0px);                }                50% {                    transform: translateY(-10px);                }                100% {                    opacity: 1;                    transform: translateY(0px);                }            }        "
        }
      </style>
      <g
        id="freepik--background-simple--inject-2"
        className="animable"
        style={{
          transformOrigin: "249.98px 231.793px",
        }}
      >
        <path
          d="M81.91,305.64s25,57,88.21,75.64,130.71-6.2,185.87-24.87S440.16,290,427.87,241.05s-58.76-45.43-102.1-88.9S277.49,90.54,217.43,77.7,91.63,115.41,75.19,187,81.91,305.64,81.91,305.64Z"
          style={{
            fill: "#2EB44C",
            transformOrigin: "249.98px 231.793px",
          }}
          id="elg0s86ju1g7n"
          className="animable"
        />
        <g id="el4uw20mzizn">
          <path
            d="M81.91,305.64s25,57,88.21,75.64,130.71-6.2,185.87-24.87S440.16,290,427.87,241.05s-58.76-45.43-102.1-88.9S277.49,90.54,217.43,77.7,91.63,115.41,75.19,187,81.91,305.64,81.91,305.64Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.8,
              transformOrigin: "249.98px 231.793px",
            }}
            className="animable"
            id="elxi06k8np2u"
          />
        </g>
      </g>
      <g
        id="freepik--Trees--inject-2"
        className="animable"
        style={{
          transformOrigin: "249.986px 232.085px",
        }}
      >
        <path
          d="M403,228.6s22.92,2.27,34.53,16.48c0,0,12.28-16.46,34.32-16.12a44.55,44.55,0,1,0-68.86-.32Z"
          style={{
            fill: "#2EB44C",
            transformOrigin: "437.551px 200.53px",
          }}
          id="elld2nqay5ye"
          className="animable"
        />
        <g id="eld13egyxd9rc">
          <path
            d="M403,228.6s22.92,2.27,34.53,16.48c0,0,12.28-16.46,34.32-16.12a44.55,44.55,0,1,0-68.86-.32Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.5,
              transformOrigin: "437.551px 200.53px",
            }}
            className="animable"
            id="eldd37zpgfy08"
          />
        </g>
        <polygon
          points="436.76 159.12 435.99 307.66 439.86 307.66 436.76 159.12"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "437.925px 233.39px",
          }}
          id="eld1p7aits7dk"
          className="animable"
        />
        <path
          d="M437.93,240.75s7.73-13.93,21.66-18.19c0,0-14.7,3.49-22,15.48Z"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "448.59px 231.655px",
          }}
          id="elw7p3xwvltjh"
          className="animable"
        />
        <path
          d="M437.93,201.29s7.73-13.93,21.66-18.19c0,0-14.7,3.48-22,15.48Z"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "448.59px 192.195px",
          }}
          id="elx20k56uftl"
          className="animable"
        />
        <path
          d="M437.93,221s7.73-13.93,21.66-18.19c0,0-14.7,3.49-22,15.48Z"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "448.59px 211.905px",
          }}
          id="el0lywirydbhgl"
          className="animable"
        />
        <path
          d="M436.78,211.34s-7.47-13.92-20.91-18.18c0,0,14.19,3.48,21.28,15.48Z"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "426.51px 202.25px",
          }}
          id="el5wkzhry003a"
          className="animable"
        />
        <path
          d="M436.78,230.69s-7.47-13.93-20.91-18.18c0,0,14.19,3.48,21.28,15.47Z"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "426.51px 221.6px",
          }}
          id="elvblx1pwlu9"
          className="animable"
        />
        <path
          d="M27.87,228.6s22.92,2.27,34.53,16.48c0,0,12.28-16.46,34.32-16.12a44.55,44.55,0,1,0-68.86-.32Z"
          style={{
            fill: "#2EB44C",
            transformOrigin: "62.4214px 200.53px",
          }}
          id="eldketn5ybfq"
          className="animable"
        />
        <g id="elbj7yiwxsfoc">
          <path
            d="M27.87,228.6s22.92,2.27,34.53,16.48c0,0,12.28-16.46,34.32-16.12a44.55,44.55,0,1,0-68.86-.32Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.5,
              transformOrigin: "62.4214px 200.53px",
            }}
            className="animable"
            id="elt73xxoh27a"
          />
        </g>
        <polygon
          points="61.65 159.12 60.87 307.66 64.74 307.66 61.65 159.12"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "62.805px 233.39px",
          }}
          id="eli387ghs6izh"
          className="animable"
        />
        <path
          d="M62.81,240.75s7.73-13.93,21.66-18.19c0,0-14.7,3.49-22,15.48Z"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "73.47px 231.655px",
          }}
          id="el2dpx91o9snc"
          className="animable"
        />
        <path
          d="M62.81,201.29s7.73-13.93,21.66-18.19c0,0-14.7,3.48-22,15.48Z"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "73.47px 192.195px",
          }}
          id="el23xsxcwt97a"
          className="animable"
        />
        <path
          d="M62.81,221s7.73-13.93,21.66-18.19c0,0-14.7,3.49-22,15.48Z"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "73.47px 211.905px",
          }}
          id="elibqjvoyfbm"
          className="animable"
        />
        <path
          d="M61.66,211.34s-7.47-13.92-20.91-18.18c0,0,14.19,3.48,21.28,15.48Z"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "51.39px 202.25px",
          }}
          id="el27k6zyev7ek"
          className="animable"
        />
        <path
          d="M61.66,230.69s-7.47-13.93-20.91-18.18c0,0,14.19,3.48,21.28,15.47Z"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "51.39px 221.6px",
          }}
          id="eldm7a4lpzvgw"
          className="animable"
        />
        <path
          d="M309.87,243s18.76,1.85,28.26,13.48c0,0,10.05-13.47,28.09-13.2a36.46,36.46,0,1,0-56.36-.25Z"
          style={{
            fill: "#2EB44C",
            transformOrigin: "338.143px 220.02px",
          }}
          id="elmysgaw028a"
          className="animable"
        />
        <g id="el7ypgon1b4bg">
          <path
            d="M309.87,243s18.76,1.85,28.26,13.48c0,0,10.05-13.47,28.09-13.2a36.46,36.46,0,1,0-56.36-.25Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.5,
              transformOrigin: "338.143px 220.02px",
            }}
            className="animable"
            id="el5mnvzeggniv"
          />
        </g>
        <polygon
          points="337.51 186.08 336.88 307.66 340.05 307.66 337.51 186.08"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "338.465px 246.87px",
          }}
          id="elryqc8of41q"
          className="animable"
        />
        <path
          d="M338.46,252.9s6.34-11.4,17.73-14.89c0,0-12,2.85-18,12.67Z"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "347.19px 245.455px",
          }}
          id="elvf6zbo0zr7f"
          className="animable"
        />
        <path
          d="M338.46,220.6s6.34-11.4,17.73-14.88c0,0-12,2.85-18,12.66Z"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "347.19px 213.16px",
          }}
          id="el7okgaybo4l"
          className="animable"
        />
        <path
          d="M338.46,236.75s6.34-11.4,17.73-14.89c0,0-12,2.85-18,12.67Z"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "347.19px 229.305px",
          }}
          id="el7szv43k4cp7"
          className="animable"
        />
        <path
          d="M337.52,228.83s-6.11-11.4-17.11-14.88c0,0,11.61,2.85,17.42,12.66Z"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "329.12px 221.39px",
          }}
          id="elxkm1nepj1uc"
          className="animable"
        />
        <path
          d="M337.52,244.66s-6.11-11.4-17.11-14.88c0,0,11.61,2.85,17.42,12.67Z"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "329.12px 237.22px",
          }}
          id="elasah3c8gw99"
          className="animable"
        />
        <path
          d="M128.36,243s18.76,1.85,28.26,13.48c0,0,10-13.47,28.09-13.2a36.46,36.46,0,1,0-56.36-.25Z"
          style={{
            fill: "#2EB44C",
            transformOrigin: "156.633px 220.02px",
          }}
          id="el43q9ou9gted"
          className="animable"
        />
        <g id="el5j1gu3wyjg">
          <path
            d="M128.36,243s18.76,1.85,28.26,13.48c0,0,10-13.47,28.09-13.2a36.46,36.46,0,1,0-56.36-.25Z"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.5,
              transformOrigin: "156.633px 220.02px",
            }}
            className="animable"
            id="elkeq9o1j2xwf"
          />
        </g>
        <polygon
          points="156 186.08 155.37 307.66 158.54 307.66 156 186.08"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "156.955px 246.87px",
          }}
          id="elixp2ehh26ki"
          className="animable"
        />
        <path
          d="M157,252.9s6.34-11.4,17.73-14.89c0,0-12,2.85-18,12.67Z"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "165.73px 245.455px",
          }}
          id="el8ii6y80qdiw"
          className="animable"
        />
        <path
          d="M157,220.6s6.34-11.4,17.73-14.88c0,0-12,2.85-18,12.66Z"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "165.73px 213.16px",
          }}
          id="eltk18t6szmmd"
          className="animable"
        />
        <path
          d="M157,236.75s6.34-11.4,17.73-14.89c0,0-12,2.85-18,12.67Z"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "165.73px 229.305px",
          }}
          id="el3lzv0fn3yhu"
          className="animable"
        />
        <path
          d="M156,228.83S149.9,217.43,138.9,214c0,0,11.61,2.85,17.42,12.66Z"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "147.61px 221.415px",
          }}
          id="eltgmzzi84kh"
          className="animable"
        />
        <path
          d="M156,244.66s-6.11-11.4-17.11-14.88c0,0,11.61,2.85,17.42,12.67Z"
          style={{
            fill: "rgb(184, 184, 184)",
            transformOrigin: "147.6px 237.22px",
          }}
          id="elc66k86pue3s"
          className="animable"
        />
        <line
          x1={34.77}
          y1={308.19}
          x2={466.31}
          y2={308.19}
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeMiterlimit: 10,
            strokeWidth: "1.04742px",
            transformOrigin: "250.54px 308.19px",
          }}
          id="elzsxfrbh0fy"
          className="animable"
        />
      </g>
      <g
        id="freepik--Text--inject-2"
        className="animable"
        style={{
          transformOrigin: "249.79px 271.83px",
        }}
      >
        <path
          d="M188.51,120.72c0-7.73,4.08-12.15,11.53-12.15s11.54,4.42,11.54,12.15v25.14c0,7.73-4.08,12.15-11.54,12.15s-11.53-4.42-11.53-12.15Zm7.6,25.62c0,3.45,1.52,4.76,3.93,4.76s3.94-1.31,3.94-4.76v-26.1c0-3.45-1.52-4.76-3.94-4.76s-3.93,1.31-3.93,4.76Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "200.045px 133.29px",
          }}
          id="eljcv720ij3dg"
          className="animable"
        />
        <path
          d="M217.31,120.72c0-7.73,4.07-12.15,11.53-12.15s11.53,4.42,11.53,12.15v25.14c0,7.73-4.08,12.15-11.53,12.15s-11.53-4.42-11.53-12.15Zm7.59,25.62c0,3.45,1.52,4.76,3.94,4.76s3.93-1.31,3.93-4.76v-26.1c0-3.45-1.52-4.76-3.93-4.76s-3.94,1.31-3.94,4.76Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "228.84px 133.29px",
          }}
          id="elsx7tblpt5q"
          className="animable"
        />
        <path
          d="M269.23,121.07v6.28c0,7.74-3.73,12-11.32,12h-3.59v18.16h-7.6V109.13h11.19C265.5,109.13,269.23,113.34,269.23,121.07Zm-14.91-5v16.36h3.59c2.41,0,3.72-1.1,3.72-4.55v-7.25c0-3.46-1.31-4.56-3.72-4.56Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "257.975px 133.32px",
          }}
          id="elnjz6389ntb"
          className="animable"
        />
        <path
          d="M284.77,108.57c7.38,0,11.18,4.42,11.18,12.15v1.52h-7.18v-2c0-3.45-1.38-4.76-3.8-4.76s-3.8,1.31-3.8,4.76c0,9.94,14.85,11.81,14.85,25.62,0,7.73-3.87,12.15-11.32,12.15s-11.33-4.42-11.33-12.15v-3h7.18v3.45c0,3.45,1.52,4.7,3.94,4.7s3.93-1.25,3.93-4.7c0-9.94-14.84-11.81-14.84-25.62C273.58,113,277.38,108.57,284.77,108.57Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "284.695px 133.29px",
          }}
          id="eltlmee6yd0ep"
          className="animable"
        />
        <path
          d="M302.79,146.75l-1-17.46V109.13h7.6v20.16l-1,17.46Zm6.49,3.39v7.32H302v-7.32Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "305.59px 133.295px",
          }}
          id="eln9ti86zx5cb"
          className="animable"
        />
        <path
          d="M172.12,412.17c3.56,0,5.08,2.11,5.08,5.54v2.72c0,3.59-1.68,5.47-5.27,5.47h-2.24v8.93h-2.52V412.17Zm-.19,11.46c1.87,0,2.78-.87,2.78-3v-3c0-2.07-.75-3.11-2.59-3.11h-2.43v9.16Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "172.185px 423.5px",
          }}
          id="el7j3cpsmttje"
          className="animable"
        />
        <path
          d="M182.06,430.26l-.88,4.57h-2.33l4.34-22.66h3.66l4.34,22.66h-2.53l-.87-4.57Zm.32-2.16h5.05L184.87,415Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "185.02px 423.5px",
          }}
          id="elxkk6opa222"
          className="animable"
        />
        <path
          d="M203.81,417.64v1.94h-2.4v-2.07c0-2-.81-3.26-2.65-3.26s-2.65,1.22-2.65,3.26v12c0,2,.84,3.27,2.65,3.27s2.65-1.23,2.65-3.27V425h-2.33v-2.27h4.73v6.67c0,3.43-1.62,5.69-5.11,5.69s-5.09-2.26-5.09-5.69V417.64c0-3.43,1.62-5.69,5.09-5.69S203.81,414.21,203.81,417.64Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "198.71px 423.52px",
          }}
          id="el8m115o1azul"
          className="animable"
        />
        <path
          d="M214.91,422.21v2.26h-5.53v8.06h6.79v2.3h-9.32V412.17h9.32v2.3h-6.79v7.74Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "211.51px 423.5px",
          }}
          id="el0jc28429g11n"
          className="animable"
        />
        <path
          d="M227.05,434.83h-2.26V412.17H228l5.24,16.38V412.17h2.24v22.66h-2.63l-5.79-18.32Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "230.135px 423.5px",
          }}
          id="elwt5l3h6ihe"
          className="animable"
        />
        <path
          d="M238.51,417.64c0-3.46,1.71-5.69,5.18-5.69s5.21,2.23,5.21,5.69v11.72c0,3.43-1.72,5.69-5.21,5.69s-5.18-2.26-5.18-5.69ZM241,429.49c0,2.07.88,3.27,2.69,3.27s2.68-1.2,2.68-3.27v-12c0-2-.84-3.26-2.68-3.26s-2.69,1.22-2.69,3.26Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "243.705px 423.5px",
          }}
          id="ellpvcyz02qqc"
          className="animable"
        />
        <path
          d="M255.24,434.83V414.47h-4.17v-2.3h10.87v2.3h-4.17v20.36Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "256.505px 423.5px",
          }}
          id="elrh06leq41wq"
          className="animable"
        />
        <path
          d="M277.74,422.72V425h-5.21v9.81H270V412.17h9v2.3h-6.47v8.25Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "274.5px 423.49px",
          }}
          id="elrvuivl87u4f"
          className="animable"
        />
        <path
          d="M281.46,417.64c0-3.46,1.72-5.69,5.18-5.69s5.21,2.23,5.21,5.69v11.72c0,3.43-1.71,5.69-5.21,5.69s-5.18-2.26-5.18-5.69Zm2.5,11.85c0,2.07.87,3.27,2.68,3.27s2.69-1.2,2.69-3.27v-12c0-2-.84-3.26-2.69-3.26s-2.68,1.22-2.68,3.26Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "286.655px 423.5px",
          }}
          id="elwuahz2gl6fh"
          className="animable"
        />
        <path
          d="M297.29,429.49c0,2,.81,3.27,2.65,3.27s2.63-1.23,2.63-3.27V412.17h2.36v17.19c0,3.43-1.59,5.73-5.08,5.73s-5.05-2.3-5.05-5.73V412.17h2.49Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "299.865px 423.63px",
          }}
          id="eljiv9xk5rv3o"
          className="animable"
        />
        <path
          d="M310.3,434.83H308V412.17h3.2l5.24,16.38V412.17h2.24v22.66H316.1l-5.8-18.32Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "313.34px 423.5px",
          }}
          id="el504jcgcxj14"
          className="animable"
        />
        <path
          d="M327.26,412.17c3.53,0,5.15,2.14,5.15,5.63v11.43c0,3.46-1.62,5.6-5.15,5.6H322V412.17Zm0,20.36c1.81,0,2.69-1.13,2.69-3.2V417.67c0-2.07-.88-3.2-2.72-3.2h-2.72v18.06Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "327.205px 423.5px",
          }}
          id="el60syt8pwikx"
          className="animable"
        />
      </g>
      <g
        id="freepik--Characters--inject-2"
        className="animable"
        style={{
          transformOrigin: "253.475px 278.501px",
        }}
      >
        <g id="el6qy3ybqwwwa">
          <path
            d="M152.59,356.45c0,3-18.43,5.39-41.18,5.39s-41.18-2.41-41.18-5.39,18.44-5.39,41.18-5.39S152.59,353.48,152.59,356.45Z"
            style={{
              opacity: 0.3,
              transformOrigin: "111.41px 356.45px",
            }}
            className="animable"
            id="el1oqfbe1fnid"
          />
        </g>
        <g id="el8hmhs9uf22l">
          <path
            d="M238.8,356.45c0,3-18.44,5.39-41.18,5.39s-41.18-2.41-41.18-5.39,18.44-5.39,41.18-5.39S238.8,353.48,238.8,356.45Z"
            style={{
              opacity: 0.3,
              transformOrigin: "197.62px 356.45px",
            }}
            className="animable"
            id="el4nye92ouqf6"
          />
        </g>
        <g id="elb37jeprkf26">
          <path
            d="M353,356.45c0,3-18.43,5.39-41.17,5.39s-41.18-2.41-41.18-5.39,18.43-5.39,41.18-5.39S353,353.48,353,356.45Z"
            style={{
              opacity: 0.3,
              transformOrigin: "311.825px 356.45px",
            }}
            className="animable"
            id="elewb4sh0pez"
          />
        </g>
        <g id="el0g13x69e7iz9">
          <path
            d="M448.4,356.45c0,3-18.44,5.39-41.18,5.39S366,359.43,366,356.45s18.44-5.39,41.18-5.39S448.4,353.48,448.4,356.45Z"
            style={{
              opacity: 0.3,
              transformOrigin: "407.2px 356.45px",
            }}
            className="animable"
            id="ellqlb3n0js9i"
          />
        </g>
        <path
          d="M316.28,198.77s-1.69,7-1.69,8.18a10.06,10.06,0,0,1-.24,2.17s-3.61,4.33-3.13,5.53a5.83,5.83,0,0,0,2.41,2.16s1.92,7,3.85,7.46a46.34,46.34,0,0,0,6,.72l.72,5.77,9.62-1.44-2.4-8.66,1-7.46s1.68-3.12,0-8.17-5.29-5.29-7.7-5.29A83.06,83.06,0,0,1,316.28,198.77Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "322.498px 214.765px",
          }}
          id="elgjksm7b4vn"
          className="animable"
        />
        <path
          d="M320.61,195.17s-8.42-.25-8.18,1.92,8.9,5,8.9,5-2.89,1.92-1.45,3.85,4.81,3.61,4.81,3.61-1,4.09.49,4.33,2.4-1.93,2.64-2.65,2.89-.48,3.61,1.44a10,10,0,0,1,.72,3.13s2.89-8.66,3.37-12.51S325.18,195.17,320.61,195.17Z"
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "323.98px 205.481px",
          }}
          id="elo35vj3cb97i"
          className="animable"
        />
        <path
          d="M325.94,214s2.91-6.4,4.65-3.25,1,6.43-.71,6.78-3.14.69-3.14.69"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "328.762px 214.049px",
          }}
          id="eltsh0xao3uq"
          className="animable"
        />
        <path
          d="M314.11,280.79c-.47.55-8.18,17.08-9.14,22.61s-7.21,38.49-7.46,39.93-13.46,4.33-14.43,4.57,1,2.89,3.85,3.61,11.07,4.09,13,2.16,4.57-12.75,5.29-13.95,14.26-33.1,14.26-33.1,6.94-15.31,8.38-16.76-1.71-7.86-2.92-9.79S317.48,276.94,314.11,280.79Z"
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "305.53px 316.259px",
          }}
          id="elu2bz4sy3fmq"
          className="animable"
        />
        <path
          d="M312.67,281s3.37,16.12,5.05,21.89a46.07,46.07,0,0,0,7,13.47c1.93,2.65,11.31,26,11.31,26l1.44,3.61s-.48,1.44-3.12,3.36-10.11,5.05-8.18,5.78,9.62.72,13.23.48a12.5,12.5,0,0,0,8.17-3.85c1.45-1.69-2.4-6.5-2.4-7.46s-8.42-30.3-8.9-31.75-2.89-11.78-2.89-11.78V289s1.21-2.16,1.21-7-3.61-5.78-3.61-5.78l-19.73,1.93Z"
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "329.573px 315.978px",
          }}
          id="elnswli01j4sn"
          className="animable"
        />
        <path
          d="M311,279.59s.49,4.09,1.93,4.33,16.35,4.33,22.85-.48c0,0,1.2-3.61.48-4.57s-2.41-.48-11.06-.48S311,278.39,311,279.59Z"
          style={{
            fill: "#2EB44C",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "323.745px 282.02px",
          }}
          id="elu1rr6r074o"
          className="animable"
        />
        <path
          d="M333.11,225.71s-8.9,1.2-12.75,5-7.21,10.83-8.89,17.32-2.89,30.31-.49,31.51,22.61,2.41,26,1-.24-12.75-.24-17.08,3.85-19,3.13-27.17S333.11,225.71,333.11,225.71Z"
          style={{
            fill: "#2EB44C",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "324.796px 253.442px",
          }}
          id="elr1dpd5s8nx8"
          className="animable"
        />
        <path
          d="M301,250.71c-.11-9.9-4.15-18.35-9.52-22l0-2-14.8.15c-8.33.09-15,11-14.83,24.3s7,24.07,15.34,24l14.8-.15,0-2C297.19,269.14,301.05,260.61,301,250.71Z"
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "281.424px 250.935px",
          }}
          id="el9maf8th0sls"
          className="animable"
        />
        <path
          d="M307.64,250.64c-.11-10.4-4.12-19.28-9.76-23.14l0-2.13-6.34.07c-8.76.09-15.73,11.52-15.58,25.53s7.36,25.29,16.12,25.2l6.34-.07,0-2.12C303.93,270,307.75,261,307.64,250.64Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "291.8px 250.77px",
          }}
          id="elqznfxq5k6jo"
          className="animable"
        />
        <path
          d="M314,250.57c.15,14-6.83,25.44-15.58,25.53s-16-11.19-16.12-25.19,6.83-25.44,15.58-25.54S313.83,236.56,314,250.57Z"
          style={{
            fill: "rgb(115, 115, 115)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "298.15px 250.735px",
          }}
          id="elo984hjsgmnf"
          className="animable"
        />
        <path
          d="M310.36,250.14c.07,6.63-3.24,12.05-7.39,12.1s-7.57-5.31-7.64-12,3.24-12,7.39-12.1S310.29,243.5,310.36,250.14Z"
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "302.845px 250.189px",
          }}
          id="elr6mjnf3y1kp"
          className="animable"
        />
        <path
          d="M428.2,300a121.5,121.5,0,0,0,1.05-18.6c-3.25,1.5-22.21,2.05-23.95,2.09s-20.7-.59-23.95-2.09a121.5,121.5,0,0,0,1,18.6c1.31,8.11,12.83,40.58,13.09,42.15a34.94,34.94,0,0,0,1.31,4.46s-6.55,4.71-9.43,6.54-.78,2.62,2.1,2.62,8.9-.79,10.73-.79c1.62,0,4.44-1.41,5.1-1.75.66.34,3.48,1.75,5.09,1.75,1.84,0,7.86.79,10.74.79s5-.79,2.1-2.62-9.43-6.54-9.43-6.54a34.94,34.94,0,0,0,1.31-4.46C415.37,340.55,426.89,308.08,428.2,300Z"
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "405.296px 318.585px",
          }}
          id="eldjzz4s8m83j"
          className="animable"
        />
        <path
          d="M401.34,230.49s-10.13,3.31-15.36,6.63-10.48,21.3-10.48,22a22.41,22.41,0,0,1-1.22,3.66s-5.24-1.22-6.28,3.14,5.23,9.95,8.55,10.13,4.36-.87,4.36-.87-2.09,4.19-1.22,5.93,8.21,8.21,22.7,8,25-2.62,26.53-4.71,1.75-6.46,1.75-6.46,5.41-1.4,7.33-4.89,1.57-7.16.52-10.82S432.06,247.94,428.4,242s-11.35-9.25-15-10.3S403.78,230.49,401.34,230.49Z"
          style={{
            fill: "#2EB44C",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "403.63px 259.803px",
          }}
          id="elb1tmans2cg4"
          className="animable"
        />
        <path
          d="M404.41,245.19c1.45-.48,6-4.52,7.75-8.56a19.47,19.47,0,0,0,1.45-4.85l-.23-.07c-3.66-1.05-9.6-1.22-12-1.22,0,0-2.12.69-4.92,1.76a18,18,0,0,0,1.2,6.32C399.24,242.61,403,245.68,404.41,245.19Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "405.035px 237.866px",
          }}
          id="elvm75hxwt52l"
          className="animable"
        />
        <path
          d="M421.06,253.35S425.25,275.7,430,278"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "425.53px 265.675px",
          }}
          id="elipbtuv330ym"
          className="animable"
        />
        <path
          d="M380.91,275.18s5.24-19.73,5.59-21.65"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "383.705px 264.355px",
          }}
          id="elrsfnhlyhnvq"
          className="animable"
        />
        <path
          d="M427.83,263.45s-2.9.65-2.9,2.75"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "426.38px 264.825px",
          }}
          id="elcm34yzfleq"
          className="animable"
        />
        <path
          d="M378.56,263.77s3.23,2.1,2.91,5.33"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "380.026px 266.435px",
          }}
          id="el0yn8rhmmkhlc"
          className="animable"
        />
        <path
          d="M400.2,355c1.62,0,4.44-1.41,5.1-1.75.66.34,3.48,1.75,5.09,1.75,1.84,0,7.86.79,10.74.79s5-.79,2.1-2.62c-2.51-1.59-7.77-5.35-9.11-6.31-3.09,2.82-9.2,2.12-9.2,2.12a31.37,31.37,0,0,1-3.67-.78c-1.83-.53-4.45-1.57-4.45-1.57s-6.55,4.71-9.43,6.54-.78,2.62,2.1,2.62S398.37,355,400.2,355Z"
          style={{
            fill: "#2EB44C",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "405.304px 351.21px",
          }}
          id="el9tick0ky3cp"
          className="animable"
        />
        <line
          x1={404.92}
          y1={346.67}
          x2={404.92}
          y2={301.01}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "404.92px 323.84px",
          }}
          id="eljd3apt5qmis"
          className="animable"
        />
        <polyline
          points="405.3 353.2 404.92 348.93 404.92 346.67"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "405.11px 349.935px",
          }}
          id="elmlcve54k8gi"
          className="animable"
        />
        <line
          x1={401.25}
          y1={299.44}
          x2={408.84}
          y2={299.44}
          style={{
            fill: "none",
            stroke: "rgb(255, 255, 255)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "405.045px 299.44px",
          }}
          id="el9x20em1jwd8"
          className="animable"
        />
        <path
          d="M391.33,204.29s-5.44,3.26-4.63,6.12,3.27,3.41,2.73,5S387,218,389.15,219a18.36,18.36,0,0,0,3.82,1.23s2,6.94,5.44,8.16,9.4.41,13.62-.13a7.05,7.05,0,0,0,6.12-5.18c.41-1.49-1.77.28-2.86-1.36s.55-5.85,1.77-9.53-2.45-10.62-7.21-12.93S396,198.16,391.33,204.29Z"
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "402.409px 213.598px",
          }}
          id="el9y6dolvluu"
          className="animable"
        />
        <path
          d="M400.47,228a36.87,36.87,0,0,0-1.4,7c-.18,3,2.27,6.29,4.89,6.11s4.88-5.41,5.23-8-.87-9.6-.87-9.6-4,.18-5.59,1.57A9.68,9.68,0,0,0,400.47,228Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "404.157px 232.314px",
          }}
          id="elohvjlldlz1p"
          className="animable"
        />
        <path
          d="M396.92,208.1s.13,4.08,4.22,8a25.29,25.29,0,0,0,8.57,5.45,7.53,7.53,0,0,1-.68,2.58,1.89,1.89,0,0,1-1.9,1h-1.37s-2,4.69-4.42,5.37-8.37.21-9.19-5.78-.95-10.89-.27-12.12S396.92,208.1,396.92,208.1Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "400.576px 219.418px",
          }}
          id="elhhn7a9w9bfb"
          className="animable"
        />
        <path
          d="M439.71,260.8a11,11,0,0,0,1.11-4c0-1.82-3.38-4.43-4-5.08s-1.24-.58-1.37.33a3.74,3.74,0,0,0,1.17,2.73,3.06,3.06,0,0,1,1.05,1.57c.06.45-1.12,2.66-.53,4.09S439.39,262.3,439.71,260.8Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "438.135px 256.514px",
          }}
          id="elvo4cdxds28"
          className="animable"
        />
        <path
          d="M304.28,250.59S319,255.3,327.85,266.3s14.66,9.42,25.66,5.76,15.19-16.76,30.38-5.76,18.33,15.18,30.9,8.9,19.37-21.47,30.9-16.24,6.28,32,7.33,45-.53,45.57,2.09,49.76,29.33,1,29.33,1"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "3.14226px",
            transformOrigin: "394.36px 303.236px",
          }}
          id="el4kqocs11ida"
          className="animable"
        />
        <path
          d="M331.85,233.11s-2.93-4.78-7.25-3.53c-4.57,1.32-6.93,7.85-8,11.92s-1.79,8.47-3.75,12.26c0,0-8.66-.15-13.49-1.55a114.2,114.2,0,0,1-10.86-4s-1.71-2.39-2.92-2.86-4.35-1.39-4.58-.67,3.64,3.09,3.64,3.09-7.2,1-7.92,1.77,5.09,3.07,5.09,3.07,2.17.7,7.45-.56c0,0,19.37,12.3,23.23,13.71s6,1.86,8.63-3.22,11.36-17.68,12.75-22.75"
          style={{
            fill: "#2EB44C",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "305.264px 248.036px",
          }}
          id="el259aai80923"
          className="animable"
        />
        <path
          d="M293.53,250.25c-2.83-1.06-5-2-5-2s-1.71-2.39-2.92-2.86-4.35-1.39-4.58-.67,3.64,3.09,3.64,3.09-7.2,1-7.92,1.77,5.09,3.07,5.09,3.07,2.17.7,7.45-.56l2.71,1.71Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "285.109px 249.127px",
          }}
          id="elpvmlpebi8q"
          className="animable"
        />
        <path
          d="M368.19,262.64a11,11,0,0,1-1.11-4c0-1.82,3.39-4.42,4-5.07s1.23-.58,1.36.33a3.74,3.74,0,0,1-1.17,2.73,3.06,3.06,0,0,0-1.05,1.57c-.06.44,1.12,2.66.53,4.09S368.51,264.13,368.19,262.64Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "369.76px 258.359px",
          }}
          id="eln3z8cavvzrk"
          className="animable"
        />
        <path
          d="M370.52,257.15s-2.79,5.15-1.17,7,3.19-4.16,3.49-5.82S371.55,255,370.52,257.15Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "370.861px 260.316px",
          }}
          id="elnc7mpfx2x2h"
          className="animable"
        />
        <path
          d="M372.82,259s-2.79,5.15-1.17,7,3.19-4.16,3.49-5.82S373.85,256.79,372.82,259Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "373.161px 262.152px",
          }}
          id="elz21858k255q"
          className="animable"
        />
        <path
          d="M375.35,259.43s-2.79,5.15-1.17,7,3.19-4.16,3.49-5.82S376.38,257.27,375.35,259.43Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "375.691px 262.593px",
          }}
          id="elvmi24l15ep"
          className="animable"
        />
        <path
          d="M378.59,259.47s-2.6,4-1.18,5.51,2.95-3.24,3.25-4.61S379.55,257.67,378.59,259.47Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "378.843px 261.935px",
          }}
          id="elaadan4csio"
          className="animable"
        />
        <path
          d="M437.85,257.35s2.79,5.15,1.17,7-3.19-4.17-3.49-5.83S436.82,255.19,437.85,257.35Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "437.509px 260.512px",
          }}
          id="ell7j74lbuipk"
          className="animable"
        />
        <path
          d="M435.55,259.15s2.79,5.15,1.17,7-3.19-4.17-3.49-5.83S434.52,257,435.55,259.15Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "435.209px 262.314px",
          }}
          id="el25dfv0zauha"
          className="animable"
        />
        <path
          d="M433,259.63s2.79,5.15,1.17,7-3.19-4.16-3.48-5.83S432,257.47,433,259.63Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "432.666px 262.791px",
          }}
          id="eleumm2gybqf"
          className="animable"
        />
        <path
          d="M429.78,259.67s2.6,4.05,1.18,5.51-2.95-3.24-3.24-4.61S428.82,257.87,429.78,259.67Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "429.533px 262.128px",
          }}
          id="eltdkkmhh39gm"
          className="animable"
        />
        <path
          d="M85.72,316.65v5.68a18,18,0,0,0-4.13,7.58c-1.21,4.64-5,24.61-4,25.13S88,357.71,91,357.26c3.44-.52,6.37-.34,4.64-2.24s-10.82-7.21-10.82-7.21,10-14.46,12.22-19.8,2.58-13.6,2.58-13.6l-13.42-.51Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "88.5219px 335.611px",
          }}
          id="ell5hbwyvgdx9"
          className="animable"
        />
        <path
          d="M91,357.26c3.44-.52,6.37-.34,4.64-2.24s-10.82-7.21-10.82-7.21l-.34,2.06-6.55-.8c-.48,3.34-.69,5.79-.34,6C78.66,355.55,88,357.71,91,357.26Z"
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "86.784px 352.566px",
          }}
          id="el1ml7aumicdx"
          className="animable"
        />
        <path
          d="M118.6,319.59l1.55,2.76a10.07,10.07,0,0,0-.52,8.09c1.55,4.82,5.17,22.55,5.17,23.58s2.23,1.72,4,1.72,6.67.34,7.36.34,5.68.86,7.4-.17-2.19-3.1-4.77-4.3-8.26-4.3-8.26-4.3,1.37-14.12,1.37-20.32,1.38-8.26-.51-12.05-13.77,1.73-13.77,1.73Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "130.802px 335.014px",
          }}
          id="el3k1kvaa7f0a"
          className="animable"
        />
        <path
          d="M138.74,351.61c-2.58-1.21-8.26-4.3-8.26-4.3l-.69,2.58-5.53,1c.33,1.72.54,2.9.54,3.14,0,1,2.23,1.72,4,1.72s6.67.34,7.36.34,5.68.86,7.4-.17S141.32,352.81,138.74,351.61Z"
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "134.12px 351.868px",
          }}
          id="el5d6x8hx2qgj"
          className="animable"
        />
        <path
          d="M109.33,205a47.36,47.36,0,0,0-4.61-6.54c-2.37-2.67-9.8-2.52-13.06,1s-2.23,9.8-1,11.58,3.71,10.1,3.71,10.1l.59,5.94,13.81,4.9-2.82-9.65s5.35-1,5.79-2.67-.89-7.28-.89-7.28,1.78-1.19,1.63-2.23S109.33,205,109.33,205Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "100.983px 214.299px",
          }}
          id="elvg8r7h4ifck"
          className="animable"
        />
        <path
          d="M93.44,211.4s2.36,2,3.65-.29,2.27-4.53,2.27-6.47-4.85-3.55-1.62-4.85,5.56,1.59,7-1.31.13-3.21-7.63-3.21-11.64,3.88-10,8.73a65.31,65.31,0,0,0,4.85,10.34Z"
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "95.9956px 204.805px",
          }}
          id="el44h0t0a7cpr"
          className="animable"
        />
        <path
          d="M109.77,208.43a3.71,3.71,0,1,1-3.71-3.71A3.71,3.71,0,0,1,109.77,208.43Z"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "106.06px 208.43px",
          }}
          id="ela3lcdlz3x8q"
          className="animable"
        />
        <path
          d="M109.23,205.14a3.62,3.62,0,0,1,1.36-.27,3.49,3.49,0,0,1,1.69,6.54"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "111.652px 208.14px",
          }}
          id="elo54xtwxvn"
          className="animable"
        />
        <line
          x1={102.35}
          y1={208.43}
          x2={93.44}
          y2={211.4}
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "97.895px 209.915px",
          }}
          id="el36j8m41g4s1"
          className="animable"
        />
        <path
          d="M96,213.33s-1.78-3.71-3.56-3-.89,6.54.89,7.13a3.84,3.84,0,0,0,3.41-.89s1.49,3.12,3.27,4"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "95.7399px 215.405px",
          }}
          id="el4wltob2fnb6"
          className="animable"
        />
        <path
          d="M84.32,280l-1.11,14.22-3.32,21.05s7.76,6.09,15,6.09,9.23-.92,9.23-.92l4.06-21.6,4.06,22.71s8-1.29,13.3-2.59a35.08,35.08,0,0,0,10.34-4.8l-10.71-27.88-2.32-14.38s-12,1.11-19.21,1.48-16.8-1.48-16.8-1.48Z"
          style={{
            fill: "#2EB44C",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "107.885px 296.725px",
          }}
          id="el5p57hkzpk1m"
          className="animable"
        />
        <path
          d="M91.93,223.18s17,5.93,22.25,11.69c2.9,3.2,6.15,25.1,7.32,30.45s3.21,16,3.21,16-10.37,4.68-18.39,4.85-23.41-3.51-23.41-3.51,1.83-23.73,1.83-34.76.17-18.23,2-21.74C86.75,226.19,89.76,222.84,91.93,223.18Z"
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "103.81px 254.666px",
          }}
          id="elkm29tb957cl"
          className="animable"
        />
        <path
          d="M87.46,284.38s.92,15.51,0,21.24a44.06,44.06,0,0,1-3.32,10.71"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "86.0044px 300.355px",
          }}
          id="elzy0qk6u2kj"
          className="animable"
        />
        <path
          d="M87.83,291.77a8.47,8.47,0,0,0,5.54-6.28"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "90.6px 288.63px",
          }}
          id="el0e6im6i1lbqd"
          className="animable"
        />
        <path
          d="M80.81,312.27s9.24,7.94,23.64,5"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "92.63px 315.097px",
          }}
          id="elh830arylkw"
          className="animable"
        />
        <path
          d="M111.84,316.88s15.14-1.66,21.79-6.09"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "122.735px 313.835px",
          }}
          id="elf2pzxe8i2e"
          className="animable"
        />
        <path
          d="M179.56,216s-1.46,1.08-6.55,4.8-1.08,9.34-1.08,9.34-3.64.57-8.5,5.68,2.17,13.88,8.74,15.25,14.25-6.76,16.55-14.3-.74-18.91-2.27-21.26S179.56,216,179.56,216Z"
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "175.682px 232.901px",
          }}
          id="el60rowjy4zub"
          className="animable"
        />
        <path
          d="M198.84,215s2.53-3.91.24-9.58-9.13-8.26-15.79-4.15A11.73,11.73,0,0,0,178,214.54c.65,2.62,3.93,6.65,4.62,6.71s6.68,2.24,10.77.33S198.84,215,198.84,215Z"
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "188.799px 210.811px",
          }}
          id="el7gk5uprualx"
          className="animable"
        />
        <path
          d="M169.41,347s4.75,7.39,13,8.65.83-6.14-.56-7.26S170.53,344.85,169.41,347Z"
          style={{
            fill: "rgb(115, 115, 115)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "177.827px 350.967px",
          }}
          id="ellre27i70ut"
          className="animable"
        />
        <path
          d="M213.36,348.9s9.07,2.23,8.37,4.19-8.09,1.81-12.41.69-8.38-2.79-7.82-4.6S213.36,348.9,213.36,348.9Z"
          style={{
            fill: "rgb(115, 115, 115)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "211.608px 351.447px",
          }}
          id="ell71nt6s7ig"
          className="animable"
        />
        <path
          d="M187.57,229.7s-12.14,1.78-14.36,6.07.91,27.66.91,27.66-5.18,11.43-5.25,18.79,3.21,20.31,2.95,23.54-3.58,40-3.58,41,7,3.24,17.09,4.44,29.39-2.39,29.39-2.39-.38-21.7.63-34.64-.43-24.57-3-29.4-14.54-22.22-14.54-22.22,5.8-10.52,6.16-15.12-1.51-7-5.89-12.29A15.27,15.27,0,0,0,187.57,229.7Z"
          style={{
            fill: "#2EB44C",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "191.957px 290.573px",
          }}
          id="el8dxp6ww3kxe"
          className="animable"
        />
        <path
          d="M197.84,262.56s5.8-10.52,6.16-15.12-1.51-7-5.89-12.29c-.21-.26-.43-.49-.65-.73l-.16.15c-4.5,7.35-19.38,19.91-23.81,23.57.34,3.13.63,5.29.63,5.29s-5.18,11.43-5.25,18.79a67.11,67.11,0,0,0,1,10c10.51-6,24.57-17.23,32.18-23.49C199.67,265.21,197.84,262.56,197.84,262.56Z"
          style={{
            fill: "rgb(115, 115, 115)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "186.457px 263.32px",
          }}
          id="eliwxps0rpl3p"
          className="animable"
        />
        <path
          d="M185.33,221.12a75.07,75.07,0,0,1,.55,8.27c-.16,2.05.5,6.73,4,8.2s5.7-.58,5.69-2.64a20.8,20.8,0,0,0-1-5l.1-5.65S188.63,220.52,185.33,221.12Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "190.45px 229.572px",
          }}
          id="elvwzrlabzx2b"
          className="animable"
        />
        <path
          d="M182.19,217.45s-2.57-2.26-2.65,1,2.92,4.34,3.58,4.56a2.21,2.21,0,0,0,1.58-.39s4.66,4,8.4,4.26,5.26-1.47,6.14-8.26-6.13-13.68-6.13-13.68S187.11,216,182.19,217.45Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "189.427px 215.922px",
          }}
          id="el3ndd4krig5c"
          className="animable"
        />
        <path
          d="M125,281.18a9,9,0,0,1,.56-3.4c.62-1.36,4-2.14,4.75-2.4s1.13,0,.91.71a3.1,3.1,0,0,1-1.81,1.64,2.54,2.54,0,0,0-1.33.81c-.19.32-.07,2.37-1,3.24S124.69,282.41,125,281.18Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "128.12px 278.79px",
          }}
          id="elnzgmero6que"
          className="animable"
        />
        <path
          d="M201.64,251.11s-12-1.57-17.29,6.81-15.18,37.7-33.51,42.42-23.57-33-34.57-31.95c-10.48,1-17.8,0-24.09,11.52-3.36,6.17-20.16,73.54-28.1,74.23-25.29,2.21-41.57.77-41.57.77"
          style={{
            fill: "none",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "3.14226px",
            transformOrigin: "112.075px 303.182px",
          }}
          id="elbeho7b75waa"
          className="animable"
        />
        <path
          d="M92.71,223.66s-2.77-1.11-6.47,1.1-13.66,26.41-12.92,29,11.26,7.39,17.17,9.05,16.62,3.7,16.62,3.7,3.32,5.17,4.43,5.35a24.06,24.06,0,0,0,4.8-.55s2,1.66,2.77-.19-.74-4.24-2.4-6.09-8.68-2.77-8.68-2.77L89,249.14,97,234.37"
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "96.2988px 247.688px",
          }}
          id="elc8ysphryx5"
          className="animable"
        />
        <path
          d="M111.54,271.85a24.06,24.06,0,0,0,4.8-.55s2,1.66,2.77-.19-.74-4.24-2.4-6.09-8.68-2.77-8.68-2.77L89,249.14l2.84-5.29c-7.11-4.43-11.83-4.1-14.38-3.21-2.55,6.12-4.49,11.92-4.15,13.11.74,2.59,11.26,7.39,17.17,9.05s16.62,3.7,16.62,3.7S110.43,271.67,111.54,271.85Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "96.294px 256.038px",
          }}
          id="elub7omd9ym8"
          className="animable"
        />
        <path
          d="M230.49,251.52c-.81-8.73-5-15.9-10-18.75l-.16-1.78-13.06,1.21c-7.35.68-12.42,10.77-11.33,22.53s7.93,20.73,15.28,20l13.06-1.21-.17-1.78C228.51,268.06,231.3,260.25,230.49,251.52Z"
          style={{
            fill: "rgb(38, 50, 56)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "213.211px 252.881px",
          }}
          id="elqqtefbopft"
          className="animable"
        />
        <path
          d="M236.4,251c-.85-9.17-5-16.72-10.29-19.72l-.18-1.87-5.59.52c-7.72.72-13.05,11.31-11.91,23.67s8.34,21.79,16.06,21.07l5.59-.52-.17-1.87C234.53,268.34,237.25,260.14,236.4,251Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "222.416px 252.059px",
          }}
          id="elkm57twxn3pg"
          className="animable"
        />
        <path
          d="M242,250.45c1.15,12.36-4.18,22.95-11.91,23.67S215.17,265.4,214,253.05s4.18-23,11.9-23.67S240.84,238.1,242,250.45Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "227.998px 251.752px",
          }}
          id="elvtigl8z8p6"
          className="animable"
        />
        <path
          d="M248,243.23l-21.69,2a2.32,2.32,0,0,1-2.53-2.1h0a2.32,2.32,0,0,1,2.1-2.53l21.69-2a2.32,2.32,0,0,1,2.53,2.1h0A2.32,2.32,0,0,1,248,243.23Z"
          style={{
            fill: "rgb(115, 115, 115)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "236.94px 241.915px",
          }}
          id="elvnjymsfy3te"
          className="animable"
        />
        <path
          d="M249.61,260.42l-21.69,2a2.32,2.32,0,0,1-2.53-2.1h0a2.32,2.32,0,0,1,2.1-2.53l21.69-2a2.34,2.34,0,0,1,2.53,2.1h0A2.32,2.32,0,0,1,249.61,260.42Z"
          style={{
            fill: "rgb(115, 115, 115)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "238.55px 259.106px",
          }}
          id="el39z4nwx5k95"
          className="animable"
        />
        <path
          d="M180.07,231.33s-5.22,1-7.69,4-7.54,26.15-6.37,28.81,19.92,6,25.71,6.48a83.17,83.17,0,0,0,13.94-.62s5-.13,6.37-.36,3.89-1.58,3.93-2.09-.21-1.73-2.08-1.88-5.88-1.49-6.39-1.53a32.6,32.6,0,0,1,3.65-.74s.26-1-1.8-1-3.93-.14-4.71,1a17.2,17.2,0,0,0-1.42,2.8s-11.55.81-17.82-2.25-8.25-4.08-8.25-4.08l4.5-18"
          style={{
            fill: "#2EB44C",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "190.901px 251.008px",
          }}
          id="el8o9l0a1hyv8"
          className="animable"
        />
        <path
          d="M212,269.67c1.39-.23,3.89-1.58,3.93-2.09s-.21-1.73-2.08-1.88-5.88-1.49-6.39-1.53a32.6,32.6,0,0,1,3.65-.74s.26-1-1.8-1-3.93-.14-4.71,1a17.2,17.2,0,0,0-1.42,2.8s-11.55.81-17.82-2.25-8.25-4.08-8.25-4.08l2.74-11a39.2,39.2,0,0,0-11.17-2.77c-1.87,7.54-3.4,16.42-2.7,18,1.16,2.66,19.92,6,25.71,6.48a83.17,83.17,0,0,0,13.94-.62S210.64,269.9,212,269.67Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "190.87px 258.403px",
          }}
          id="elf0zyfyrrons"
          className="animable"
        />
        <path
          d="M127.54,279.24s-3.86,2.89-3.27,4.8,3.81-2,4.61-3.15S129.05,278,127.54,279.24Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "126.719px 281.685px",
          }}
          id="elmpq7hlyxuwp"
          className="animable"
        />
        <path
          d="M128.3,281.83s-3.85,2.89-3.27,4.8,3.82-2,4.61-3.15S129.81,280.57,128.3,281.83Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "127.477px 284.271px",
          }}
          id="elo3ey77ycc8"
          className="animable"
        />
        <path
          d="M130.13,283.51s-3.85,2.89-3.26,4.8,3.81-2,4.61-3.15S131.65,282.25,130.13,283.51Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "129.319px 285.951px",
          }}
          id="elkuk88p1tu3"
          className="animable"
        />
        <path
          d="M131.64,284.87s-3.33,2.14-2.77,3.72,3.31-1.41,4-2.33S133,283.86,131.64,284.87Z"
          style={{
            fill: "rgb(255, 255, 255)",
            stroke: "rgb(38, 50, 56)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.04742px",
            transformOrigin: "130.986px 286.787px",
          }}
          id="eljqhivmvhz4n"
          className="animable"
        />
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />
          <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="hover" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />
          <feFlood floodColor="#ff0000" floodOpacity={0.5} result="PINK" />
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
          <feColorMatrix
            type="matrix"
            values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
          />
        </filter>
      </defs>
    </svg>
  );
};

export default PageNotFoundSVG;
