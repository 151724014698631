import { NavLink } from "react-router-dom";
import essenrfid_logo from "../../assets/images/essenrfid_logo.png";
import xtenna_logo from "../../assets/images/xtenna_logo.png";

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <footer className="bg-white border-t-2 w-full md:py-16 py-8 text-white mt-16">
      <div className="flex md:flex-row flex-col md:justify-around justify-center items-center md:items-start max-w-7xl m-auto gap-4">
        <div className="md:hidden flex justify-between items-center w-full px-4">
          <a href="https://www.essenrfid.com/" target="_blank">
            <img src={essenrfid_logo} alt="" className="w-24 object-contain" />
          </a>
          <a href="https://www.essenrfid.com/" target="_blank">
            <img src={xtenna_logo} alt="" className="w-28 object-contain" />
          </a>
        </div>
        <a href="https://www.essenrfid.com/" target="_blank">
          <img
            src={essenrfid_logo}
            alt=""
            className="w-24 object-contain hidden md:block"
          />
        </a>
        <div className="flex flex-col justify-between items-center gap-4">
          <a
            className="text-gray-500"
            href="https://www.essenrfid.com/"
            target="_blank"
          >
            www.essenrfid.com
          </a>
          {/* <div className="flex flex-row justify-between items-center w-full">
            <a className="text-gray-500" href="#">
              Privacy Policy
            </a>
            <a className="text-gray-500" href="#">
              Terms of Service
            </a>
          </div> */}
          <div className="text-gray-500 px-5 text-center">
            © 2024 <span className="text-geovetraGreen-500">Geovetra</span>. All
            rights reserved.
          </div>
          <div className="text-gray-500 px-5 text-center text-[10px]">
            <NavLink to="/terms-and-conditions" className="underline">
              Terms & Conditions
            </NavLink>{" "}
            and{" "}
            <NavLink to="/privacy-policy" className="underline">
              Privacy policy
            </NavLink>
          </div>
        </div>
        <a href="https://www.essenrfid.com/" target="_blank">
          <img
            src={xtenna_logo}
            alt=""
            className="w-28 object-contain hidden md:block"
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
