export interface FormatedDate {
  date: string;
  time: string;
  timestamp: string;
  input?: string;
  input_datetime?: string;
  diff?: string;
}

interface TimeStamps {
  created_at?: FormatedDate;
  updated_at?: FormatedDate;
}

interface SoftDeletes {
  deleted_at?: FormatedDate;
}

interface BelongsToCompany {
  company_id: string;
  company?: ICompany;
}

interface HasCreatedBy {
  created_by_user_id: string;
  created_by_user: IUser;
}

export interface IStatus {
  key: string;
  color: string;
  icon: string;
}

export interface IAddress {
  id?: string;
  name?: string;
  address_line_1?: string;
  address_line_2?: string;
  country?: ICountry;
  state?: IState;
  city?: ICity;
  pincode?: IPincode;
}

export enum AllowedCompanyType {
  PRIVATE_LIMITED_COMPANY = "Private Limited Company",
  PUBLIC_LIMITED_COMPANY = "Public Limited Company",
  LIMITED_LIABILITY_PARTNERSHIP = "Limited Liability Partnership",
  ONE_PERSON_COMPANY = "One Person Company",
  SOLE_PROPRIETORSHIP = "Sole Proprietorship",
  SECTION_8_COMPANY = "Section 8 Company",
}

export interface ICompany extends TimeStamps {
  id?: string;

  name: string;
  domain: string;
  gstin: string;

  welcome_at?: boolean;
  challan_fields?: any;

  api_key?: string;

  plan_id?: string;
  plan_details: IPlan;

  // registered_office_location_id: string
  // registered_office_location: ILocation

  // location_details: ILocation

  address_line_1: string;
  address_line_2: string;
  country_id?: string;
  state_id?: string;
  city_id?: string;
  pincode_id?: string;

  country?: ICountry;
  state?: IState;
  city?: ICity;
  pincode?: IPincode;

  location_details: ILocation;

  type_of_company: AllowedCompanyType;

  created_by_user_id: string;
  phone_number: string;
  logo?: string;
}

export interface ILocation extends TimeStamps, SoftDeletes {
  id?: string;

  name: string;
  address_line_1: string;
  address_line_2: string;
  country_id?: string;
  state_id?: string;
  city_id?: string;
  pincode_id?: string;

  fetched_location_name?: string;
  coordinates?: any;
  geofence?: number;
  area?: any;
  meta_data?: any;

  country?: ICountry;
  state?: IState;
  city?: ICity;
  pincode?: IPincode;
}

export interface ICountry extends TimeStamps {
  id?: string;

  name: string;
  code?: string;
}

export interface IState extends TimeStamps {
  id?: string;

  name: string;
  code?: string;
  country_id?: string;
}

export interface ICity extends TimeStamps {
  id?: string;
  name: string;
  country_id?: string;
  state_id?: string;
}

export interface IPincode extends TimeStamps {
  id?: string;

  name: string;
  code?: string;
  country_id?: string;
  state_id?: string;
  city_id?: string;

  country?: ICountry;
  state?: IState;
  city?: ICity;
}

export interface IUser extends TimeStamps, SoftDeletes {
  id?: string;
  name: string;
  email: string;
  phone_number_country_code?: string;
  phone_number?: string;

  email_verified_at?: FormatedDate;
  password?: string;
  active?: boolean;

  deactivated_at?: FormatedDate;

  companies?: ICompany[];
}

export interface IRole extends TimeStamps {
  id?: string;
  name: "Admin" | "Manager" | "Operator" | "Management";
}

export interface IUserLocation extends TimeStamps {
  id?: string;
  user_id: string;
  location_id: string;

  user?: IUser;
  location?: ILocation;
}

export interface IUserAddress extends TimeStamps {
  id?: string;
  name: string;
  user_id: string;

  address_line_1: string;
  address_line_2: string;
  country_id?: string;
  state_id?: string;
  city_id?: string;
  pincode_id?: string;

  country?: ICountry;
  state?: IState;
  city?: ICity;
  pincode?: IPincode;
}

export interface ICompanyUser extends TimeStamps, BelongsToCompany {
  plan_details: any;
  id?: string;
  user_id: string;
  role_id: string;
  user?: IUser;
  role?: IRole;
  invited_by?: string;
  invitation_accepted_at: any;
}

export interface IWarehouse
  extends TimeStamps,
    SoftDeletes,
    BelongsToCompany,
    HasCreatedBy {
  id?: string;
  name: string;

  location_id: string;
  incharge_name?: string;
  incharge_number?: string;

  deactivated_at: FormatedDate;

  location_details?: ILocation;
}

export interface IDriver
  extends TimeStamps,
    SoftDeletes,
    BelongsToCompany,
    HasCreatedBy {
  id?: string;
  name: string;
  phone?: string;
  driving_license_number: string;
  driving_license_expire_at: FormatedDate;
  insurance_expire_at?: FormatedDate;
  date_of_birth: FormatedDate;

  residential_location_id: string;

  contractor_id?: string;

  deactivated_at?: FormatedDate;

  contractor?: IContractor;
}

export interface IVehicle
  extends TimeStamps,
    SoftDeletes,
    BelongsToCompany,
    HasCreatedBy {
  id?: string;

  name: string;
  license_plate_number: string;
  contractor_id: string;
  type_of_vehicle: string;
  puc_expire_at: FormatedDate;
  deactivated_at: FormatedDate;

  contractor?: IContractor;
}

export interface ILov<T = string> {
  label: string;
  value: T;
}
export interface IContractor
  extends TimeStamps,
    SoftDeletes,
    BelongsToCompany,
    HasCreatedBy {
  id?: string;

  name: string;
  phone_number_country_code: string;
  phone_number: string;
  email: string;
  location_id: string;

  location?: ILocation;
}

export interface ICustomer
  extends TimeStamps,
    SoftDeletes,
    BelongsToCompany,
    HasCreatedBy {
  id?: string;

  name: string;
  phone_number_country_code: string;
  phone_number: string;
  email: string;
  location_id: string;

  location?: ILocation;
}

export interface IRouteResponse {
  code: "OK";
  routes: Array<{
    distance: number;
    duration: number;
    geometry: {
      coordinates: any;
      type: "LineString";
    };
    legs: any;
    weight: number;
    weight_name: string;
  }>;
  waypoints: Array<{
    distance: number;
    hint: string;
    location: [0, 1];
    name: string;
  }>;
}

export enum AllowedTripStatus {
  TRIP_ENDED = "trip_ended",
  TRIP_TERMINATED = "trip_terminated",
  TRIP_EXPIRED = "trip_expired",
  NO_DELIVERIES_FOUND = "no_deliveries_found",
  SUBSCRIPTION_NOT_SELECTED = "subscription_not_selected",
  VEHICLE_NOT_SELECTED = "vehicle_not_selected",
  DRIVER_NOT_SELECTED = "driver_not_selected",
  WAREHOUSE_NOT_SELECTED = "warehouse_not_selected",
  MISSING_COORDINATES = "missing_coordinates",
  NO_TRACKING_ROUTE = "no_tracking_route",
  PENDING_AUTOSTART = "pending_auto_start",
  NOT_AT_WAREHOUSE = "not_at_warehouse",
  READY_TO_START = "ready_to_start",
  ONGOING_DELIVERIES = "on_going_deliveries",
}

export interface ITrip
  extends TimeStamps,
    SoftDeletes,
    BelongsToCompany,
    HasCreatedBy {
  id?: string;

  trip_type: string;
  scheduled_start_at?: FormatedDate;
  scheduled_end_at?: FormatedDate;
  actual_start_at?: FormatedDate;
  actual_end_at?: FormatedDate;
  at_warehouse: boolean;
  terminated_at?: string;
  pin?: number;

  auto_sequence?: boolean;
  estimated_route?: IRouteResponse;
  projected_route?: IRouteResponse;

  projected_route_details?: IRouteType;
  estimated_route_details?: IRouteType;

  track_line?: string;

  time_delay?: number;
  distance_travelled?: number;
  tat?: number;

  warehouse?: IWarehouse;
  vehicle?: IVehicle;
  drivers?: IDriver[];
  subscription?: ISubscription;

  status: AllowedTripStatus;
  autostart: boolean;

  deliveries?: IDelivery[];
}

export interface IRouteType {
  geometry: {
    coordinates: any;
    type: "LineString";
  };
  distance: string;
  duration: string;
  duration_in_secs: number;
}

export enum AllowedDeliveryStatus {
  CUSTOMER_NOTIFIED = "custom_notified",
  PENDING = "pending",
  MISSING_COORDINATES = "missing_coordinates",
  MISSED = "missed",
  ONGOING = "on_going",
  DELIVERIED = "delivered",
  CANCELLED = "cancelled",
  RETURNED = "returned",
  TERMINATED = "terminated",
}

export interface IDelivery
  extends TimeStamps,
    SoftDeletes,
    BelongsToCompany,
    HasCreatedBy {
  id?: string;

  trip_id?: string;
  original_id?: string;

  delivery_location_details: ILocation;

  recipient_customer_id: string;
  pickup_location_id: string;
  delivery_location_id: string;

  requested_delivery_date?: FormatedDate;
  estimated_delivery_date?: FormatedDate;
  actual_delivery_date?: FormatedDate;

  delivery_status: AllowedDeliveryStatus;

  delivery_notes: string;
  delivery_attempts: number;

  delivery_type: string;

  payment_to_collected_in_rupees?: number;

  package_length_centimeters: number;
  package_width_centimeters: number;
  package_height_centimeters: number;
  package_weight_in_grams: number;

  pod_signature_required: boolean;

  insurance_amount?: number;

  fragile: boolean;
  perishable: boolean;
  hazardous: boolean;

  packaging: string;

  customs_regulations: boolean;
  return_policy?: string;
  payment_options?: string;

  enter_extreme_geofence_at?: FormatedDate;
  enter_geofence_at?: FormatedDate;
  exit_geofence_at?: FormatedDate;
  exit_extreme_geofence_at?: FormatedDate;

  sort_index?: number;

  pod_otp_hash?: string;
  pod_otp_at?: FormatedDate;

  cancellation_reason?: string;

  tat: number;

  challan_fields?: string;

  recipient_customer?: ICustomer;
  delivery_location?: ILocation;
  pickup_location?: ILocation;
}

export enum AllowedSubscriptionType {
  DEVICE = "device",
  LICENSE = "license",
}

export interface ISubscriptionRecharge extends TimeStamps {
  recharge: any;
  id: string;
  subscription_id: string;
  recharge_id: string;
  is_expired: boolean;
  days_left: number;
  start_date: FormatedDate;
  end_date: FormatedDate;
}

export interface ISubscription extends TimeStamps {
  value: any;
  id?: string;

  subscription_number: string;
  type: AllowedSubscriptionType;
  purchase_id: string;

  active_device?: IDevice;
  active_sim?: ISim;

  last_track?: ITrack;
  orderId?: string;
  subscription_recharges: ISubscriptionRecharge[];
  last_active_recharge: ILastActiveRecharge;
}
export interface ILastActiveRecharge extends TimeStamps {
  start_date?: FormatedDate;
  id?: string;
  days_left: number;
}

export interface IPurchase extends TimeStamps, BelongsToCompany {
  id?: string;

  order_id: string;

  type_of_purchase: string;
  cost_per_unit: number;
  number_of_units: number;

  total_purchase_cost: number;
}

export interface ISim extends TimeStamps {
  id?: string;

  number: string;
  registered_via: string;
  provider?: string;
  plan_expire_at?: string;
}

export interface IDevice extends TimeStamps {
  id?: string;

  imei: string;
  registered_via: string;
  remaining_battery: number;
  charging_status: boolean;
}

export interface ISimSubscription extends TimeStamps {
  id?: string;

  subscription_id: string;
  sim_id: string;
  active: boolean;

  sim?: ISim;
}

export interface IDeviceSubscription extends TimeStamps {
  id?: string;

  subscription_id: string;
  device_id: string;
  active: boolean;

  device?: IDevice;
}

export interface ITrack extends TimeStamps {
  id?: string;

  subscription_id: string;
  device_payload: any;
  location: any;
  location_details: ILocation;
  status: string;
  tim: any;

  // subscription?: ISubscription
}

export interface IPlan extends TimeStamps {
  id?: string;
  title: string;
  deliveries_per_day: number;
  cost_per_day: number;
  active_manager_accounts: number;
  active_operator_accounts: number;
  active_management_accounts: number;
  active_warehouse_count: number;
  active_drivers_count: number;
  active_vehicles_count: number;
  max_per_delivery_distance_in_meter: number;
  device_refresh_interval_in_seconds: number;
  reports_and_analysis: any;
  challan_creation: any;
  custom_fields_count: number;
  map_tiles_url: any;
  map_search_engine_api: any;
  map_route_api: any;
}
export interface ICoupon extends TimeStamps {
  id: string;
  name: string;
  discount_percentage: number;
  start_at: FormatedDate;
  expire_at: FormatedDate;
}
export interface ICheckoutOrder {
  number_of_devices: number;
  number_of_licenses: number;
  number_of_days: number;
  plan_id: string;
  plan?: IPlan;
  coupon?: ICoupon;
  //   type: string;
  shipping_address_details?: IUserAddress;
  billing_address_details?: IUserAddress;
}

export enum AllowedOrderStatus {
  PENDING = "pending",
  PAID = "paid",
  CANCELLED = "cancelled",
  FULLFILLED = "fullfilled",
}
export interface IOrder extends TimeStamps, BelongsToCompany, HasCreatedBy {
  id?: string;
  plan_id: number;
  order_total_amount: number;

  coupon_id?: string;

  discount_amount: number;
  discount_percentage: number;

  net_amount: number;

  tax_amount: number;
  tax_percentage: number;

  grand_total: number;

  status: AllowedOrderStatus;
  type: string;
  // user_id: string
  // shipping_address: IAddress
  // billing_address: IAddress

  shipping_address_details: IUserAddress;
  billing_address_details: IUserAddress;

  // user?: IUser

  purchases?: IPurchase[];
  recharges?: IRecharge[];

  payments?: IPayment[];

  total: {
    gst: number;
    purchase: number;
    recharge: number;
    total: number;
  };
  plan_details: IPlan;
  length: number;
}
export interface IRecharge extends TimeStamps {
  id?: string;

  order_id: string;
  purchase_id?: string;
  number_of_subscriptions: number;
  cost_per_day: number;
  number_of_days: number;
  cancelled_at?: FormatedDate;
  recharge_total: number;

  order?: IOrder;
  purchase?: IPurchase;
}

export enum AllowedPaymentStatus {
  PENDING = "pending",
  PAID = "paid",
  CANCELLED = "cancelled",
}
export interface IPayment extends TimeStamps {
  id?: string;

  user_id: string;
  order_id: string;
  order_ref_id: string;
  order_random_id: string;
  amount: string;
  gateway: string;
  status: AllowedPaymentStatus;
}

export interface IJob {
  id?: string;
}

export interface ISystemConfig {
  id?: string;
  config_key: string;
  config_value: string;
}

export interface IApiCache {
  id?: string;
}

export interface ResponseModel<M> {
  data: M;
}

export interface ResponsePaginated<M> {
  data: M[];
  links: PaginationLinks;
  meta: PaginationMeta;
}

export interface PaginationLinks {
  first: string;
  last: string;
  next?: string;
  prev?: string;
}

export interface PaginationMeta {
  total: number;
  current_page: number;
  path: string;
  per_page: number;
  last_page: number;
  from: number;
  to: number;
  links: PaginationMetaLinks[];
}

export interface PaginationMetaLinks {
  active: boolean;
  label: string;
  url: string;
}

export interface ResponseModel<M> {
  data: M;
}

export interface ResponsePaginated<M> {
  data: M[];
  links: PaginationLinks;
  meta: PaginationMeta;
}

export interface PaginationLinks {
  first: string;
  last: string;
  next?: string;
  prev?: string;
}

export interface PaginationMeta {
  total: number;
  current_page: number;
  path: string;
  per_page: number;
  last_page: number;
  from: number;
  to: number;
  links: PaginationMetaLinks[];
}

export interface PaginationMetaLinks {
  active: boolean;
  label: string;
  url: string;
}

export const RECORDS_PER_PAGE = [10, 20, 50];

export type FormMode =
  | "create"
  | "read"
  | "update"
  | "loading"
  | "access_denied"
  | "error";
