import { SetStateAction, useState } from "react";

interface FAQSectionProps {}

const FAQSection: React.FC<FAQSectionProps> = () => {
  const accordianData = [
    {
      title: "What is Geovetra?",
      description:
        "Geovetra seamlessly integrates with your current dispatch and logistics systems, making adoption swift and hassle-free. Experience the power of comprehensive tracking without disrupting your workflow.",
    },
    {
      title: "What is Delivery Logistics Efficiency?",
      description:
        "Lowering the cost of logistics operations & Hawkeye visibility of deliveries is the key to Happier Customer Deliveries",
    },
    {
      title: "What is Your Optimize Delivery Network?",
      description:
        "Geovetra offers unparalleled tracking capabilities, so you always know where your drivers are and can anticipate delivery times accurately.",
    },
    {
      title: "What is Seamless Integration with Existing Systems?",
      description:
        "Geovetra seamlessly integrates with your current dispatch and logistics systems, making adoption swift and hassle-free. Experience the power of comprehensive tracking without disrupting your workflow.",
    },
    {
      title: "How It Works?",
      description:
        "Geovetra simplifies your delivery management through four straightforward steps, maximizing efficiency and transparency.",
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index: any) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="md:mt-16 mt-10 text-center max-w-7xl px-4 w-full">
      <div className="flex flex-col items-center">
        <p className="text-2xl md:text-3xl w-fit font-semibold text-black mb-2 bg-white p-0">
          Frequently Asked Questions
        </p>
        <p className="text-sm md:text-xl w-fit font-medium text-gray-500 mb-4 bg-white p-0">
          In case you have more questions, feel free to reach out to us.
        </p>
      </div>

      <div className="">
        {accordianData.map((data, index) => {
          return (
            <div
              id="accordion-collapse"
              data-accordion="collapse"
              className="md:px-20"
              key={index}
            >
              <h2
                id={`accordion-collapse-heading-${index}`}
                className="bg-white"
              >
                <button
                  type="button"
                  className={`flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border rounded-lg ${
                    openIndex === index ? "border-b-0" : "border-b-1"
                  } border-r-0 border-l-0 border-t-0 border-gray-200 hover:bg-geovetraGreen-100 gap-3`}
                  //   data-accordion-target="#accordion-collapse-body-1"
                  //   aria-expanded="true"
                  //   aria-controls="accordion-collapse-body-1"
                  onClick={() => toggleAccordion(index)}
                  aria-expanded={openIndex === index}
                  aria-controls={`accordion-collapse-body-${index}`}
                >
                  <span className="text-gray-900 font-medium text-start md:text-lg text-sm">
                    {data.title}
                  </span>
                  <svg
                    data-accordion-icon
                    className={`w-3 h-3 ${
                      openIndex === index ? "" : "rotate-180"
                    } shrink-0`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5 5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                // id="accordion-collapse-body-1"
                // className="hidden"
                // aria-labelledby="accordion-collapse-heading-1"
                id={`accordion-collapse-body-${index}`}
                className={`${openIndex === index ? "" : "hidden"}`}
                aria-labelledby={`accordion-collapse-heading-${index}`}
              >
                <div className="text-sm md:text-base w-fit font-medium text-gray-500 p-5 borderp-b-1 border border-r-0 border-l-0 border-t-0 border-gray-200 bg-white text-start">
                  {data.description}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FAQSection;
