const compressImage = async (fileY) => {
  const file = fileY; // get the file
  const img = await createImageBitmap(file);
  const MAX_WIDTH = 180;
  const MAX_HEIGHT = 180;
  const MIME_TYPE = "image/jpeg";
  const QUALITY = 0.7;
  const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
  const canvas = document.createElement("canvas");
  canvas.width = newWidth;
  canvas.height = newHeight;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, newWidth, newHeight);
  // Turn into Blob
  const blob = await new Promise((resolve) =>
    canvas.toBlob(resolve, file?.type, 1)
  );
  // Turn Blob into File
  const compressedImage = new File([blob], file.name, {
    type: blob.type,
  });
  return compressedImage;
};
function calculateSize(img, maxWidth, maxHeight) {
  let width = img.width;
  let height = img.height;
  // calculate the width and height, constraining the proportions
  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }
  return [width, height];
}
// Utility functions for demo purpose
export default compressImage;
