import useFormMode from "../../redux/hooks/useFormMode";

interface SelectInputFieldProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  data: string[];
  SelectInputClassname?: string;
  item?: string;
  label?: string;
  error?: string;
  defaultValue?: string | number;
  register?: any;
  required?: boolean;
  placeholder?: string;
}

const SelectInputField: React.FC<SelectInputFieldProps> = ({
  data = [],
  SelectInputClassname,
  item,
  label,
  register,
  defaultValue,
  required = false,
  error,
  placeholder,
  ...props
}) => {
  const { formMode } = useFormMode();
  return (
    <div className="flex w-full flex-col gap-1">
      {label && (
        <label className="text-xs md:text-sm font-medium">
          {label} {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <select
        disabled={formMode === "read"}
        className={`p-2 rounded-md text-xs md:text-sm font-normal border ${SelectInputClassname} ${
          error &&
          `border-red-500 focus-visible:border-red-500 focus-visible:outline-red-500`
        }`}
        defaultValue={defaultValue}
        {...register}
        {...props}
      >
        {placeholder && (
          <option value="undefined" disabled selected={true}>
            {placeholder}
          </option>
        )}
        {data.map((item: string, index: number) => {
          return (
            <option key={index} value={item} className="text-sm font-medium">
              {item}
            </option>
          );
        })}
        {error && <p className="text-red-500 text-xs">{error}</p>}
      </select>
    </div>
  );
};

export default SelectInputField;
