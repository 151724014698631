import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICheckoutOrder } from "../../types/CommonType";

const INITIAL_STATE = {
  order: {
    plan_id: "1",
  } as ICheckoutOrder,
};

const checkoutReducer = createSlice({
  name: "checkoutReducer",
  initialState: INITIAL_STATE,
  reducers: {
    setCheckoutOrder: (state, action: PayloadAction<ICheckoutOrder>) => {
      state.order = action?.payload;
    },
  },
});

export const { setCheckoutOrder } = checkoutReducer.actions;
export default checkoutReducer.reducer;
