import {
  combineReducers,
  configureStore,
  createAction,
} from "@reduxjs/toolkit";
import uiReducer from "./reducers/uiReducer";
import userAddressReducer from "./reducers/userAddressReducer";
import organizationReducer from "./reducers/organizationReducer";
import formModeReducer from "./reducers/formModeReducer";
import checkoutReducer from "./reducers/checkoutReducer";

export const revertAll = createAction("REVERT_ALL");

const combinedReducer = combineReducers({
  ui: uiReducer,
  userAddress: userAddressReducer,
  organization: organizationReducer,
  formMode: formModeReducer,
  checkOut: checkoutReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "REVERT_ALL") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export function makeStore() {
  return configureStore({
    reducer: rootReducer,
  });
}

const store = makeStore();
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
