import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import React, { ReactNode, useEffect, useState } from "react";
import {
  ModalTypes,
  closeModal,
  openModal,
} from "../../redux/reducers/uiReducer";
import { useDispatch } from "react-redux";
import { includes } from "lodash";
import useUI from "../../redux/hooks/useUi";

interface ModalProps {
  name: ModalTypes;
  children: ReactNode;
  onClose?: () => void;
}

const CommonModal: React.FC<ModalProps> = ({
  name,
  children,
  onClose = () => {},
}) => {
  const dispatch = useDispatch();
  const { modalType } = useUI();

  const toggleModal = (visible: boolean) => {
    if (visible) {
      dispatch(openModal(name));
    } else {
      dispatch(closeModal(name));
    }
  };

  return (
    <Transition show={modalType.includes(name)}>
      <Dialog
        className="relative z-20"
        onClose={(value) => {
          toggleModal(value);
          onClose();
        }}
      >
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
        </TransitionChild>
        {children}
      </Dialog>
    </Transition>
  );
};
export default CommonModal;
