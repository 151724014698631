import { DialogPanel, DialogTitle, TransitionChild } from "@headlessui/react";
import LoginFormSvg from "../atoms/LoginFormSvg";
import CommonModal from "./CommonModal";
import PrimaryButton from "../atoms/PrimaryButton";
import { NavLink } from "react-router-dom";
import { ILoginFormInput } from "./LoginModal";
import { useForm } from "react-hook-form";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { REGEX } from "../../constants/RegexConstants";
import InputField from "../atoms/InputField";
import api from "../../helpers/api";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { closeModal } from "../../redux/reducers/uiReducer";
import ForgetPasswordSvg from "../atoms/ForgetPasswordSvg";

interface ForgotPasswordModalProps {
  email?: string;
}

interface IForgetPasswordFormInput {
  email?: string;
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({ email }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgetPasswordFormInput>();

  const onSubmit = (data: any) => {
    setBtnLoading(true);
    api
      .POST("forgot-password", data)
      .then((res) => {
        if (res.status === 200) {
          toast.success(
            `Please check your email link has been send to your email`
          );
          dispatch(closeModal("ForgotPasswordModal"));
        }
      })
      .catch((error) => {
        toast.error("Unable to reset password");
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  return (
    <CommonModal name="ForgotPasswordModal">
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 md:p-0">
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel className="rounded-2xl bg-white text-left shadow-xl transition-all md:my-8 md:w-fit justify-center items-center flex flex-col">
              <div className="flex items-center justify-center columns-2">
                <div className="hidden md:block w-full">
                  <ForgetPasswordSvg className="md:h-[250px] w-full" />
                </div>
                <div className="flex flex-col items-center justify-center w-full">
                  <div className="text-start md:mt-0 md:text-left pt-4">
                    <DialogTitle
                      as="h3"
                      className="text-xl text-center font-semibold leading-6 text-gray-900"
                    >
                      Forgot Password
                    </DialogTitle>
                    <div className="mt-2">
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="flex flex-col justify-center items-start gap-6 p-4 md:px-4 max-w-80"
                      >
                        <InputField
                          label="Email"
                          placeholder="Email"
                          type="email"
                          defaultValue={email}
                          error={errors?.email?.message}
                          register={register("email", {
                            required: "This Field is Required",
                            pattern: {
                              value: REGEX.email,
                              message: "Invalid email address",
                            },
                          })}
                        />
                        <PrimaryButton
                          type="submit"
                          btnClassName="w-full text-center flex items-center justify-center gap-1 rounded-[6px] h-[38px]"
                          loading={btnLoading}
                        >
                          <EnvelopeIcon width={20} /> Send Reset Email
                        </PrimaryButton>
                      </form>
                    </div>
                  </div>
                  <div className="pb-4">
                    <p className="text-[8px] text-center">
                      <NavLink
                        to="/terms-and-conditions"
                        className="hover:underline cursor-pointer"
                      >
                        Terms & Conditions
                      </NavLink>{" "}
                      and{" "}
                      <NavLink
                        to="/privacy-policy"
                        className="hover:underline cursor-pointer"
                      >
                        Privacy Policy
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </CommonModal>
  );
};

export default ForgotPasswordModal;
