import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import compressImage from "../../helpers/compressImage";
import { FiletoBase64 } from "../../helpers/utils";

interface ProfileImageUploadProps {
  disabled?: boolean;
  image?: string;
  onImageChange?: (image: string) => void;
}

const ProfileImageUpload: React.FC<ProfileImageUploadProps> = ({
  disabled = false,
  image,
  onImageChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = async (e: any) => {
    if (inputRef?.current && e?.target?.files?.[0]) {
      if (
        ["image/jpeg", "image/png", "image/jpg"].includes(
          e?.target?.files?.[0]?.type
        )
      ) {
        const compressedFile = await compressImage(e?.target?.files?.[0]);
        if (compressedFile?.size >= 200000) {
          return toast.error("Profile picture size should be less than 200kb");
        } else {
          const url = URL.createObjectURL(compressedFile);
          fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
              FiletoBase64(blob).then((base64String: any) => {
                onImageChange && onImageChange(base64String);
              });
            });
        }
      } else {
        toast.error("Please select only jpeg , png image file");
      }
    }
  };

  return (
    <div className="group flex justify-center items-center h-28 w-28 relative bg-gray-200 rounded-full border border-gray-400">
      <input
        type="file"
        ref={inputRef}
        hidden
        accept="image/png, image/jpeg, image/jpg"
        onChange={handleImageChange}
      />
      <img className="w-full h-full object-fill rounded-full" src={image} />
      {inputRef?.current === null ? (
        <div
          onClick={() => {
            //@ts-ignore
            inputRef.current.click();
          }}
          className="absolute w-full h-full cursor-pointer flex justify-center items-center bg-black/10 rounded-full p-5"
        >
          <CloudArrowUpIcon className=" size-8 text-gray-600" />
        </div>
      ) : (
        !disabled && (
          <div
            onClick={() => {
              //@ts-ignore
              inputRef.current.click();
            }}
            className="hidden absolute w-full h-full cursor-pointer group-hover:flex justify-center items-center bg-black/10 rounded-full p-5"
          >
            <CloudArrowUpIcon className=" size-8 text-gray-600" />
          </div>
        )
      )}
    </div>
  );
};

export default ProfileImageUpload;
