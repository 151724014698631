import {
  Transition,
  Dialog,
  TransitionChild,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { NewspaperIcon, StarIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import InvoiceModal from "./InvoiceModal";
import StatusBadges from "../atoms/StatusBadges";
import { IResponse, useAuth } from "../../providers/AuthContext";
import { IOrder } from "../../types/CommonType";
import api from "../../helpers/api";
import OrderModalSkeleton from "../skelitons/OrderModalSkeleton";
import PrimaryButton from "../atoms/PrimaryButton";
import { useDispatch } from "react-redux";
import { closeModal, startRefetch } from "../../redux/reducers/uiReducer";
import toast from "react-hot-toast";

interface OrderModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  orderId: string | undefined;
}

const OrderModal: React.FC<OrderModalProps> = ({
  visible,
  setVisible,
  orderId,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const { selectedCompany } = useAuth();
  const [orders, setOrder] = useState<IOrder>();
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const dispatch = useDispatch();

  const toggleModal = (visibleInvoice: boolean) => {
    if (visibleInvoice) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    if (orderId) {
      setSkeletonLoading(true);
      api
        .GET<IResponse<IOrder>>(`${selectedCompany?.domain}/orders/${orderId}`)
        .then((response) => {
          const ordersData = response?.data?.data;
          if (ordersData) {
            setOrder(ordersData);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setSkeletonLoading(false);
        });
    }
  }, [orderId]);

  const pendingpaymentProcess = () => {
    if (orderId) {
      api
        .POST(`${selectedCompany?.domain}/orders/${orderId}/pay`)
        .then((res: any) => {
          if (res?.data?.redirect) window.location.href = res.data.redirect;
        });
    } else {
      toast.error("Request cannot fulfill");
    }
  };

  const cancelPaymentProcess = () => {
    if (orderId) {
      api
        .POST(`${selectedCompany?.domain}/orders/${orderId}/cancel`)
        .then((res: any) => {
          if (res?.data?.id) {
            setVisible(false);
            dispatch(startRefetch("orderList"));
          }
        });
    } else {
      toast.error("Request cannot cancel");
    }
  };

  return (
    <>
      <Transition show={visible}>
        <Dialog className="relative z-20" onClose={setVisible}>
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
          </TransitionChild>
          <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 md:p-0">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="rounded-2xl bg-[#F9FAFB] text-left shadow-xl transition-all md:my-8 md:w-fit justify-center items-center flex flex-col py-4 px-4 md:p-8">
                  {skeletonLoading && !orders?.id ? (
                    <OrderModalSkeleton />
                  ) : (
                    <>
                      <div className="text-start md:mt-0 md:text-left w-full">
                        <DialogTitle
                          as="h3"
                          className="flex flex-1 text-xs md:text-xl text-center items-center justify-between w-full font-medium md:font-semibold leading-6 text-gray-900 mb-4 md:mb-4"
                        >
                          <p>#{orders?.id}</p>
                          <div className="flex items-center gap-2">
                            {orders?.plan_details ? (
                              <p className="flex items-center gap-1 p-1 px-2 bg-blue-100 text-blue-500 rounded-xl text-[10px] md:text-sm">
                                <StarIcon className="hidden md:block size-3 md:size-6 text-yellow-500 fill-yellow-500" />{" "}
                                {orders?.plan_details?.title &&
                                  orders?.plan_details?.title
                                    ?.slice(0, 1)
                                    .toUpperCase() +
                                    orders?.plan_details?.title?.slice(1)}{" "}
                                Plan
                              </p>
                            ) : (
                              <NavLink
                                to="/pricing"
                                className="text-xs md:text-sm font-medium underline text-blue-500"
                              >
                                No Active Plan
                              </NavLink>
                            )}
                            <StatusBadges status={orders?.status} />
                            <button
                              className="flex items-center gap-1 py-1.5 px-2.5 text-xs md:text-sm font-medium text-white bg-geovetraGreen-500 rounded-xl hover:bg-geovetraGreen-600"
                              onClick={() => {
                                setOpenModal(true);
                                setVisible(false);
                              }}
                            >
                              <NewspaperIcon className="size-4 md:size-5" />
                              Invoice
                            </button>
                          </div>
                        </DialogTitle>
                      </div>
                      <div className="flex flex-col md:flex-row justify-between items-start w-full columns-2 gap-4 mb-4">
                        <div className="md:w-1/2">
                          <p className="text-xs md:text-sm font-medium">
                            Shipping Address
                          </p>
                          <p className="text-[10px] md:text-xs font-normal line-clamp-1 md:text-wrap md:max-w-xs">
                            {[
                              orders?.shipping_address_details?.address_line_1,
                              orders?.shipping_address_details?.address_line_2,
                              orders?.shipping_address_details?.city?.name,
                              orders?.shipping_address_details?.pincode?.name,
                              orders?.shipping_address_details?.state?.name,
                              orders?.shipping_address_details?.country?.name,
                            ].join(", ")}
                          </p>
                        </div>
                        <div className="md:w-1/2">
                          <p className="text-xs md:text-sm font-medium">
                            Billing Address
                          </p>
                          <p className="text-[10px] md:text-xs font-normal line-clamp-1 md:text-wrap md:max-w-xs">
                            {[
                              orders?.billing_address_details?.address_line_1,
                              orders?.billing_address_details?.address_line_2,
                              orders?.billing_address_details?.city?.name,
                              orders?.billing_address_details?.pincode?.name,
                              orders?.billing_address_details?.state?.name,
                              orders?.billing_address_details?.country?.name,
                            ].join(", ")}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row justify-center items-start w-full columns-2 gap-4 my-4">
                        <div>
                          <p className="text-xs md:text-base font-bold md:font-medium">
                            Transactions
                          </p>
                          {orders?.payments?.length == 0 ? (
                            <p className="text-center text-xs md:text-base font-bold md:font-medium my-4 bg-white rounded-xl p-2">
                              No Transaction
                            </p>
                          ) : (
                            orders?.payments?.map((payment, index) => (
                              <div
                                className="flex justify-between items-center gap-4 mt-2 bg-white rounded-xl p-2"
                                key={index}
                              >
                                <p className="p-1 px-2 rounded-xl bg-red-100 text-xs font-semibold">
                                  {payment?.order_ref_id}
                                </p>
                                <p className="text-xs font-medium">
                                  {payment?.created_at?.date}{" "}
                                  {payment?.created_at?.time}
                                </p>
                              </div>
                            ))
                          )}
                        </div>
                        <div className="md:border-l md:pl-4">
                          <div className="relative overflow-x-auto">
                            <table className="w-full text-sm text-left rtl:text-right">
                              <thead className="text-xs">
                                <tr>
                                  <th
                                    scope="col"
                                    className=" pt-3 text-xs md:text-base font-bold md:font-medium"
                                  >
                                    Description
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 pt-3 text-xs md:text-base font-bold md:font-medium"
                                  >
                                    Qty
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 pt-3 text-xs md:text-base font-bold md:font-medium"
                                  >
                                    Price
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 pt-3 text-xs md:text-base font-bold md:font-medium"
                                  >
                                    Total
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {orders?.purchases?.map((purchase, index) => {
                                  return (
                                    <tr className="" key={index}>
                                      <th
                                        scope="row"
                                        className="pb-2 text-xs md:text-sm font-medium"
                                      >
                                        Purchase of {purchase?.type_of_purchase}
                                      </th>
                                      <td className="pl-6 py-2 text-xs md:text-sm">
                                        {purchase?.number_of_units} Units
                                      </td>
                                      <td className="pl-6 py-2 text-xs md:text-sm">
                                        ₹ {purchase?.cost_per_unit} / Units
                                      </td>
                                      <td className="pl-6 py-2 text-xs md:text-sm">
                                        ₹ {purchase?.total_purchase_cost}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                              <tbody>
                                {orders?.recharges?.map((recharge, index) => {
                                  return (
                                    <tr className="" key={index}>
                                      <th
                                        scope="row"
                                        className="pb-2 text-xs md:text-sm font-medium"
                                      >
                                        Recharge of {recharge?.number_of_days}{" "}
                                        Days
                                      </th>
                                      <td className="pl-6 py-2 text-xs md:text-sm">
                                        {recharge?.number_of_subscriptions}{" "}
                                        Units
                                      </td>
                                      <td className="pl-6 py-2 text-xs md:text-sm">
                                        ₹ {recharge?.cost_per_day} / Day
                                      </td>
                                      <td className="pl-6 py-2 text-xs md:text-sm">
                                        ₹ {recharge?.recharge_total}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>

                          <div className="flex justify-between border-t mt-2 pt-2">
                            <div>
                              <p className="text-xs md:text-sm mb-1">Total: </p>
                              <p className="text-xs md:text-sm mb-1">
                                Discount:
                              </p>
                            </div>
                            <div>
                              <p className="text-xs md:text-sm mb-1">
                                ₹ {orders?.order_total_amount}
                              </p>
                              <p className="text-xs md:text-sm mb-1">
                                ₹ {orders?.discount_amount}
                              </p>
                            </div>
                          </div>
                          <div className="flex justify-between border-t mt-2 pt-2">
                            <div>
                              <p className="text-xs md:text-sm mb-1">
                                Net Amount (Taxable Amount):{" "}
                              </p>
                              <p className="text-xs md:text-sm mb-1">
                                GST Amount (18%):
                              </p>
                            </div>
                            <div>
                              <p className="text-xs md:text-sm mb-1">
                                ₹ {orders?.net_amount}
                              </p>
                              <p className="text-xs md:text-sm mb-1">
                                ₹ {orders?.tax_amount}
                              </p>
                            </div>
                          </div>
                          <div className="flex justify-between border-t mt-2 pt-2">
                            <div>
                              <p className="text-xs md:text-sm mb-1 font-semibold">
                                Grand Total:
                              </p>
                            </div>
                            <div>
                              <p className="text-xs md:text-sm mb-1 font-semibold">
                                ₹ {orders?.grand_total}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* buttons */}
                      {orders?.status === "pending" && (
                        <div className="flex justify-between gap-2 w-full mt-4">
                          <PrimaryButton
                            type="button"
                            className="md:py-2 px-5 text-xs md:text-sm font-medium text-black bg-gray-50 rounded-xl hover:bg-gray-200 inline-flex items-center border border-gray-300"
                            onClick={() => {
                              cancelPaymentProcess();
                            }}
                          >
                            Cancel the payment
                          </PrimaryButton>
                          <PrimaryButton
                            type="submit"
                            btnClassName="w-fit text-center flex items-center justify-center rounded-[6px] h-[38px] text-xs md:text-sm"
                            onClick={() => {
                              pendingpaymentProcess();
                            }}
                          >
                            Proceed to secure payment
                          </PrimaryButton>
                        </div>
                      )}
                    </>
                  )}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      <InvoiceModal
        order={orders}
        visibleInvoice={openModal}
        setVisibleInvoice={toggleModal}
      />
    </>
  );
};

export default OrderModal;
