import { ReactNode } from "react";
import Footer from "../pages/dashboard/Footer";
import Navbar from "../pages/dashboard/Navbar";
import { Outlet } from "react-router-dom";

interface HomeLayoutProps {}

const HomeLayout: React.FC<HomeLayoutProps> = () => {
  return (
    <div className="flex flex-col items-center justify-center relative bg-grid-small-geovetra-primary/10">
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default HomeLayout;
