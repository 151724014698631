import { SubmitHandler, useForm } from "react-hook-form";
import PrimaryButton from "../../components/atoms/PrimaryButton";
import InputField from "../../components/atoms/InputField";
import SelectInputField from "../../components/atoms/SelectInputField";
import TextAreaInput from "../../components/atoms/TextAreaInput";
import emailjs from "@emailjs/browser";
import { useRef, useState } from "react";
import toast from "react-hot-toast";

enum reasonType {}

interface IFormInput {
  name: string;
  surname: string;
  email: string;
  need: reasonType;
  message: string;
}

interface ContactUsPageProps {}

const ContactUsPage: React.FC<ContactUsPageProps> = () => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormInput>();

  const onSubmit = (data: IFormInput) => {
    setLoading(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICEID ?? "",
        process.env.REACT_APP_EMAILJS_TEMPLATEID ?? "",
        "#contact_form_id",
        process.env.REACT_APP_EMAILJS_SECRET_KEY ?? ""
      )
      .then(
        (result: any) => {
          reset();
          toast.success("Mail has been successfully sent!");
        },
        (error: any) => {
          toast.error("Mail Not sent!");
          console.log(error);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="contactus-bg-image flex flex-col justify-center items-center">
      <div className="bg-white rounded-xl flex justify-center items-center shadow-2xl md:px-4 w-fit">
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="contact_form_id"
          className="flex flex-col justify-center items-start gap-6 p-8 px-4"
        >
          <p className="text-2xl font-medium text-center mx-auto bg-white ">
            Contact Us
          </p>
          <div className="flex flex-col gap-6 md:flex-row">
            <InputField
              label="First Name *"
              placeholder="First Name"
              type="text"
              error={errors?.name?.message}
              register={register("name", {
                required: "This Field is Required",
              })}
            />
            <InputField
              label="Last Name *"
              placeholder="Last Name"
              type="text"
              error={errors?.surname?.message}
              register={register("surname", {
                required: "This Field is Required",
              })}
            />
          </div>
          <div className="flex flex-col md:flex-row gap-6">
            <InputField
              label="Email *"
              placeholder="Email"
              type="email"
              error={errors?.email?.message}
              register={register("email", {
                required: "This Field is Required",
              })}
            />
            <SelectInputField
              label="Please specify your need *"
              data={[
                "Request invoice for order",
                "Request order status",
                "Haven't received cashback yet",
                "Other",
              ]}
              register={{
                ...register("need", { required: "This Field is Required" }),
              }}
              error={errors?.need?.message}
            />
          </div>
          <div className="w-full">
            <TextAreaInput
              label="Message *"
              placeholder="Write your message here"
              error={errors?.message?.message}
              register={register("message", {
                required: "This Field is Required",
              })}
            />
          </div>
          <div className="flex flex-col gap-2 justify-center items-center self-center">
            <PrimaryButton type="submit" loading={loading}>
              Submit
            </PrimaryButton>
            <p className="font-light text-red-500 text-xs">
              (We will respond to your Inquiry within two bussiness days)
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUsPage;
