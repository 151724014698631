import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAddress } from "../../types/CommonType";

const INITIAL_STATE = {
  address: {} as IAddress,
};

const userAddressReducer = createSlice({
  name: "userAddressReducer",
  initialState: INITIAL_STATE,
  reducers: {
    setUserAddress: (state, action: PayloadAction<IAddress>) => {
      state.address = action?.payload;
    },
  },
});

export const { setUserAddress } = userAddressReducer.actions;
export default userAddressReducer.reducer;
