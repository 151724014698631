import React, { useState } from "react";
import Modal from "./CommonModal";
import { DialogPanel, DialogTitle, TransitionChild } from "@headlessui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import PrimaryButton from "../atoms/PrimaryButton";
import { NavLink, useNavigate } from "react-router-dom";
import LoginFormSvg from "../atoms/LoginFormSvg";
import CommonModal from "./CommonModal";
import {
  closeModal,
  openModal,
  setLoading,
} from "../../redux/reducers/uiReducer";
import { useDispatch } from "react-redux";
import InputField from "../atoms/InputField";
import { useAuth } from "../../providers/AuthContext";
import setFormErrors from "../../helpers/setFormErrors";
import { REGEX } from "../../constants/RegexConstants";
import ForgotPasswordModal from "./ForgetPasswordModal";

export interface ILoginFormInput {
  email: string;
  password: string;
}

interface LoginModalProps {}

const LoginModal: React.FC<LoginModalProps> = () => {
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const { login, auth } = useAuth();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    reset,
    watch,
    formState: { errors },
  } = useForm<ILoginFormInput>();

  const onSubmit: SubmitHandler<ILoginFormInput> = (data) => {
    setBtnLoading(true);
    // dispatch(closeModal("LoginModal"));
    login(
      data,
      (message) => {
        if (typeof message === "string") {
          setError("password", { message: message }, { shouldFocus: true });
        } else {
          setFormErrors<ILoginFormInput>(message, setError);
        }
      },
      () => {
        navigate("/settings/account");
        reset();
      }
    ).finally(() => {
      setBtnLoading(false);
    });
  };

  return (
    <>
      <CommonModal name="LoginModal">
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 md:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="rounded-2xl bg-white text-left shadow-xl transition-all md:my-8 md:w-fit justify-center items-center flex flex-col">
                <div className="flex items-center justify-center columns-2">
                  <div className="hidden md:block w-full">
                    <LoginFormSvg className="md:h-[250px] w-full" />
                  </div>
                  <div className="flex flex-col items-center justify-center w-full">
                    <div className="text-start md:mt-0 md:text-left pt-4">
                      <DialogTitle
                        as="h3"
                        className="text-xl text-center font-semibold leading-6 text-gray-900"
                      >
                        Login
                      </DialogTitle>
                      <div className="mt-2">
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          className="flex flex-col justify-center items-start gap-6 p-4 md:px-4 max-w-80"
                        >
                          <InputField
                            label="Email"
                            placeholder="Email"
                            type="email"
                            error={errors?.email?.message}
                            register={register("email", {
                              required: "This Field is Required",
                              pattern: {
                                value: REGEX.email,
                                message: "Invalid email address",
                              },
                            })}
                          />
                          <div className="flex flex-col gap-1">
                            <InputField
                              label="Password"
                              placeholder="Password"
                              type="password"
                              error={errors?.password?.message}
                              register={register("password", {
                                required: "This Field is Required",
                              })}
                            />
                            <label
                              className="self-end text-[10px] font-normal text-[#0000EE] cursor-pointer hover:underline w-fit"
                              onClick={() => {
                                dispatch(openModal("ForgotPasswordModal"));
                                dispatch(closeModal("LoginModal"));
                              }}
                            >
                              Forgot Password?
                            </label>
                          </div>
                          <PrimaryButton
                            type="submit"
                            btnClassName="w-full text-center flex items-center justify-center rounded-[6px] h-[38px]"
                            loading={btnLoading}
                          >
                            Login
                          </PrimaryButton>
                        </form>
                      </div>
                    </div>
                    <div className="pb-4">
                      <p className="text-xs">
                        Don't Have an Account?{" "}
                        <span
                          className="text-orange-500 hover:underline cursor-pointer"
                          onClick={() => {
                            dispatch(closeModal("LoginModal"));
                            dispatch(openModal("RegisterModal"));
                          }}
                        >
                          Register
                        </span>
                      </p>
                      <p className="text-[8px] text-center">
                        <NavLink
                          to="terms-and-conditions"
                          target="_blank"
                          className="hover:underline cursor-pointer"
                        >
                          Terms & Conditions
                        </NavLink>{" "}
                        and{" "}
                        <NavLink
                          to="privacy-policy"
                          target="_blank"
                          className="hover:underline cursor-pointer"
                        >
                          Privacy Policy
                        </NavLink>
                      </p>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </CommonModal>
      <ForgotPasswordModal email={watch("email")} />
    </>
  );
};

export default LoginModal;
