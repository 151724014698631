import { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import { NavLink, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openModal } from "../../redux/reducers/uiReducer";
import LoginModal from "../../components/modals/LoginModal";
import RegisterModal from "../../components/modals/RegisterModal";
import PrimaryButton from "../../components/atoms/PrimaryButton";
import CommonLoader from "../../components/atoms/CommonLoader";
import { useAuth } from "../../providers/AuthContext";
import ProfileDropdownCard from "../../components/atoms/ProfileDropdownCard";
import OrganizationModal from "../../components/modals/OrganizationModal";
import AddressModal from "../../components/modals/AddressModal";
import ResetPasswordModal from "../../components/modals/ResetPasswordModal";

interface NavbarProps {}

const Navbar: React.FC<NavbarProps> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (searchParams.get("email") && searchParams.get("token")) {
      dispatch(openModal("ResetPasswordModal"));
    }
  }, []);

  const navButtons = [
    {
      title: "Home",
      navLink: "/",
      target: "",
    },
    {
      title: "Pricing",
      navLink: "/pricing",
      target: "",
    },
    {
      title: "About",
      navLink: "https://www.essenrfid.com",
      target: "_blank",
    },
    {
      title: "Contact",
      navLink: "/contact-us",
      target: "",
    },
  ];

  const renderNavButtons = () => {
    return (
      <div className="hidden w-full md:block md:w-auto" id="navbar-default">
        <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:items-center md:space-x-6 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-transparent">
          {navButtons.map((btn, index) => {
            return (
              <li key={index}>
                <NavLink
                  to={`${btn?.navLink}`}
                  target={`${btn?.target}`}
                  className={({ isActive }) =>
                    `block px-3 py-2 rounded-md text-base font-medium text-black hover:bg-[#F9FAFB77] duration-100 ${
                      isActive ? "" : ""
                    }`
                  }
                >
                  {btn.title}
                </NavLink>
              </li>
            );
          })}

          {isAuthenticated ? (
            <ProfileDropdownCard />
          ) : (
            <li>
              <PrimaryButton
                // className="block px-3 py-2 rounded-md text-base font-medium bg-geovetraGreen-500 text-white hover:bg-geovetraGreen-200 duration-100 cursor-pointer"
                onClick={() => {
                  dispatch(openModal("LoginModal"));
                }}
              >
                Login
              </PrimaryButton>
            </li>
          )}
        </ul>
      </div>
    );
  };

  const renderMobileNavButtons = () => {
    return (
      <div
        className={`${
          isOpen ? "block" : "hidden"
        } md:hidden z-auto absolute bg-white w-full rounded-xl border-b-2`}
        id="navbar-default"
      >
        <ul className="space-y-1 px-2 pt-2 pb-3">
          {navButtons.map((btn, index) => {
            return (
              <li key={index}>
                <NavLink
                  to={`${btn.navLink}`}
                  className={({ isActive }) =>
                    `block py-2 px-3 rounded-lg md:bg-transparent md:hover:text-geovetraGreen-50 md:p-2 md:px-4 md:dark:hover:bg-geovetraGreen-500 duration-100 text-xs ${
                      isActive ? " bg-gray-200 " : ""
                    }`
                  }
                  onClick={toggleDropdown}
                >
                  {btn.title}
                </NavLink>
              </li>
            );
          })}
          {isAuthenticated ? (
            <ProfileDropdownCard />
          ) : (
            <li>
              <div
                className="block bg-geovetraGreen-500 text-white py-2 px-3 rounded-lg md:bg-transparent md:hover:text-geovetraGreen-50 md:p-2 md:px-4 md:dark:hover:bg-geovetraGreen-500 duration-100 cursor-pointer"
                onClick={() => {
                  dispatch(openModal("LoginModal"));
                  toggleDropdown();
                }}
              >
                Login
              </div>
            </li>
          )}
        </ul>
      </div>
    );
  };

  return (
    <nav className="bg-transparent z-20 w-full ">
      <div className="flex items-center justify-between p-4">
        <div className="flex flex-col md:flex-row md:items-center md:gap-2">
          <NavLink className="" to="/">
            <img src={logo} className="geovetra-logo w-52" alt="" />
          </NavLink>
          <p className="p-0 self-start text-sm text-primary has-text-weight-semibold hidden md:block">
            Geo vehicle traffic status <br />
            and transit analysis
          </p>
        </div>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-geovetraGreen-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:bg-geovetraGreen-50"
          aria-controls="navbar-default"
          aria-expanded={isOpen ? "true" : "false"}
          onClick={toggleDropdown}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        {renderNavButtons()}
      </div>

      {renderMobileNavButtons()}

      {/* MODALS */}
      <LoginModal />
      <RegisterModal />
      <OrganizationModal />
      <ResetPasswordModal
        email={searchParams.get("email")}
        token={searchParams.get("token")}
      />
      <AddressModal />
      <CommonLoader />
    </nav>
  );
};

export default Navbar;
