import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompanyUser } from "../../types/CommonType";

const INITIAL_STATE = {
  organization: {} as ICompanyUser,
};

const organizationReducer = createSlice({
  name: "organizationReducer",
  initialState: INITIAL_STATE,
  reducers: {
    setOrganization: (state, action: PayloadAction<ICompanyUser>) => {
      state.organization = action?.payload;
    },
  },
});

export const { setOrganization } = organizationReducer.actions;
export default organizationReducer.reducer;
