interface PricingTickSvgProps {
  strockColor?: string;
}

const PricingTickSvg: React.FC<PricingTickSvgProps> = ({ strockColor }) => {
  return (
    <svg viewBox="0 0 48 48" className="md:w-[20px] w-[15px]">
      <path
        fill="none"
        stroke={`${strockColor ?? "black"}`}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M14.6,34.6l4,4c0.8,0.8,2,0.8,2.8,0l21.3-21.3c0.8-0.8,0.8-2,0-2.8l-4.4-4.4c-0.8-0.8-2-0.8-2.8,0L32,13.6"
      />
      <path
        fill="none"
        stroke={`${strockColor ?? "black"}`}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M28,17.6l-8,8l-5.3-5.3c-0.8-0.8-2-0.8-2.8,0l-4.4,4.4c-0.8,0.8-0.8,2,0,2.8l3.3,3.3"
      />
    </svg>
  );
};

export default PricingTickSvg;
