interface CardSectionProps {}

const CardSection: React.FC<CardSectionProps> = () => {
  const cardData = [
    {
      id: 1,
      idColor: "#d05f26",
      BackgroundColor: "#fdba74",
      title: "Driver Mark In",
      description:
        "Drivers use the Geovetra app to mark in, signaling the start of their delivery route. This initiates real-time tracking within our system.",
    },
    {
      id: 2,
      idColor: "#c93e3e",
      BackgroundColor: "#fca5a5",
      title: "Route Creation",
      description:
        "Upon marking in, our system automatically generates and optimizes the best delivery route, saving time and resources.",
    },
    {
      id: 3,
      idColor: "#1f946f",
      BackgroundColor: "#6ee7b7",
      title: "Driver Tracking",
      description:
        "Monitor your drivers in real-time as they proceed through their deliveries, ensuring timely arrivals and departures.",
    },
    {
      id: 4,
      idColor: "#3a6be1",
      BackgroundColor: "#93c5fd",
      title: "Delivery Completion",
      description:
        "Drivers mark out at the end of their route, providing a complete log of the trip, which is stored for your records.",
    },
  ];
  return (
    <div className=" items-center justify-center flex text-center flex-col gap-3 max-w-7xl px-4 md:my-16">
      <div className="bg-white [mask-image:radial-gradient(ellipse_at_center,white_50%,transparent)]">
        <p className="font-semibold text-black text-2xl md:text-3xl my-3">
          How It Works
        </p>
        <p className="text-sm font-medium text-slate-500 md:text-xl md:pb-8 pb-4">
          Geovetra simplifies your delivery management through four
          straightforward steps, maximizing efficiency and transparency.
        </p>
      </div>

      <div className="gap-10 grid grid-cols-1 md:grid-cols-4 md:gap-10 items-stretch px-6 md:px-0">
        {cardData.map((data, index) => {
          return (
            <div
              key={index}
              className="max-w-sm p-6 py-10 bg-white border border-gray-200 rounded-3xl shadow-md flex flex-col items-center justify-start"
            >
              <div
                className="bg-geovetraGreen-100 p-8 mb-5 rounded-full flex items-center justify-center h-6 w-6"
                style={{
                  backgroundColor: `${data.BackgroundColor}`,
                }}
              >
                <h5
                  className="text-3xl font-medium tracking-tight text-geovetraGreen-500 "
                  style={{
                    color: `${data.idColor}`,
                  }}
                >
                  {data.id}
                </h5>
              </div>
              <p className="text-black font-semibold mb-1">{data.title}</p>
              <p className="text-slate-500 font-normal text-sm text-wrap">
                {data.description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CardSection;
