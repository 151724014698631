interface TextAreaInputProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  textAreaClassName?: string;
  label?: string;
  error?: string;
  register?: any;
}

const TextAreaInput: React.FC<TextAreaInputProps> = ({
  textAreaClassName,
  label,
  error,
  register,
  ...props
}) => {
  return (
    <div className="flex flex-col gap-1 ">
      {label && <label className="text-sm font-medium">{label}</label>}
      <textarea
        {...register}
        className={`text-black border p-2 rounded-md text-sm font-normal ${textAreaClassName} ${
          error &&
          `border-red-500 focus-visible:border-red-500 focus-visible:outline-red-500`
        }`}
        {...props}
      />
      {error && <p className="text-red-500 text-xs">{error}</p>}
    </div>
  );
};

export default TextAreaInput;
