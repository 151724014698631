import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";

interface ImageSliderProps<T> {
  imageData: T[];
}

const ImageSlider = <T,>({ imageData }: ImageSliderProps<T>) => {
  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      loop={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{
        dynamicBullets: true,
      }}
      modules={[Autoplay, Pagination]}
      className="rounded-2xl w-full"
    >
      {imageData.map((item: any, index) => {
        return (
          <SwiperSlide key={index} className="swiper-slide swiper-slide-active">
            <img
              src={item.image}
              alt=""
              className="h-[250px] md:h-[400px] rounded-2xl w-full object-fit"
              onContextMenu={(event) => event.preventDefault()}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default ImageSlider;
