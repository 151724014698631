interface BackgroundBlobProps {}

const BackgroundBlob: React.FC<BackgroundBlobProps> = () => {
  return (
    <div className="absolute h-fit right-0 top-0 z-0 w-2/4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="347.1426 3.927 363.7634 297.105"
        opacity={0.2}
      >
        <path
          d="M 357.789 3.927 C 339.867 41.495 344.572 79.907 371.906 119.165 C 412.906 178.052 510.133 140.395 555.812 217.599 C 586.266 269.067 637.964 296.878 710.906 301.032 L 710.906 3.927 L 357.789 3.927 Z"
          fill="#2eb44c"
        />
      </svg>
    </div>
  );
};

export default BackgroundBlob;
