import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeLayout from "./HomeLayout";
import Home from "../pages/Home";
import PricingPage from "../pages/about/PricingPage";
import ContactUsPage from "../pages/about/ContactUsPage";
import TermsAndConditions from "../pages/TermsAndConditions";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import { Provider } from "react-redux";
import store from "../redux/store";
import { AuthProvider } from "../providers/AuthContext";
import { Toaster } from "react-hot-toast";
import SettingsLayout from "./SettingsLayout";
import UserProfile from "../pages/settings/UserProfile";
import Orders from "../pages/settings/Orders";
import PrivateRoute from "../providers/PrivateRoute";
import Organization from "../pages/settings/Organization";
import Subscriptions from "../pages/settings/Subscriptions";
import Checkout from "../pages/settings/Checkout";
import EmailVerificationScreen from "../pages/EmailVerificationScreen";
import PageNotFound from "../pages/PageNotFound";
import InvitationScreen from "../pages/InvitationScreen";

interface ApplayoutProps {}

const AppLayout: React.FC<ApplayoutProps> = () => {
  return (
    <Provider store={store}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomeLayout />}>
              <Route path="*" element={<PageNotFound />} />
              <Route
                path="email/verify/:id/:token"
                element={<EmailVerificationScreen />}
              />
              <Route path="invitation" element={<InvitationScreen />} />
              <Route index element={<Home />} />
              <Route path="pricing" element={<PricingPage />} />
              <Route path="contact-us" element={<ContactUsPage />} />
              <Route
                path="terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route element={<PrivateRoute />}>
                <Route path="settings" element={<SettingsLayout />}>
                  <Route path="account" element={<UserProfile />} />
                  <Route path="orders" element={<Orders />} />
                  <Route path="orders/:id" element={<Orders />} />
                  <Route path="organization" element={<Organization />} />
                  <Route path="subscriptions" element={<Subscriptions />} />
                </Route>
                <Route path="checkout" element={<Checkout />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
        <Toaster />
      </AuthProvider>
    </Provider>
  );
};

export default AppLayout;
