interface StatusBadgesProps {
  status?: string;
}

export enum AllowedOrderStatus {
  PENDING = "pending",
  PAID = "paid",
  CANCELLED = "cancelled",
  FULLFILLED = "fullfilled",
}

const StatusBadges: React.FC<StatusBadgesProps> = ({ status }) => {
  switch (status) {
    case AllowedOrderStatus.PENDING:
      return (
        <span className="uppercase bg-yellow-100 text-yellow-800 py-1 px-2 text-xs font-medium md:text-sm rounded-xl">
          {status}
        </span>
      );
    case AllowedOrderStatus.PAID:
      return (
        <span className="uppercase bg-green-100 text-green-800 py-1 px-2 text-xs font-medium md:text-sm rounded-xl">
          {status}
        </span>
      );
    case AllowedOrderStatus.CANCELLED:
      return (
        <span className="uppercase bg-red-100 text-red-800 py-1 px-2 text-xs font-medium md:text-sm rounded-xl">
          {status}
        </span>
      );
    case AllowedOrderStatus.FULLFILLED:
      return (
        <span className="uppercase bg-green-100 text-green-800 py-1 px-2 text-xs font-medium md:text-sm rounded-xl">
          {status}
        </span>
      );
  }
  return null;
};

export default StatusBadges;
