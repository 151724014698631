import * as React from "react";
interface ForgetPasswordSvgProps {
  className?: string | undefined;
}

const ForgetPasswordSvg: React.FC<ForgetPasswordSvgProps> = ({
  className,
  ...props
}) => {
  return (
    <svg
      className={`animated ${className}`}
      id="freepik_stories-forgot-password"
      viewBox="0 0 500 500"
      {...props}
    >
      <style>
        {
          "svg#freepik_stories-forgot-password:not(.animated) .animable {opacity: 0;}svg#freepik_stories-forgot-password.animated #freepik--background-complete--inject-2 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideDown;animation-delay: 0s;}svg#freepik_stories-forgot-password.animated #freepik--Login--inject-2 {animation: 1s 1 forwards linear slideLeft,1.5s Infinite  linear floating;animation-delay: 0s,1s;}svg#freepik_stories-forgot-password.animated #freepik--Thinking--inject-2 {animation: 1.5s Infinite  linear floating;animation-delay: 0s;}            @keyframes slideDown {                0% {                    opacity: 0;                    transform: translateY(-30px);                }                100% {                    opacity: 1;                    transform: translateY(0);                }            }                    @keyframes slideLeft {                0% {                    opacity: 0;                    transform: translateX(-30px);                }                100% {                    opacity: 1;                    transform: translateX(0);                }            }                    @keyframes floating {                0% {                    opacity: 1;                    transform: translateY(0px);                }                50% {                    transform: translateY(-10px);                }                100% {                    opacity: 1;                    transform: translateY(0px);                }            }        "
        }
      </style>
      <g
        id="freepik--background-complete--inject-2"
        className="animable"
        style={{
          transformOrigin: "250px 226.03px",
        }}
      >
        <rect
          y={382.4}
          width={500}
          height={0.25}
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "250px 382.525px",
          }}
          id="elqrleqjm3ge"
          className="animable"
        />
        <rect
          x={290.21}
          y={389.21}
          width={25.46}
          height={0.25}
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "302.94px 389.335px",
          }}
          id="elf9lovp4f3x"
          className="animable"
        />
        <rect
          x={395.11}
          y={394.62}
          width={60.33}
          height={0.25}
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "425.275px 394.745px",
          }}
          id="elmdrg9lvw5ci"
          className="animable"
        />
        <rect
          x={222.46}
          y={389.33}
          width={62.33}
          height={0.25}
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "253.625px 389.455px",
          }}
          id="elvkkhkj6q53g"
          className="animable"
        />
        <rect
          x={169}
          y={396.81}
          width={13.04}
          height={0.25}
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "175.52px 396.935px",
          }}
          id="el227nrmb84q7"
          className="animable"
        />
        <rect
          x={194.71}
          y={396.81}
          width={59.38}
          height={0.25}
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "224.4px 396.935px",
          }}
          id="el2e0uqq5wb68"
          className="animable"
        />
        <rect
          x={52.46}
          y={392.59}
          width={38.45}
          height={0.25}
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "71.685px 392.715px",
          }}
          id="elacx9drcedwr"
          className="animable"
        />
        <path
          d="M237,337.8H43.91a5.71,5.71,0,0,1-5.7-5.71V60.66A5.71,5.71,0,0,1,43.91,55H237a5.71,5.71,0,0,1,5.71,5.71V332.09A5.71,5.71,0,0,1,237,337.8ZM43.91,55.2a5.46,5.46,0,0,0-5.45,5.46V332.09a5.46,5.46,0,0,0,5.45,5.46H237a5.47,5.47,0,0,0,5.46-5.46V60.66A5.47,5.47,0,0,0,237,55.2Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "140.46px 196.4px",
          }}
          id="el52b3kzs4qo6"
          className="animable"
        />
        <path
          d="M453.31,337.8H260.21a5.72,5.72,0,0,1-5.71-5.71V60.66A5.72,5.72,0,0,1,260.21,55h193.1A5.71,5.71,0,0,1,459,60.66V332.09A5.71,5.71,0,0,1,453.31,337.8ZM260.21,55.2a5.47,5.47,0,0,0-5.46,5.46V332.09a5.47,5.47,0,0,0,5.46,5.46h193.1a5.47,5.47,0,0,0,5.46-5.46V60.66a5.47,5.47,0,0,0-5.46-5.46Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "356.75px 196.4px",
          }}
          id="elyys948v4mv"
          className="animable"
        />
        <polygon
          points="195.96 295.67 129.56 295.67 125.53 249.17 191.93 249.17 195.96 295.67"
          style={{
            fill: "rgb(230, 230, 230)",
            transformOrigin: "160.745px 272.42px",
          }}
          id="elg3gs5pq3pld"
          className="animable"
        />
        <polygon
          points="199.4 295.67 133 295.67 128.97 249.17 195.37 249.17 199.4 295.67"
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "164.185px 272.42px",
          }}
          id="elyx39m4nijt"
          className="animable"
        />
        <polygon
          points="142.08 285.68 139.86 259.15 186.29 259.15 188.51 285.68 142.08 285.68"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "164.185px 272.415px",
          }}
          id="elzrnmp0mjsrh"
          className="animable"
        />
        <rect
          x={203.1}
          y={295.67}
          width={7.79}
          height={86.73}
          style={{
            fill: "rgb(230, 230, 230)",
            transformOrigin: "206.995px 339.035px",
          }}
          id="elyh0zmv3xn9"
          className="animable"
        />
        <rect
          x={207.94}
          y={295.67}
          width={5.41}
          height={86.73}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "210.645px 339.035px",
          }}
          id="elzdp0l14b6k9"
          className="animable"
        />
        <g id="elovj2db7pioc">
          <rect
            x={137.94}
            y={295.67}
            width={75.41}
            height={9.57}
            style={{
              fill: "rgb(240, 240, 240)",
              transformOrigin: "175.645px 300.455px",
              transform: "rotate(180deg)",
            }}
            className="animable"
            id="el3ulnrhud19i"
          />
        </g>
        <rect
          x={132.81}
          y={305.24}
          width={7.79}
          height={77.16}
          style={{
            fill: "rgb(230, 230, 230)",
            transformOrigin: "136.705px 343.82px",
          }}
          id="elcv148knw2n"
          className="animable"
        />
        <rect
          x={137.65}
          y={305.24}
          width={5.41}
          height={77.16}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "140.355px 343.82px",
          }}
          id="eldwo3jswhtou"
          className="animable"
        />
        <rect
          x={77.36}
          y={305.24}
          width={7.79}
          height={77.16}
          style={{
            fill: "rgb(230, 230, 230)",
            transformOrigin: "81.255px 343.82px",
          }}
          id="el8crxnl0f15b"
          className="animable"
        />
        <rect
          x={82.2}
          y={305.24}
          width={5.41}
          height={77.16}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "84.905px 343.82px",
          }}
          id="elp299ft92gk"
          className="animable"
        />
        <rect
          x={153.8}
          y={305.24}
          width={7.79}
          height={77.16}
          style={{
            fill: "rgb(230, 230, 230)",
            transformOrigin: "157.695px 343.82px",
          }}
          id="el0jxbfmizkl5t"
          className="animable"
        />
        <rect
          x={158.63}
          y={305.24}
          width={5.41}
          height={77.16}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "161.335px 343.82px",
          }}
          id="elrnd51m80cr"
          className="animable"
        />
        <rect
          x={77.36}
          y={295.67}
          width={60.58}
          height={9.57}
          style={{
            fill: "rgb(230, 230, 230)",
            transformOrigin: "107.65px 300.455px",
          }}
          id="el1smltsnjjgx"
          className="animable"
        />
        <rect
          x={129.51}
          y={79.41}
          width={234.33}
          height={130.46}
          style={{
            fill: "rgb(224, 224, 224)",
            transformOrigin: "246.675px 144.64px",
          }}
          id="el6m5mat4g7d"
          className="animable"
        />
        <rect
          x={130.69}
          y={79.41}
          width={246.02}
          height={130.46}
          style={{
            fill: "rgb(245, 245, 245)",
            transformOrigin: "253.7px 144.64px",
          }}
          id="el60fydz61epc"
          className="animable"
        />
        <g id="el4k2m9bvyfdi">
          <rect
            x={192.49}
            y={25.65}
            width={122.42}
            height={237.98}
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "253.7px 144.64px",
              transform: "rotate(90deg)",
            }}
            className="animable"
            id="el8k6akhgvfij"
          />
        </g>
        <polygon
          points="231.07 205.85 276 83.43 201.85 83.43 156.92 205.85 231.07 205.85"
          style={{
            fill: "rgb(245, 245, 245)",
            transformOrigin: "216.46px 144.64px",
          }}
          id="elc9tl3hlcq1t"
          className="animable"
        />
        <polygon
          points="302.44 205.85 347.37 83.43 291.43 83.43 246.5 205.85 302.44 205.85"
          style={{
            fill: "rgb(245, 245, 245)",
            transformOrigin: "296.935px 144.64px",
          }}
          id="elvoscu7qvp9"
          className="animable"
        />
        <rect
          x={418.19}
          y={257.8}
          width={19.92}
          height={124.6}
          style={{
            fill: "rgb(230, 230, 230)",
            transformOrigin: "428.15px 320.1px",
          }}
          id="el8qxxv7vs0ll"
          className="animable"
        />
        <polygon
          points="432.87 382.4 438.11 382.4 438.11 372.81 427.38 372.81 432.87 382.4"
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "432.745px 377.605px",
          }}
          id="elccnwvv7cumm"
          className="animable"
        />
        <rect
          x={234.53}
          y={257.8}
          width={19.92}
          height={124.6}
          style={{
            fill: "rgb(230, 230, 230)",
            transformOrigin: "244.49px 320.1px",
          }}
          id="elrturrv1clgr"
          className="animable"
        />
        <rect
          x={249.88}
          y={257.8}
          width={188.23}
          height={122.01}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "343.995px 318.805px",
          }}
          id="elnkflf5d9q7n"
          className="animable"
        />
        <path
          d="M434.55,297.17H253.45V262.71h181.1Zm-180.1-1h179.1V263.71H254.45Z"
          style={{
            fill: "rgb(230, 230, 230)",
            transformOrigin: "344px 279.94px",
          }}
          id="elkz2ep1y5xi"
          className="animable"
        />
        <path
          d="M402.67,279.44H288.84c-5.28,0-10.37-4.26-14-11.7l-.34-.71H417l-.34.71C413,275.18,408,279.44,402.67,279.44ZM276.14,268c3.38,6.63,8,10.41,12.7,10.41H402.67c4.72,0,9.32-3.78,12.7-10.41Z"
          style={{
            fill: "rgb(230, 230, 230)",
            transformOrigin: "345.75px 273.235px",
          }}
          id="elg45uouj7dca"
          className="animable"
        />
        <polygon
          points="255.12 382.4 249.88 382.4 249.88 372.81 260.61 372.81 255.12 382.4"
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "255.245px 377.605px",
          }}
          id="elgg2rrqz425g"
          className="animable"
        />
        <path
          d="M434.55,336H253.45V301.58h181.1Zm-180.1-1h179.1V302.58H254.45Z"
          style={{
            fill: "rgb(230, 230, 230)",
            transformOrigin: "344px 318.79px",
          }}
          id="elfa4otohlsta"
          className="animable"
        />
        <path
          d="M402.67,318.31H288.84c-5.28,0-10.37-4.27-14-11.7l-.34-.72H417l-.34.72C413,314,408,318.31,402.67,318.31ZM276.14,306.89c3.38,6.63,8,10.42,12.7,10.42H402.67c4.72,0,9.32-3.79,12.7-10.42Z"
          style={{
            fill: "rgb(230, 230, 230)",
            transformOrigin: "345.75px 312.1px",
          }}
          id="el34u3joimfhm"
          className="animable"
        />
        <path
          d="M434.55,374.9H253.45V340.44h181.1Zm-180.1-1h179.1V341.44H254.45Z"
          style={{
            fill: "rgb(230, 230, 230)",
            transformOrigin: "344px 357.67px",
          }}
          id="elxxs8tqz28x"
          className="animable"
        />
        <path
          d="M402.67,357.17H288.84c-5.28,0-10.37-4.26-14-11.69l-.34-.72H417l-.34.72C413,352.91,408,357.17,402.67,357.17ZM276.14,345.76c3.38,6.63,8,10.41,12.7,10.41H402.67c4.72,0,9.32-3.78,12.7-10.41Z"
          style={{
            fill: "rgb(230, 230, 230)",
            transformOrigin: "345.75px 350.965px",
          }}
          id="elcgxokky395t"
          className="animable"
        />
        <polygon
          points="120.98 274.28 120.98 276.7 119.44 276.7 118.16 295.67 99.32 295.67 98.05 276.7 96.5 276.7 96.5 274.28 120.98 274.28"
          style={{
            fill: "rgb(230, 230, 230)",
            transformOrigin: "108.74px 284.975px",
          }}
          id="elibw6tiv4jh"
          className="animable"
        />
        <path
          d="M114.78,227.21c-1.54-11.3-16.79-14.52-36.14-14.94-18.41-.39-24.31-6.9-24.31-6.9s.06,26.37,26.59,35.93C106.11,250.36,116.33,238.53,114.78,227.21Z"
          style={{
            fill: "rgb(230, 230, 230)",
            transformOrigin: "84.6311px 224.941px",
          }}
          id="elbqv4mcevrqq"
          className="animable"
        />
        <path
          d="M105.38,252.82c.32-4.64,7.74-13.18,25.59-9.3,12.9,2.81,17.7-3.88,17.7-3.88s-1.2,18.88-20.6,24.55C109.64,269.58,104.81,261,105.38,252.82Z"
          style={{
            fill: "rgb(230, 230, 230)",
            transformOrigin: "127.003px 252.756px",
          }}
          id="el1f05xct90c1"
          className="animable"
        />
        <path
          d="M109.45,281.4a.69.69,0,0,0,.53-.24c1.29-1.53.23-5.19-1.23-10.26-1.88-6.5-4.22-14.6-2.24-20.47a82.34,82.34,0,0,1,3.84-9.08c2.8-5.92,5.22-11,3.2-16.38a.69.69,0,1,0-1.29.49c1.81,4.79-.38,9.43-3.16,15.31a85.59,85.59,0,0,0-3.9,9.22c-2.11,6.28.29,14.61,2.22,21.29,1.14,4,2.32,8,1.51,9a.68.68,0,0,0,.08,1A.66.66,0,0,0,109.45,281.4Z"
          style={{
            fill: "rgb(230, 230, 230)",
            transformOrigin: "109.338px 252.961px",
          }}
          id="elgviknds8ucq"
          className="animable"
        />
      </g>
      <g
        id="freepik--Shadow--inject-2"
        className="animable"
        style={{
          transformOrigin: "250px 416.24px",
        }}
      >
        <ellipse
          id="freepik--path--inject-2"
          cx={250}
          cy={416.24}
          rx={193.89}
          ry={11.32}
          style={{
            fill: "rgb(245, 245, 245)",
            transformOrigin: "250px 416.24px",
          }}
          className="animable"
        />
      </g>
      <g
        id="freepik--Login--inject-2"
        className="animable"
        style={{
          transformOrigin: "178.62px 243.32px",
        }}
      >
        <polygon
          points="249.96 361.05 107.96 361.05 100.19 132.68 242.19 132.68 249.96 361.05"
          style={{
            fill: "#2EB44C",
            transformOrigin: "175.075px 246.865px",
          }}
          id="elm8esrnchyo"
          className="animable"
        />
        <polygon
          points="257.05 353.96 115.05 353.96 107.27 125.59 249.27 125.59 257.05 353.96"
          style={{
            fill: "#2EB44C",
            transformOrigin: "182.16px 239.775px",
          }}
          id="eln3wn2n5dnxr"
          className="animable"
        />
        <g id="elrfiwhkr8pkh">
          <polygon
            points="257.05 353.96 115.05 353.96 107.27 125.59 249.27 125.59 257.05 353.96"
            style={{
              fill: "rgb(255, 255, 255)",
              opacity: 0.3,
              transformOrigin: "182.16px 239.775px",
            }}
            className="animable"
            id="elxg3zfvefrgd"
          />
        </g>
        <g id="el72scany635d">
          <ellipse
            cx={180.5}
            cy={191.09}
            rx={39.27}
            ry={40.63}
            style={{
              fill: "#2EB44C",
              transformOrigin: "180.5px 191.09px",
              transform: "rotate(-45.49deg)",
            }}
            className="animable"
            id="el2m7mv5ffign"
          />
        </g>
        <path
          d="M213.47,191.09a32,32,0,0,1-3.45,15.7,31.59,31.59,0,0,1-28.4,17.26,34.26,34.26,0,0,1-29.56-17.26,33.49,33.49,0,0,1-4.52-15.7,31.64,31.64,0,0,1,31.84-33A34.36,34.36,0,0,1,213.47,191.09Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "180.502px 191.07px",
          }}
          id="elr47wlu76vh"
          className="animable"
        />
        <g id="ely1gxpe70nk">
          <path
            d="M210,206.79a31.59,31.59,0,0,1-28.4,17.26,34.26,34.26,0,0,1-29.56-17.26A50.59,50.59,0,0,1,180.74,198,53.48,53.48,0,0,1,210,206.79Z"
            style={{
              fill: "#2EB44C",
              opacity: 0.6,
              transformOrigin: "181.02px 211.025px",
            }}
            className="animable"
            id="elivgtahc1szd"
          />
        </g>
        <g id="elnzjj6m3ybgr">
          <ellipse
            cx={180.12}
            cy={179.81}
            rx={14.44}
            ry={14.94}
            style={{
              fill: "#2EB44C",
              opacity: 0.6,
              transformOrigin: "180.12px 179.81px",
              transform: "rotate(-45.49deg)",
            }}
            className="animable"
            id="eltgjbvdhgsfs"
          />
        </g>
        <polygon
          points="228.52 271.26 137.94 271.26 137.33 253.26 227.91 253.26 228.52 271.26"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "182.925px 262.26px",
          }}
          id="elycn9rxxdxdp"
          className="animable"
        />
        <polygon
          points="229.62 303.43 139.03 303.43 138.42 285.43 229.01 285.43 229.62 303.43"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "184.02px 294.43px",
          }}
          id="elrxiahyce3g"
          className="animable"
        />
        <path
          d="M194.66,287.91l-21.83,3a1.52,1.52,0,0,0-1.32,1.8l2.87,14.63a1.76,1.76,0,0,0,1.94,1.37l21.83-3a1.53,1.53,0,0,0,1.31-1.81l-2.86-14.63A1.75,1.75,0,0,0,194.66,287.91Zm-6.27,14.82-4,.53-.39-4.72a2.8,2.8,0,0,1-1.63-2,2.49,2.49,0,0,1,2.16-3,2.86,2.86,0,0,1,3.16,2.23,2.45,2.45,0,0,1-.78,2.33Z"
          style={{
            fill: "#2EB44C",
            transformOrigin: "185.485px 298.309px",
          }}
          id="elro9c93uw03"
          className="animable"
        />
        <path
          d="M192.5,287.12h0a1.56,1.56,0,0,1-1.72-1.21l-.28-1.43a8.23,8.23,0,0,0-8.62-6.56,7,7,0,0,0-6.46,8.39l.32,1.65a1.34,1.34,0,0,1-1.17,1.6h0a1.54,1.54,0,0,1-1.72-1.21l-.27-1.39a9.85,9.85,0,0,1,8-11.75,11.2,11.2,0,0,1,12.8,8.67l.32,1.64A1.34,1.34,0,0,1,192.5,287.12Z"
          style={{
            fill: "#2EB44C",
            transformOrigin: "183.047px 282.315px",
          }}
          id="elj9lnd5w86a"
          className="animable"
        />
        <polygon
          points="214.67 328.64 155.7 328.64 155.31 316.92 214.27 316.92 214.67 328.64"
          style={{
            fill: "#2EB44C",
            transformOrigin: "184.99px 322.78px",
          }}
          id="eljehacaqywu"
          className="animable"
        />
        <path
          d="M186.22,262.26a3.16,3.16,0,0,1-3.18,3.29,3.44,3.44,0,0,1-3.41-3.29,3.16,3.16,0,0,1,3.18-3.29A3.43,3.43,0,0,1,186.22,262.26Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "182.925px 262.26px",
          }}
          id="el1cdlbln0nquh"
          className="animable"
        />
        <path
          d="M172,262.26a3.15,3.15,0,0,1-3.18,3.29,3.43,3.43,0,0,1-3.4-3.29,3.16,3.16,0,0,1,3.18-3.29A3.42,3.42,0,0,1,172,262.26Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "168.71px 262.26px",
          }}
          id="el7hw38zcm5ol"
          className="animable"
        />
        <path
          d="M157.87,262.26a3.16,3.16,0,0,1-3.18,3.29,3.42,3.42,0,0,1-3.4-3.29,3.16,3.16,0,0,1,3.18-3.29A3.42,3.42,0,0,1,157.87,262.26Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "154.58px 262.26px",
          }}
          id="elff7k12gsvk4"
          className="animable"
        />
        <path
          d="M200.39,262.26a3.16,3.16,0,0,1-3.18,3.29,3.42,3.42,0,0,1-3.4-3.29A3.15,3.15,0,0,1,197,259,3.42,3.42,0,0,1,200.39,262.26Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "197.1px 262.275px",
          }}
          id="els39r4ll9vot"
          className="animable"
        />
        <path
          d="M214.56,262.26a3.15,3.15,0,0,1-3.18,3.29,3.43,3.43,0,0,1-3.4-3.29,3.16,3.16,0,0,1,3.18-3.29A3.42,3.42,0,0,1,214.56,262.26Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "211.27px 262.26px",
          }}
          id="elwoi0z542avq"
          className="animable"
        />
      </g>
      <g
        id="freepik--Character--inject-2"
        className="animable"
        style={{
          transformOrigin: "320.664px 271.895px",
        }}
      >
        <path
          d="M333.91,174.28c1.33,2.14,2.44,4.18,3.58,6.31s2.17,4.27,3.18,6.46a92.36,92.36,0,0,1,5.1,13.73c.18.59.31,1.22.46,1.83l.22.93.11.46.13.76a13.68,13.68,0,0,1-.31,5.16,29.24,29.24,0,0,1-3.12,7.45A59.22,59.22,0,0,1,334.38,229l-4.21-3.18a111.39,111.39,0,0,0,5.63-12,24.86,24.86,0,0,0,1.53-5.63,4.46,4.46,0,0,0-.08-1.77l-.4-1.22c-.16-.5-.31-1-.5-1.51a113.4,113.4,0,0,0-5.27-12.08c-2-4-4.15-8-6.3-11.82Z"
          style={{
            fill: "rgb(255, 139, 123)",
            transformOrigin: "335.793px 201.64px",
          }}
          id="elha2aarnz3e5"
          className="animable"
        />
        <path
          d="M331.24,224.43l-4.6,2.43,5.28,6.18s3.3-2.23,3.45-5.63Z"
          style={{
            fill: "rgb(255, 139, 123)",
            transformOrigin: "331.005px 228.735px",
          }}
          id="elj8hlx0qh66"
          className="animable"
        />
        <polygon
          points="320.47 230.67 326.12 235.16 331.92 233.04 326.64 226.86 320.47 230.67"
          style={{
            fill: "rgb(255, 139, 123)",
            transformOrigin: "326.195px 231.01px",
          }}
          id="eldb0vjdrvb2"
          className="animable"
        />
        <path
          d="M301.15,141.56c-.11.56-.5.95-.86.88s-.57-.59-.46-1.14.5-1,.87-.88S301.26,141,301.15,141.56Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "300.491px 141.424px",
          }}
          id="elm9pw5h3lghe"
          className="animable"
        />
        <path
          d="M300.7,142.52a20.26,20.26,0,0,1-3.62,4.22,3.29,3.29,0,0,0,2.56,1Z"
          style={{
            fill: "rgb(255, 86, 82)",
            transformOrigin: "298.89px 145.133px",
          }}
          id="ell7r1gpc6bd9"
          className="animable"
        />
        <path
          d="M298.9,162.14l0-6.56,8.68,1s-.14,5.64-5,7.3Z"
          style={{
            fill: "rgb(255, 139, 123)",
            transformOrigin: "303.24px 159.73px",
          }}
          id="el80ahn3glwyu"
          className="animable"
        />
        <polygon
          points="301.4 150.86 307.51 152.62 307.62 156.63 298.94 155.58 301.4 150.86"
          style={{
            fill: "rgb(255, 139, 123)",
            transformOrigin: "303.28px 153.745px",
          }}
          id="elrwtyvg8ygl"
          className="animable"
        />
        <g id="el2jkf8buz46i">
          <path
            d="M307.51,152.62l-6.11-1.76-1.19,2.28a9.73,9.73,0,0,0,5.64,8.48,9.56,9.56,0,0,0,1.77-5Z"
            style={{
              opacity: 0.2,
              transformOrigin: "303.915px 156.24px",
            }}
            className="animable"
            id="elsrccpmzblv"
          />
        </g>
        <path
          d="M303.81,138.89a.34.34,0,0,0,.25-.56,3.32,3.32,0,0,0-2.84-1.1.33.33,0,0,0-.27.38.34.34,0,0,0,.38.28h0a2.63,2.63,0,0,1,2.24.89A.34.34,0,0,0,303.81,138.89Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "302.543px 138.051px",
          }}
          id="el46k6mwwtux7"
          className="animable"
        />
        <path
          d="M316.59,151.68c-1,5.15-1.62,12.28,2,15.71,0,0-1.4,5.22-11,5.22-10.51,0-5.05-4.58-5.05-4.58,4.37-1.36,4.33-6.33,3.33-10.33Z"
          style={{
            fill: "rgb(255, 139, 123)",
            transformOrigin: "309.856px 162.145px",
          }}
          id="el0i58bzorpxwl"
          className="animable"
        />
        <path
          d="M320.54,170.83c.94-1.32.9-5.51-.1-6.25-1.61-1.19-8.52-1.47-17.81.67,1,4.06-1.15,6.18-1.15,6.18Z"
          style={{
            fill: "#2EB44C",
            transformOrigin: "311.349px 167.546px",
          }}
          id="el0blirwg76ab"
          className="animable"
        />
        <path
          d="M351.8,408.24a2.29,2.29,0,0,1-1.76-.1.68.68,0,0,1-.16-.77.77.77,0,0,1,.43-.49c.95-.46,3.13.43,3.23.47a.16.16,0,0,1,.1.15.19.19,0,0,1-.09.16A9.83,9.83,0,0,1,351.8,408.24Zm-1.15-1.13a.84.84,0,0,0-.19.07.42.42,0,0,0-.26.28c-.09.3,0,.4.06.43.38.32,1.78,0,2.77-.37A5.49,5.49,0,0,0,350.65,407.11Z"
          style={{
            fill: "#2EB44C",
            transformOrigin: "351.732px 407.559px",
          }}
          id="elof3y5k0r2h"
          className="animable"
        />
        <path
          d="M353.51,407.67h-.08c-.8-.23-2.5-1.44-2.52-2.23a.55.55,0,0,1,.43-.54.86.86,0,0,1,.73.06c.94.48,1.54,2.42,1.56,2.51a.16.16,0,0,1,0,.16A.14.14,0,0,1,353.51,407.67Zm-2-2.49-.08,0c-.2.07-.2.17-.2.22,0,.47,1.14,1.42,2,1.8-.21-.56-.67-1.67-1.28-2A.58.58,0,0,0,351.52,405.18Z"
          style={{
            fill: "#2EB44C",
            transformOrigin: "352.281px 406.259px",
          }}
          id="elhq8wiapplcr"
          className="animable"
        />
        <path
          d="M299.86,409.42c-.9,0-1.77-.13-2.06-.53a.63.63,0,0,1,0-.73.83.83,0,0,1,.53-.39c1.17-.32,3.63,1.08,3.73,1.14a.15.15,0,0,1,.08.17.16.16,0,0,1-.13.14A12.2,12.2,0,0,1,299.86,409.42Zm-1.11-1.37a1,1,0,0,0-.32,0,.51.51,0,0,0-.33.23c-.12.21-.07.31,0,.37.33.46,2.1.47,3.41.28A7.61,7.61,0,0,0,298.75,408.05Z"
          style={{
            fill: "#2EB44C",
            transformOrigin: "299.914px 408.571px",
          }}
          id="elvzbn5rr3ppe"
          className="animable"
        />
        <path
          d="M302,409.22h-.07c-.85-.38-2.53-1.9-2.4-2.7,0-.19.16-.42.62-.46a1.2,1.2,0,0,1,.92.28,4.78,4.78,0,0,1,1.1,2.71.2.2,0,0,1-.07.15A.19.19,0,0,1,302,409.22Zm-1.72-2.85h-.1c-.3,0-.32.14-.32.18-.08.48,1.06,1.68,1.93,2.2a3.87,3.87,0,0,0-.93-2.17A.88.88,0,0,0,300.27,406.37Z"
          style={{
            fill: "#2EB44C",
            transformOrigin: "300.846px 407.636px",
          }}
          id="elic4lr2kya9"
          className="animable"
        />
        <polygon
          points="309.72 409.06 302.69 409.06 301.99 392.77 309.02 392.77 309.72 409.06"
          style={{
            fill: "rgb(255, 139, 123)",
            transformOrigin: "305.855px 400.915px",
          }}
          id="elz18fp8dzhj"
          className="animable"
        />
        <polygon
          points="361.35 405.8 354.48 407.29 347.57 392.12 354.45 390.63 361.35 405.8"
          style={{
            fill: "rgb(255, 139, 123)",
            transformOrigin: "354.46px 398.96px",
          }}
          id="elpdbekzit7ce"
          className="animable"
        />
        <path
          d="M353.81,406.6l7.73-1.67a.61.61,0,0,1,.68.34l2.68,5.82a1,1,0,0,1-.74,1.44c-2.7.53-4.05.66-7.44,1.39-2.09.46-6.81,1.61-9.7,2.23s-3.88-2.15-2.75-2.66c5.05-2.31,7.15-4.34,8.52-6.23A1.71,1.71,0,0,1,353.81,406.6Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "354.411px 410.578px",
          }}
          id="ely797wsfwyv9"
          className="animable"
        />
        <path
          d="M302.74,408.24h7.68a.62.62,0,0,1,.6.48l1.39,6.25a1,1,0,0,1-1,1.26c-2.77-.05-6.77-.21-10.25-.21-4.08,0-7.59.22-12.37.22-2.89,0-3.69-2.92-2.48-3.19,5.5-1.2,10-1.33,14.75-4.26A3.27,3.27,0,0,1,302.74,408.24Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "299.105px 412.24px",
          }}
          id="ele3nhvlkrvf5"
          className="animable"
        />
        <path
          d="M331.17,169c6.59,1.51,12,18.34,12,18.34L330,197.41a28.93,28.93,0,0,1-7-13C320.67,175.59,324.43,167.44,331.17,169Z"
          style={{
            fill: "#2EB44C",
            transformOrigin: "332.739px 183.109px",
          }}
          id="elh4kynufgs5v"
          className="animable"
        />
        <g id="elnavprd2l5z">
          <path
            d="M331.17,169c6.59,1.51,12,18.34,12,18.34L330,197.41a28.93,28.93,0,0,1-7-13C320.67,175.59,324.43,167.44,331.17,169Z"
            style={{
              opacity: 0.4,
              transformOrigin: "332.739px 183.109px",
            }}
            className="animable"
            id="el4eti5ifwfex"
          />
        </g>
        <path
          d="M296.5,189.07c-1.64,1.77-3.28,3.35-5,5-.85.8-1.71,1.6-2.59,2.37l-1.33,1.17-.33.29-.57.46a8,8,0,0,1-1.93,1.14,6.29,6.29,0,0,1-2.44.5,6,6,0,0,1-2.1-.37,5.91,5.91,0,0,1-2-1.27,6,6,0,0,1-1.56-2.59,6.81,6.81,0,0,1-.29-1.49,10.1,10.1,0,0,1,0-1.78c.05-.48.11-.9.19-1.29a24.76,24.76,0,0,1,1.16-4,35.81,35.81,0,0,1,1.55-3.62c.64-1.27,1.25-2.2,1.86-3.26a81.65,81.65,0,0,1,8.45-11.46,77.42,77.42,0,0,1,10.25-9.72l3.68,3.79c-2.58,3.46-5.12,7.17-7.47,10.82a117.26,117.26,0,0,0-6.4,11.25c-.43.94-1,2-1.27,2.76a26.83,26.83,0,0,0-.93,2.7,17.45,17.45,0,0,0-.57,2.52,3.94,3.94,0,0,0,0,.4c0,.08,0,.12,0-.09a2.78,2.78,0,0,0-.13-.66,4.29,4.29,0,0,0-1.15-1.86,4.9,4.9,0,0,0-1.65-1,4.78,4.78,0,0,0-1.68-.31,4.08,4.08,0,0,0-1.61.31,3.68,3.68,0,0,0-.5.25l.38-.35,1.21-1.1,2.39-2.24c1.58-1.49,3.17-3.08,4.67-4.56Z"
          style={{
            fill: "rgb(255, 139, 123)",
            transformOrigin: "289.91px 179.575px",
          }}
          id="elgdy0o60ifiw"
          className="animable"
        />
        <g id="elamj8hcutvot">
          <path
            d="M325.52,181.36c3.58,3.54,4.75,10,5,15.6l-.58.45a28.93,28.93,0,0,1-7-13c-.13-.52-.25-1-.34-1.57Z"
            style={{
              opacity: 0.2,
              transformOrigin: "326.56px 189.385px",
            }}
            className="animable"
            id="elsxsw97tyr1s"
          />
        </g>
        <path
          d="M289.51,176c-1.44,7.6-2.34,23.21,1.83,54.11H329c.26-5.77-3.37-33.95,2.2-61.16a99,99,0,0,0-12.6-1.59,139.73,139.73,0,0,0-16,0c-1.76.16-3.5.42-5,.69A10,10,0,0,0,289.51,176Z"
          style={{
            fill: "#2EB44C",
            transformOrigin: "309.753px 198.62px",
          }}
          id="el4hm099sr011"
          className="animable"
        />
        <g id="el70x0z4td8gp">
          <path
            d="M289.51,176c-1.44,7.6-2.34,23.21,1.83,54.11H329c.26-5.77-3.37-33.95,2.2-61.16a99,99,0,0,0-12.6-1.59,139.73,139.73,0,0,0-16,0c-1.76.16-3.5.42-5,.69A10,10,0,0,0,289.51,176Z"
            style={{
              opacity: 0.4,
              transformOrigin: "309.753px 198.62px",
            }}
            className="animable"
            id="ely8r269hci9a"
          />
        </g>
        <g id="elphzcymroxw">
          <path
            d="M295.27,187.27l-6.8-1.63c-.21,4.12-.23,9.22.07,15.53C291.67,197.61,295.67,192.05,295.27,187.27Z"
            style={{
              opacity: 0.2,
              transformOrigin: "291.806px 193.405px",
            }}
            className="animable"
            id="el03bnph5svo4s"
          />
        </g>
        <g id="el66fjgzajgbs">
          <polygon
            points="301.99 392.77 302.35 401.17 309.39 401.17 309.03 392.77 301.99 392.77"
            style={{
              opacity: 0.2,
              transformOrigin: "305.69px 396.97px",
            }}
            className="animable"
            id="el1d9tti3bax6"
          />
        </g>
        <g id="elncfd4bjlf5">
          <polygon
            points="354.45 390.63 347.57 392.12 351.13 399.94 358.01 398.45 354.45 390.63"
            style={{
              opacity: 0.2,
              transformOrigin: "352.79px 395.285px",
            }}
            className="animable"
            id="elddruaes1b9k"
          />
        </g>
        <path
          d="M319.93,144.16c-1.8,6.74-2.52,10.78-6.68,13.58C307,162,299.2,157.12,299,150c-.22-6.43,2.77-16.38,10-17.74A9.52,9.52,0,0,1,319.93,144.16Z"
          style={{
            fill: "rgb(255, 139, 123)",
            transformOrigin: "309.632px 145.714px",
          }}
          id="el9ocdjhb37gn"
          className="animable"
        />
        <path
          d="M304.15,144.41c-.34-.9-1.89-7.87-.43-9.68s18.11-.5,20.57,1.92c4.09,4,1.25,7.6-3.22,12.23-3.36,3.48-3.49,7.4-5.19,8-3,1-7.58.06-8.5-3.11S304.15,144.41,304.15,144.41Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "314.686px 145.573px",
          }}
          id="elllhndj8n3l8"
          className="animable"
        />
        <path
          d="M322.27,128.72c-5.88-2.5-16.91.27-22.87-1.17a9.18,9.18,0,0,0,7.61,9.36,78.65,78.65,0,0,0,15,1.39C328.55,138,327.27,130.85,322.27,128.72Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "312.935px 132.925px",
          }}
          id="el07y7ldegzjl7"
          className="animable"
        />
        <path
          d="M323.19,136.86a11.21,11.21,0,0,0,6.23-1.19C330.52,138.33,324.47,139.06,323.19,136.86Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "326.371px 136.914px",
          }}
          id="eltoiwjvm1fxk"
          className="animable"
        />
        <path
          d="M307.35,144.69a7.91,7.91,0,0,1-2,4.67c-1.54,1.69-3.14.63-3.41-1.42-.24-1.86.26-5,2.14-5.9S307.48,142.6,307.35,144.69Z"
          style={{
            fill: "rgb(255, 139, 123)",
            transformOrigin: "304.621px 145.984px",
          }}
          id="ela5r6yeu3u54"
          className="animable"
        />
        <path
          d="M304.69,230.14s8,53.75,12.37,73.89c4.79,22.05,30.53,95,30.53,95l12.59-2.73s-18-59.46-22-90.29c-3.1-23.68-9.19-75.89-9.19-75.89Z"
          style={{
            fill: "#2EB44C",
            transformOrigin: "332.435px 314.575px",
          }}
          id="elnqi136xkem"
          className="animable"
        />
        <path
          d="M344.57,394.84c-.05,0,2.35,4.56,2.35,4.56l14.16-3.07-1.19-4.26Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "352.825px 395.735px",
          }}
          id="elpiteizhud8"
          className="animable"
        />
        <g id="elmpc29p4832">
          <path
            d="M309.74,249.29c9.23,1.81,8.25,36.23,7.14,53.94-2.66-12.52-6.56-36.6-9.22-53.69A3.15,3.15,0,0,1,309.74,249.29Z"
            style={{
              opacity: 0.3,
              transformOrigin: "312.618px 276.222px",
            }}
            className="animable"
            id="el5pwi9tn2dt9"
          />
        </g>
        <path
          d="M291.34,230.14s-1,52.59-.2,74c.8,22.31,8.59,95.36,8.59,95.36h11.92s.43-71.75,1-93.66c.58-23.88,3.46-75.74,3.46-75.74Z"
          style={{
            fill: "#2EB44C",
            transformOrigin: "303.47px 314.8px",
          }}
          id="elpe4mj6ulvr7"
          className="animable"
        />
        <path
          d="M297.44,394.8c-.06,0,.67,4.94.67,4.94H312.6l.4-4.41Z"
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "305.218px 397.27px",
          }}
          id="elwpp59u3941"
          className="animable"
        />
        <path
          d="M304.25,178.34c3.37,9.09-16.44,19.8-16.44,19.8l-8.58-11S285,175.72,292,170.58C300.65,164.24,301.68,171.42,304.25,178.34Z"
          style={{
            fill: "#2EB44C",
            transformOrigin: "291.933px 183.135px",
          }}
          id="el6gfpnbp41dp"
          className="animable"
        />
        <g id="elakzeel1os8b">
          <path
            d="M304.25,178.34c3.37,9.09-16.44,19.8-16.44,19.8l-8.58-11S285,175.72,292,170.58C300.65,164.24,301.68,171.42,304.25,178.34Z"
            style={{
              opacity: 0.4,
              transformOrigin: "291.933px 183.135px",
            }}
            className="animable"
            id="elhifwqrl7z97"
          />
        </g>
      </g>
      <g
        id="freepik--Thinking--inject-2"
        className="animable"
        style={{
          transformOrigin: "373.36px 94.625px",
        }}
      >
        <ellipse
          cx={381.87}
          cy={86.33}
          rx={36.09}
          ry={23}
          style={{
            fill: "#2EB44C",
            transformOrigin: "381.87px 86.33px",
          }}
          id="el1md0zsw4j5a"
          className="animable"
        />
        <path
          d="M368.85,97.91a10.11,10.11,0,0,1-9.93-8.39A10.22,10.22,0,0,1,366.63,78h0a10.06,10.06,0,0,1,10.82,4.52l23.17-4.78a3.62,3.62,0,0,1,4.24,2.49,3.49,3.49,0,0,1-2.67,4.31l-.6.12a.59.59,0,0,0-.42.31.63.63,0,0,0,0,.52l.41,1.06a1.59,1.59,0,0,1-.62,1.93,1.9,1.9,0,0,0-.89,1.41,1.57,1.57,0,0,1-1.21,1.39l-.47.1a1.56,1.56,0,0,1-1.66-.8,1.95,1.95,0,0,0-3.61.75,1.55,1.55,0,0,1-1.2,1.38l-.47.1a1.57,1.57,0,0,1-1.66-.8,2,2,0,0,0-1.39-.94A1.59,1.59,0,0,1,387,89.5l0-1.14a.62.62,0,0,0-.74-.58l-7.39,1.52a10.07,10.07,0,0,1-7.95,8.4A10.36,10.36,0,0,1,368.85,97.91Zm-2-19a9.21,9.21,0,0,0-7,10.42,9.11,9.11,0,0,0,18-.52l0-.36L386,86.8A1.62,1.62,0,0,1,388,88.32l0,1.14a.59.59,0,0,0,.48.57,2.91,2.91,0,0,1,2.11,1.43.58.58,0,0,0,.59.32l.46-.1a.56.56,0,0,0,.42-.52A2.95,2.95,0,0,1,397.56,90a.57.57,0,0,0,.58.32l.48-.1a.56.56,0,0,0,.41-.52,2.9,2.9,0,0,1,1.37-2.15.61.61,0,0,0,.21-.72l-.41-1.06a1.61,1.61,0,0,1,1.18-2.16l.61-.13a2.51,2.51,0,0,0,1.91-3.07,2.61,2.61,0,0,0-3.08-1.77L377,83.59l-.18-.31a9,9,0,0,0-9.92-4.35Zm2,15.28a6.41,6.41,0,0,1-3.51-1,6.41,6.41,0,1,1,4.81.92h0A6.37,6.37,0,0,1,368.86,94.21Zm0-11.81a6,6,0,0,0-1.11.11A5.41,5.41,0,0,0,370,93.1h0a5.41,5.41,0,0,0,1.88-9.81A5.32,5.32,0,0,0,368.89,82.4Z"
          style={{
            fill: "rgb(255, 255, 255)",
            transformOrigin: "381.886px 87.7831px",
          }}
          id="elruhyoppuc0s"
          className="animable"
        />
        <g id="eloug4jcxrn7">
          <ellipse
            cx={352.5}
            cy={115.26}
            rx={11.86}
            ry={7.56}
            style={{
              fill: "#2EB44C",
              opacity: 0.6,
              transformOrigin: "352.5px 115.26px",
            }}
            className="animable"
            id="elgh8bd9g5xlw"
          />
        </g>
        <g id="eluk96m0nl9pe">
          <ellipse
            cx={333.63}
            cy={122.82}
            rx={4.87}
            ry={3.1}
            style={{
              fill: "#2EB44C",
              opacity: 0.3,
              transformOrigin: "333.63px 122.82px",
            }}
            className="animable"
            id="elw8nfkrtzquf"
          />
        </g>
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />
          <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="hover" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />
          <feFlood floodColor="#ff0000" floodOpacity={0.5} result="PINK" />
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
          <feColorMatrix
            type="matrix"
            values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
          />
        </filter>
      </defs>
    </svg>
  );
};
export default ForgetPasswordSvg;
