import { useDispatch } from "react-redux";
import BackgroundBlob from "../../components/atoms/BackgroundBlob";
import { openModal, setLoading } from "../../redux/reducers/uiReducer";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/AuthContext";
import { useEffect, useState } from "react";
import api from "../../helpers/api";
import { IPlan } from "../../types/CommonType";
import PricingTickSvg from "../../assets/svg-icons/PricingTickSvg";

interface PricingPageProps {}

const PricingPage: React.FC<PricingPageProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [plans, setPlans] = useState<IPlan[]>([]);

  useEffect(() => {
    dispatch(setLoading({ isLoading: true, label: "Getting Plans..." }));
    api
      .GET<IPlan[]>(`/plans`)
      .then((response) => {
        if (response?.data) {
          setPlans(response?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch(setLoading({ isLoading: false }));
      });
  }, []);

  return (
    <div className="max-w-7xl text-center md:my-15 my-10 w-full px-4">
      <p className="md:mb-12 mb-6 text-2xl font-medium">Plans And Pricing</p>
      <div className="flex flex-col md:flex-row justify-start md:gap-20 gap-12 px-4 md:px-20 md:items-stretch">
        {plans.map((data) => {
          return (
            <div
              className="bg-geovetraGreen-500 text-white w-full p-5 rounded-2xl flex flex-col gap-3 shadow-2xl"
              style={{
                backgroundColor: `${
                  data.title === "business" ? "white" : "#2eb44c"
                }`,
                color: `${data.title === "business" ? "black" : "white"}`,
              }}
            >
              <div className="flex-grow">
                <p className="md:text-3xl text-2xl font-semibold mb-2 capitalize">
                  {data.title}
                </p>
                <p className="text-sm md:text-base font-medium mb-2">
                  {data.title === "business"
                    ? " Perfect for growing businesses with expanding delivery needs."
                    : "Perfect for growing businesses with expanding delivery needs."}
                </p>
                <p className="text-4xl font-bold">₹ {data.cost_per_day}</p>
                <p className="text-base font-normal mb-4">PER DAY</p>

                <div className="flex gap-1 items-start justify-start px-4 ">
                  <div className="md:mt-1">
                    <PricingTickSvg
                      strockColor={`${
                        data.title === "business" ? "black" : "white"
                      }`}
                    />
                  </div>
                  <p className="md:text-base text-xs mb-2 text-start">
                    {data.deliveries_per_day == 0 ? (
                      <p>
                        Unlimited <i className="fa fa-infinity"></i>
                      </p>
                    ) : (
                      <p>Maximum 15 deliveries Per Day </p>
                    )}
                  </p>
                </div>
                <div className="flex gap-1 items-start justify-start px-4 ">
                  <div className="md:mt-1">
                    <PricingTickSvg
                      strockColor={`${
                        data.title === "business" ? "black" : "white"
                      }`}
                    />
                  </div>
                  <p className="md:text-base text-xs mb-2 text-start">
                    <p>
                      {data.active_manager_accounts} Manager,{" "}
                      {data.active_operator_accounts} Operator,{" "}
                      {data.active_management_accounts} Management User Accounts
                    </p>
                  </p>
                </div>
                <div className="flex gap-1 items-start justify-start px-4 ">
                  <div className="md:mt-1">
                    <PricingTickSvg
                      strockColor={`${
                        data.title === "business" ? "black" : "white"
                      }`}
                    />
                  </div>
                  <p className="md:text-base text-xs mb-2 text-start">
                    <p>{data.active_warehouse_count} Warehouses</p>
                  </p>
                </div>
                <div className="flex gap-1 items-start justify-start px-4 ">
                  <div className="md:mt-1">
                    <PricingTickSvg
                      strockColor={`${
                        data.title === "business" ? "black" : "white"
                      }`}
                    />
                  </div>
                  <p className="md:text-base text-xs mb-2 text-start">
                    <p>{data.active_vehicles_count} Vehicles</p>
                  </p>
                </div>
                <div className="flex gap-1 items-start justify-start px-4 ">
                  <div className="md:mt-1">
                    <PricingTickSvg
                      strockColor={`${
                        data.title === "business" ? "black" : "white"
                      }`}
                    />
                  </div>
                  <p className="md:text-base text-xs mb-2 text-start">
                    <p>{data.active_drivers_count} Drivers</p>
                  </p>
                </div>
                <div className="flex gap-1 items-start justify-start px-4 ">
                  <div className="md:mt-1">
                    <PricingTickSvg
                      strockColor={`${
                        data.title === "business" ? "black" : "white"
                      }`}
                    />
                  </div>
                  <p className="md:text-base text-xs mb-2 text-start">
                    {data.title === "business" ? (
                      <p>OSM Map Tiles</p>
                    ) : (
                      <p>OSM Map Tiles</p>
                    )}
                  </p>
                </div>
                <div className="flex gap-1 items-start justify-start px-4 ">
                  <div className="md:mt-1">
                    <PricingTickSvg
                      strockColor={`${
                        data.title === "business" ? "black" : "white"
                      }`}
                    />
                  </div>
                  <p className="md:text-base text-xs mb-2 text-start">
                    {data.title === "business" ? (
                      <p>OSM Location Search Engine</p>
                    ) : (
                      <p>OSM Location Search Engine</p>
                    )}
                  </p>
                </div>
                <div className="flex gap-1 items-start justify-start px-4 ">
                  <div className="md:mt-1">
                    <PricingTickSvg
                      strockColor={`${
                        data.title === "business" ? "black" : "white"
                      }`}
                    />
                  </div>
                  <p className="md:text-base text-xs mb-2 text-start">
                    {data.title === "business" ? (
                      <p className="flex items-center justify-start gap-1">
                        High Routing Accuracy -{" "}
                        <span className="bg-geovetraGreen-500 text-white text-[8px] md:text-xs font-medium px-1 md:px-2.5 md:py-0.5 rounded-full">
                          Limited Period Offer
                        </span>{" "}
                      </p>
                    ) : (
                      <p>High Routing Accuracy</p>
                    )}
                  </p>
                </div>
                <div className="flex gap-1 items-start justify-start px-4 ">
                  <div className="md:mt-1">
                    <PricingTickSvg
                      strockColor={`${
                        data.title === "business" ? "black" : "white"
                      }`}
                    />
                  </div>
                  <p className="md:text-base text-xs mb-2 text-start">
                    {data.title === "business" ? (
                      <p>-</p>
                    ) : (
                      <p>Bulk Import Deliveries</p>
                    )}
                  </p>
                </div>
                <div className="flex gap-1 items-start justify-start px-4 ">
                  <div className="md:mt-1">
                    <PricingTickSvg
                      strockColor={`${
                        data.title === "business" ? "black" : "white"
                      }`}
                    />
                  </div>
                  <p className="md:text-base text-xs mb-2 text-start">
                    {data.title === "business" ? (
                      <p>End Customer Notifications</p>
                    ) : (
                      <p>End Customer Notifications</p>
                    )}
                  </p>
                </div>
                <div className="flex gap-1 items-start justify-start px-4 ">
                  <div className="md:mt-1">
                    <PricingTickSvg
                      strockColor={`${
                        data.title === "business" ? "black" : "white"
                      }`}
                    />
                  </div>
                  <p className="md:text-base text-xs mb-2 text-start">
                    {data.title === "business" ? (
                      <p>Realtime Tracking</p>
                    ) : (
                      <p>Realtime Tracking</p>
                    )}
                  </p>
                </div>
                <div className="flex gap-1 items-start justify-start px-4 ">
                  <div className="md:mt-1">
                    <PricingTickSvg
                      strockColor={`${
                        data.title === "business" ? "black" : "white"
                      }`}
                    />
                  </div>
                  <p className="md:text-base text-xs mb-2 text-start">
                    {data.title === "business" ? (
                      <p>-</p>
                    ) : (
                      <p>Realtime Route Estimation</p>
                    )}
                  </p>
                </div>
              </div>
              <button
                className="bg-white text-black py-2 rounded-xl mt-4"
                style={{
                  color: data.title === "business" ? "white" : "black",
                  backgroundColor:
                    data.title === "business" ? "black" : "white",
                }}
                onClick={() => {
                  if (isAuthenticated) {
                    navigate("/checkout");
                  } else {
                    dispatch(openModal("LoginModal"));
                  }
                }}
              >
                Select
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PricingPage;
