import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import { useAuth } from "../../providers/AuthContext";
import PrimaryButton from "./PrimaryButton";
import { ISubscription } from "../../types/CommonType";
import { useEffect, useState } from "react";
import api from "../../helpers/api";
import { useNavigate } from "react-router-dom";
import { openModal } from "../../redux/reducers/uiReducer";
import { useDispatch } from "react-redux";

interface OrganizationNavCardProps {}

const OrganizationNavCard: React.FC<OrganizationNavCardProps> = () => {
  const { selectedCompany } = useAuth();
  const [count, setCount] = useState() as any;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    api
      .GET<ISubscription[]>(
        `${selectedCompany?.domain}/recharges_main?search=active`,
        {}
      )
      .then((response) => {
        const subData = response?.data;
        if (subData) {
          const checkCount = subData.length;
          setCount(checkCount);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  }, [selectedCompany]);

  return (
    <div className="flex flex-col items-center justify-center gap-3 bg-gray-400 rounded-3xl bg-clip-padding backdrop-filter backdrop-blur-xl py-6 bg-opacity-20 button dark">
      {selectedCompany?.logo ? (
        <img
          src={selectedCompany?.logo}
          className="size-14 rounded-2xl border border-gray-400"
        />
      ) : (
        <div className="size-14 rounded-2xl bg-gray-300 flex items-center justify-center">
          <BuildingOffice2Icon className="size-8 text-black" />
        </div>
      )}
      <div className="flex flex-col items-center justify-center text-base font-semibold text-ellipsis overflow-hidden w-40 text-center leading-snug">
        {selectedCompany?.name ? selectedCompany?.name : "Welcome to Geovetra"}
        <p className="text-[10px] font-normal text-gray-500 italic line-clamp-3 flex-1 max-w-40 text-center self-center">
          {selectedCompany?.type_of_company
            ? selectedCompany?.type_of_company
            : "Create your organization to start tracking realtime deliveries"}
        </p>
      </div>

      <div className="w-full px-3">
        {selectedCompany?.name ? (
          <button
            className="py-1.5 px-3 text-sm font-medium text-white bg-geovetraGreen-500 rounded-xl hover:bg-geovetraGreen-600 w-full items-center"
            onClick={() => {
              if (count === 0) {
                navigate("/checkout");
              } else {
                window.open(
                  `${process.env.REACT_APP_URL}/${selectedCompany?.domain}`
                );
              }
            }}
          >
            {count === 0 ? "Get Subscription" : "Visit"}
          </button>
        ) : (
          <button
            className="py-1.5 px-3 text-sm font-medium text-white bg-geovetraGreen-500 rounded-xl hover:bg-geovetraGreen-600 w-full items-center"
            onClick={() => {
              dispatch(openModal("OrganizationModal"));
            }}
          >
            Create Organization
          </button>
        )}
      </div>
    </div>
  );
};

export default OrganizationNavCard;
