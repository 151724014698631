import { useState } from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "../../providers/AuthContext";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { NavLink } from "react-router-dom";
import GetSvg from "../../helpers/GetSvg";
import {
  UserIcon,
  ArrowRightStartOnRectangleIcon,
  BuildingOffice2Icon,
  ChevronDownIcon,
  ChevronUpDownIcon,
  ShoppingBagIcon,
  TicketIcon,
} from "@heroicons/react/24/outline";
import PrimaryButton from "./PrimaryButton";
import { openModal } from "../../redux/reducers/uiReducer";
interface ProfileDropdownCardProps {}

const ProfileDropdownCard: React.FC<ProfileDropdownCardProps> = () => {
  const { logout, auth, selectedCompany, setNewCompany } = useAuth();
  const dispatch = useDispatch();

  const menuData = [
    {
      icon: <UserIcon className="md:size-5 size-4" />,
      title: "My Account",
      navLink: "settings/account",
    },
    {
      icon: <TicketIcon className="md:size-5 size-4" />,
      title: "Subscriptions",
      navLink: "settings/subscriptions",
    },
    {
      icon: <ShoppingBagIcon className="md:size-5 size-4" />,
      title: "Orders",
      navLink: "settings/orders",
    },
  ];

  const renderCreateOrganizationBtn = () => (
    <div className="w-full flex items-center justify-center">
      <button
        className="my-1 py-1 px-2 text-[12px] bg-black text-white font-normal rounded-md hover:bg-gray-800"
        onClick={() => {
          dispatch(openModal("OrganizationModal"));
        }}
      >
        {/* <BuildingOffice2Icon className="size-5 text-black" /> */}
        Create Organization
      </button>
    </div>
  );

  return (
    <Menu as="div" className="relative text-left">
      <div className="flex items-center justify-between gap-2 bg-transparent p-2 rounded-lg">
        <div className="bg-geovetraGreen-700 text-white w-8 h-8 md:w-10 md:h-10 rounded-full flex items-center justify-center">
          {auth?.user?.name?.slice(0, 1).toUpperCase()}
        </div>

        <div className="flex items-start justify-center flex-col flex-1">
          <p className="text-xs md:text-sm">{auth?.user?.name}</p>
          {selectedCompany && (
            <a
              href={`${process.env.REACT_APP_URL}/${selectedCompany?.domain}`}
              className="text-[10px] md:text-xs text-blue-500 underline flex items-center gap-1"
              target="_blank"
            >
              <BuildingOffice2Icon className="size-3 md:size-4 text-black" />
              <span className="truncate overflow-hidden whitespace-nowrap w-28">
                {selectedCompany?.name}
              </span>
            </a>
          )}
        </div>
        <MenuButton>
          <div className="cursor-pointer origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0">
            <ChevronDownIcon className="size-4 md:size-5" />
          </div>
        </MenuButton>
      </div>

      <MenuItems
        as="div"
        modal={false}
        className="origin-top-right absolute right-0 mt-2 w-fit bg-white rounded-md shadow-md max-h-screen overflow-y-auto"
      >
        <div className="px-4 py-2 flex justify-between gap-2 items-center">
          <div className="w-full">
            <div className="flex items-center justify-center w-8 h-8 text-base rounded-full overflow-hidden bg-geovetraGreen-700 text-white">
              {auth?.user?.name?.slice(0, 1).toUpperCase()}
            </div>
          </div>
          <div>
            <p className="text-xs md:text-sm text-black">{auth?.user?.name}</p>
            <p className="text-[10px] md:text-xs text-gray-600">
              {auth?.user?.email}
            </p>
          </div>
        </div>

        {auth?.user_companies?.length === 0 ? (
          renderCreateOrganizationBtn()
        ) : (
          <Disclosure as="div" className="w-full flex flex-col px-2">
            <div className="pb-2 text-left px-4 py-2 text-sm text-black rounded-md flex items-center gap-2 bg-[#93c4fd43]">
              {selectedCompany?.logo ? (
                <img
                  src={selectedCompany?.logo}
                  className="size-5 md:size-6 rounded-full border border-gray-400"
                />
              ) : (
                <BuildingOffice2Icon className="size-5 md:size-6 text-black" />
              )}
              <div className="flex-1">
                <a
                  href={`${process.env.REACT_APP_URL}/${selectedCompany?.domain}`}
                  className="text-xs md:text-sm text-blue-500 underline"
                  target="_blank"
                >
                  {/* <span className="truncate overflow-hidden whitespace-nowrap w-10"> */}
                  {selectedCompany?.name}
                  {/* </span> */}
                </a>
              </div>
              <DisclosureButton>
                <ChevronUpDownIcon className="size-4 md:size-5 text-gray-500" />
              </DisclosureButton>
            </div>
            <div className="overflow-hidden bg-gray-100 rounded-lg">
              <DisclosurePanel className="origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0 p-2 px-2">
                {auth?.user_companies
                  ?.filter((e) => e.company?.id !== selectedCompany?.id)
                  ?.map((org, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          "flex flex-row items-center border-b-2  w-full gap-2 md:text-xs text-[10px] p-2 py-1 hover:bg-gray-200 cursor-pointer rounded-lg"
                        }
                        onClick={() => {
                          setNewCompany(org?.company);
                        }}
                      >
                        {org?.company?.logo ? (
                          <img
                            src={org?.company?.logo}
                            className="size-5 md:size-6 rounded-full border border-gray-400"
                          />
                        ) : (
                          <BuildingOffice2Icon className="size-5 md:size-6 text-black" />
                        )}
                        {org?.company?.name}
                      </div>
                    );
                  })}
                {auth?.user_companies &&
                  auth?.user_companies?.length < 5 &&
                  renderCreateOrganizationBtn()}
              </DisclosurePanel>
            </div>
          </Disclosure>
        )}
        {menuData.map((menu, index) => {
          return (
            <MenuItem as="div" className="px-2 my-1" key={index}>
              <NavLink
                to={`${menu.navLink}`}
                className={({ isActive }) =>
                  `px-4 py-2 text-xs md:text-sm text-black rounded-md flex items-center gap-2 hover:bg-geovetraGreen-100 transition-all
                ${
                  isActive
                    ? " bg-geovetraGreen-500 text-white hover:bg-geovetraGreen-500"
                    : "md:hover:bg-[#F9FAFB]"
                } 
                `
                }
              >
                {menu.icon}
                {menu.title}
              </NavLink>
            </MenuItem>
          );
        })}
        <MenuItem as="div" className="px-2 mb-2">
          <button
            className="flex items-center gap-2 text-left w-full rounded-md px-4 py-2 text-xs md:text-sm text-red-600 hover:bg-red-100 transition-all"
            onClick={() => logout()}
          >
            <ArrowRightStartOnRectangleIcon className="size-4 md:size-5" />
            Logout
          </button>
        </MenuItem>
      </MenuItems>
    </Menu>
  );
};

export default ProfileDropdownCard;
