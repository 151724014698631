import {
  BuildingOffice2Icon,
  ShoppingBagIcon,
  TicketIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { NavLink, Outlet } from "react-router-dom";
import OrganizationNavCard from "../components/atoms/OrganizationNavCard";

interface SettingsLayoutProps {}

const SettingsLayout: React.FC<SettingsLayoutProps> = () => {
  const menuList = [
    {
      icon: <UserIcon className="md:size-5 size-4" />,
      title: "My Accounts",
      navLink: "account",
    },
    {
      icon: <BuildingOffice2Icon className="md:size-5 size-4" />,
      title: "Organizations",
      navLink: "organization",
    },
    {
      icon: <TicketIcon className="md:size-5 size-4" />,
      title: "Subscriptions",
      navLink: "subscriptions",
    },
    {
      icon: <ShoppingBagIcon className="md:size-5 size-4" />,
      title: "Orders",
      navLink: "orders",
    },
  ];

  return (
    <div className="w-full flex flex-col md:flex-row justify-between items-start">
      <div className="hidden md:flex border-r border-gray-300 h-[90vh] bg-white flex-col justify-between items-center">
        <div className="flex flex-col p-4 gap-2">
          {menuList.map((btn, index) => {
            return (
              <ul key={index}>
                <NavLink
                  to={`${btn.navLink}`}
                  className={({ isActive }) =>
                    `flex items-center gap-2 rounded-xl font-medium px-4 py-2 pe-20 text-xs md:text-sm transition-all ${
                      isActive
                        ? " bg-geovetraGreen-500 text-white hover:bg-geovetraGreen-500"
                        : "md:hover:bg-[#F9FAFB]"
                    }`
                  }
                >
                  {btn.icon}
                  {btn.title}
                </NavLink>
              </ul>
            );
          })}
        </div>
        <div className="p-8 w-full mb-4">
          <OrganizationNavCard />
        </div>
      </div>

      <div className="md:hidden mb-4 border-b pb-2 border-gray-200 flex w-full overflow-y-scroll no-scrollbar items-center justify-between gap-2">
        {menuList.map((btn, index) => {
          return (
            <ul key={index} className="flex text-sm font-medium text-center">
              <NavLink
                to={`${btn.navLink}`}
                className={({ isActive }) =>
                  `md:hover:bg-geovetraGreen-200 flex items-center gap-1 rounded-xl font-medium px-4 py-4 text-xs md:text-sm transition-all ${
                    isActive
                      ? " bg-geovetraGreen-100 text-geovetraGreen-700"
                      : ""
                  }`
                }
              >
                {btn.icon}
                {btn.title}
              </NavLink>
            </ul>
          );
        })}
      </div>

      <div className="flex-1 flex px-4 justify-center mx-auto max-w-7xl">
        <Outlet />
      </div>
    </div>
  );
};

export default SettingsLayout;
