import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import api from "../../helpers/api";
import { IResponse, useAuth } from "../../providers/AuthContext";
import {
  AllowedCompanyType,
  AllowedOrderStatus,
  AllowedSubscriptionType,
  ISubscription,
} from "../../types/CommonType";
import OrderModal from "../../components/modals/OrderModal";
import PrimaryButton from "../../components/atoms/PrimaryButton";
import {
  GlobeAltIcon,
  NoSymbolIcon,
  PlusIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline";
import SubscriptionSVG from "../../assets/svg-icons/SubscriptionSVG";
import { NavLink, useNavigate } from "react-router-dom";
import RenewSubscriptionModal from "../../components/modals/RenewSubscriptionModal";
import { times } from "lodash";

interface SubscriptionsProps {}

const Subscriptions: React.FC<SubscriptionsProps> = () => {
  const dispatch = useDispatch();
  const [subscription, setSubscription] = useState<ISubscription[]>();
  const [orderId, setOrderId] = useState();
  const { selectedCompany } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openRenewSubscriptionModal, setOpenRenewSubscriptionModal] =
    useState(false);
  const [selectedList, setSelectedList] = useState<ISubscription[]>();
  const [count, setCount] = useState() as any;
  const [selectedFilter, setselectedFilter] = useState("all");

  const toggleModal = (visible: boolean) => {
    if (visible) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  };

  const toggleRenewSubscriptionModal = (visibleRenewSubscription: boolean) => {
    if (visibleRenewSubscription) {
      setOpenRenewSubscriptionModal(true);
    } else {
      setOpenRenewSubscriptionModal(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    api
      .GET<ISubscription[]>(
        `${selectedCompany?.domain}/recharges_main?search=${selectedFilter}`,
        {}
      )
      .then((response) => {
        const subData = response?.data;
        if (subData) {
          setSubscription(subData);
          const checkCount = subData.length;
          setCount(checkCount);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedFilter, selectedCompany]);

  const handleSingleSelect = (e: any, sub: ISubscription) => {
    if (e?.target.checked) {
      const list =
        selectedList && selectedList?.length > 0
          ? [...selectedList, sub]
          : [sub];
      setSelectedList(list);
    } else {
      const list = selectedList?.filter((s) => s?.id != sub?.id);
      setSelectedList(list);
    }
  };

  const handleAllSelect = (e: any) => {
    setSelectedList(
      e?.target.checked
        ? subscription?.filter(
            (s) => !(s?.last_active_recharge?.days_left >= 0)
          )
        : []
    );
  };

  const handleSelectChange = (event: any) => {
    setselectedFilter(event.target.value);
  };

  const renderSkeleton = (t: number) => (
    <div
      className="flex items-start justify-between px-2 py-4 md:p-4 md:px-6 w-full rounded-xl bg-[#F9FAFB] shadow mb-4 animate-pulse"
      key={t}
    >
      <div></div>
      <div className="w-full">
        <div className="flex justify-between items-center mb-2">
          <div className="h-5 bg-orange-200 rounded-xl w-36"></div>
          <div className="h-7 bg-teal-200 rounded-xl w-20"></div>
        </div>
        <div className="flex justify-between items-start mb-2">
          <div className="h-5 bg-gray-200 rounded-xl w-48"></div>
          <div className="h-6 bg-yellow-100 rounded-xl w-16"></div>
        </div>
        <div className="flex justify-between items-center mt-5">
          <div className="h-5 bg-gray-200 rounded-xl w-24"></div>
          <div className="h-4 bg-blue-200 rounded-xl w-16"></div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="h-[90vh] w-full relative">
      <div className="overflow-scroll h-full">
        <div className="flex justify-between items-center gap-2 w-full mb-4">
          <p className="flex items-center gap-1 text-gray-600 font-semibold text-xs md:text-base">
            Your Subscriptions ({count})
            {selectedFilter != "active" && (
              <input
                id="inline-checkbox"
                type="checkbox"
                // @ts-ignore
                value={subscription?.value}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                onChange={handleAllSelect}
                checked={
                  selectedList?.length ===
                  subscription?.filter(
                    (s) => !(s?.last_active_recharge?.days_left >= 0)
                  )?.length
                }
              />
            )}
          </p>
          <div className="flex gap-2 items-center">
            <form className="">
              <select
                id="countries"
                className="border text-gray-900 text-xs md:text-sm rounded-lg block w-full p-2"
                onChange={handleSelectChange}
              >
                <option selected value={"all"}>
                  All Devices
                </option>
                <option value={"active"}>Active Devices</option>
                <option value="expired">Expired Devices</option>
              </select>
            </form>
            <NavLink to={"/checkout"}>
              <PrimaryButton btnClassName="text-xs h-10">
                {/* <ShoppingCartIcon className="size-4 mr-1" /> */}
                <PlusIcon className="size-5 mr-1" />
                Subscriptions
              </PrimaryButton>
            </NavLink>
          </div>
        </div>

        {loading ? (
          times(4).map((t) => renderSkeleton(t))
        ) : subscription?.length == 0 ? (
          <div className="w-full flex justify-center">
            <p className="flex flex-col w-fit justify-center items-center gap-4 p-8 rounded-lg font-semibold text-xs md:text-base">
              <SubscriptionSVG className="w-40 md:w-60" />
              You don't have {selectedFilter === "expired"
                ? "expired"
                : "any"}{" "}
              subscription
              <NavLink to={"/checkout"}>
                <PrimaryButton>
                  Get Subscriptions
                </PrimaryButton>
              </NavLink>
            </p>
          </div>
        ) : (
          subscription?.map((item, index) => {
            return (
              <div
                key={index}
                className={`flex items-start justify-between px-2 py-4 md:p-4 md:px-6 w-full rounded-xl bg-[#F9FAFB] shadow mb-4 ${
                  item?.type === AllowedSubscriptionType.DEVICE
                    ? "hover:bg-gray-200 "
                    : ""
                }`}
              >
                <div>
                  {item.last_active_recharge?.days_left > 0 ? (
                    <></>
                  ) : (
                    <input
                      id="inline-checkbox"
                      type="checkbox"
                      value={item.id}
                      checked={
                        (selectedList?.filter((s) => s?.id === item?.id)
                          ?.length ?? 0) > 0
                      }
                      className="w-4 h-4 mr-4 mt-2 text-blue-600 bg-gray-100 border-gray-300 rounded"
                      onChange={(e) => {
                        handleSingleSelect(e, item);
                      }}
                    />
                  )}
                </div>
                <div className="w-full">
                  <div className="flex justify-between items-center mb-2">
                    <p className="bg-orange-100 p-1 px-2 rounded-xl w-fit text-xs text-end text-orange-600 font-medium">
                      {item?.subscription_number}
                    </p>
                    {item?.last_active_recharge?.days_left > 0 ? (
                      <p className="flex items-center gap-1 bg-teal-100 rounded-xl py-2 px-3 text-teal-800 text-xs font-normal md:text-sm md:font-medium">
                        <GlobeAltIcon className="size-4 md:size-5" />{" "}
                        {item?.last_active_recharge?.days_left} Days Left
                      </p>
                    ) : (
                      <p className="flex gap-1 items-center bg-red-100 rounded-xl py-2 px-3 text-red-700 text-xs font-normal md:text-sm md:font-semibold">
                        <NoSymbolIcon className="size-4 md:size-5" /> Expired
                      </p>
                    )}
                  </div>
                  <div className={`flex justify-between items-start mb-2`}>
                    <p className="text-sm font-medium">
                      IMEI:{" "}
                      {item?.active_device?.imei
                        ? item?.active_device?.imei
                        : "N/A"}
                    </p>
                    <p
                      className={`text-sm font-medium py-1 px-2 rounded-2xl ${
                        item?.type === AllowedSubscriptionType.DEVICE
                          ? "bg-blue-100 text-blue-800"
                          : "bg-yellow-100 text-yellow-700"
                      }`}
                    >
                      {item?.type &&
                        item?.type?.slice(0, 1).toUpperCase() +
                          item?.type?.slice(1)}{" "}
                    </p>
                  </div>
                  <div className="flex justify-between items-center">
                    <div>
                      {item?.type === AllowedSubscriptionType.LICENSE ? (
                        <p className="flex flex-col text-sm font-medium">
                          {item?.last_active_recharge?.start_date?.date}
                          <span>
                            {item?.last_active_recharge?.start_date?.time}
                          </span>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <button
                      className="text-xs font-medium text-blue-500 hover:text-blue-700 underline"
                      onClick={() => {
                        setOrderId(
                          item?.subscription_recharges[0]?.recharge?.order_id
                        );
                        setOpenModal(true);
                      }}
                    >
                      View Order
                    </button>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
      <div
        className={`absolute w-full flex justify-end items-center overflow-hidden bg-white rounded-t-lg px-5 h-[80px] top-[85%] checkout-container ${
          (selectedList?.length ?? 0) <= 0 && "close"
        }`}
      >
        <div className="flex justify-center items-center gap-6 ">
          <p className="text-gray-600 font-semibold text-xs md:text-base">
            Selected Items ({selectedList?.length})
          </p>
          <button
            className="py-2.5 px-5 text-sm font-medium text-white bg-yellow-500 rounded-xl hover:bg-yellow-600 inline-flex items-center gap-1"
            onClick={() => {
              setOpenRenewSubscriptionModal(true);
            }}
          >
            <ShoppingCartIcon className="size-5" /> Checkout
          </button>
        </div>
      </div>

      <OrderModal
        visible={openModal}
        setVisible={toggleModal}
        orderId={orderId}
      />

      <RenewSubscriptionModal
        visible={openRenewSubscriptionModal}
        setVisible={toggleRenewSubscriptionModal}
        subscriptions={selectedList}
      />
    </div>
  );
};

export default Subscriptions;
