import { UseFormSetError } from "react-hook-form";
import { IErrors } from "../providers/AuthContext";
// @ts-ignore
const setFormErrors = <T>(errors: IErrors<T>, setError: UseFormSetError<T>) => {
  if (errors) {
    Object.keys(errors).map((key, i) => {
      // @ts-ignore
      setError(key, { message: errors[key][0] }, { shouldFocus: true });
    });
  }
};
export default setFormErrors;
