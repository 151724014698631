import React, { useEffect, useState } from "react";
import SearchableInput from "../SearchableInput";
import { IPincode } from "../../../types/CommonType";
import api from "../../../helpers/api";
import { IResponse } from "../../../providers/AuthContext";
import toast from "react-hot-toast";
import { UseFormSetValue } from "react-hook-form";

interface PincodeSearchableInputProps {
  formKey: string;
  register?: any;
  error?: string;
  setValue: UseFormSetValue<any>;
  watch: any;
}

const PincodeSearchableInput: React.FC<PincodeSearchableInputProps> = ({
  formKey,
  register,
  error,
  setValue,
  watch,
}) => {
  const [pincodes, setPincodes] = useState<IPincode[]>([]);
  const [initialPincodes, setInitialPincodes] = useState<IPincode[]>([]);
  const [selectedPincode, setSelectedPincode] = useState<IPincode>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSearchPincodes = (text?: string) => {
    if ((text && text?.length > 0) || initialPincodes.length === 0) {
      setLoading(true);
      const fetchOptions = {
        populate: JSON.stringify(["city", "state", "country"]),
      };
      api
        .GET<IResponse<IPincode[]>>(
          "pincodes",
          text ? { ...fetchOptions, search: text } : fetchOptions
        )
        .then((response) => {
          if (response.data?.data) {
            setPincodes(response.data?.data);
            if (!text) {
              setInitialPincodes(response.data?.data);
            }
          } else {
            setPincodes([]);
          }
        })
        .catch((er) => {
          toast.error(er.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setPincodes(initialPincodes);
    }
  };

  useEffect(() => {
    handleSearchPincodes();
  }, []);

  return (
    <SearchableInput<IPincode>
      required
      data={pincodes}
      loading={loading}
      selectedOption={watch(formKey)}
      onSearchText={(query) => {
        handleSearchPincodes(query);
      }}
      onSelectChange={(value) => {
        setSelectedPincode(value);
        setValue(formKey, value);
        setValue("city", value?.city);
        setValue("state", value?.state);
        setValue("country", value?.country);
      }}
      renderOption={(pincode) => pincode?.name}
      renderSelectedOption={(pincode) => pincode?.name}
      key={"pincode"}
      label="Pincode"
      error={error}
      delaySearchText={1000}
    />
  );
};

export default PincodeSearchableInput;
