import CardSection from "./dashboard/CardSection";
import FAQSection from "./dashboard/FAQSection";
import HeroSection from "./dashboard/HeroSection";
import BackgroundBlob from "../components/atoms/BackgroundBlob";
import CounterSection from "./dashboard/CounterSecton";
import InfoSection from "./dashboard/InfoSection";

const Home = () => {
  return (
    <>
      <BackgroundBlob />
      <HeroSection />
      <InfoSection />
      <CounterSection />
      <CardSection />
      <FAQSection />
    </>
  );
};
export default Home;
