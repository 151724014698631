const fetchNumberOfLicences = () => {
  let licences = [];
  let incremantalValue = 0;
  const numberofLicenses =
    Number(
      process.env.REACT_APP_NUMBER_OF_LICENCE &&
        process.env.REACT_APP_NUMBER_OF_LICENCE !== "0"
        ? process.env.REACT_APP_NUMBER_OF_LICENCE
        : "10"
    ) ?? 10;
  for (
    incremantalValue = numberofLicenses;
    incremantalValue <= 100;
    incremantalValue += numberofLicenses
  ) {
    licences.push(`${incremantalValue}`);
  }
  return licences;
};

export const NumberOfLicences = fetchNumberOfLicences();

export const DurationOfRecharges = ["91", "183", "365"];
