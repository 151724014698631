import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { squircle } from "ldrs";
import useFormMode from "../../redux/hooks/useFormMode";

interface SearchableInputProps<T> {
  label?: string;
  error?: string;
  data?: T[];
  selectedOption?: T;
  onSelectChange: (value: T) => void;
  onSearchText: (text: string) => void;
  renderSelectedOption: (option: T) => any;
  renderOption: (option: T) => any;
  delaySearchText?: number;
  loading: boolean;
  required?: boolean;
}

export default function SearchableInput<T>({
  label,
  error,
  data = [],
  selectedOption,
  onSelectChange,
  onSearchText,
  renderSelectedOption,
  renderOption,
  delaySearchText = 500,
  loading = false,
  required = false,
}: SearchableInputProps<T>) {
  const [query, setQuery] = useState("");
  const { formMode } = useFormMode();

  const searchTextDebounce = debounce(() => {
    formMode !== "read" && onSearchText(query);
  }, delaySearchText);

  useEffect(() => {
    searchTextDebounce();
    return () => {
      searchTextDebounce.cancel();
    };
  }, [query]);

  return (
    <div className="flex w-full flex-col gap-1">
      {label && (
        <label className="text-xs md:text-sm font-medium">
          {label} {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <Combobox
        value={selectedOption ?? null}
        onChange={onSelectChange}
        onClose={() => setQuery("")}
      >
        <ComboboxButton disabled={formMode === "read"}>
          <div className="relative">
            <ComboboxInput
              disabled={formMode === "read"}
              className={
                "p-2 rounded-md text-xs md:text-sm font-normal border w-full"
              }
              placeholder={`Search or Select ${label}`}
              displayValue={renderSelectedOption}
              onChange={(event) => setQuery(event.target.value)}
            />
            <div className="absolute right-0 top-2.5 px-2.5">
              <ChevronDownIcon className="size-4 fill-black/60 group-data-[hover]:fill-black" />
            </div>
          </div>
        </ComboboxButton>

        <ComboboxOptions
          anchor="bottom"
          className={
            "w-[var(--input-width)] z-50 rounded-xl border border-gray-200 shadow-xl bg-white [--anchor-gap:var(--spacing-1)] empty:invisible transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
          }
        >
          {loading || data?.length === 0 ? (
            <ComboboxOption
              disabled={true}
              key={"defaultLoader_or_NotFound"}
              value={"defaultLoader_or_NotFound"}
              className="group flex items-center cursor-not-allowed gap-2 rounded-md py-1.5 px-3 select-none data-[disabled]:opacity-75"
            >
              <div className="text-sm/6 text-black text-center w-full italic">
                {loading ? (
                  <l-squircle
                    size="20"
                    stroke="3"
                    stroke-length="0.15"
                    bg-opacity="0.3"
                    speed="0.9"
                    color="black"
                  ></l-squircle>
                ) : data?.length === 0 ? (
                  `${label} Not Found`
                ) : (
                  <></>
                )}
              </div>
            </ComboboxOption>
          ) : (
            data?.map((value, i) => (
              <ComboboxOption
                key={i}
                value={value}
                className="group flex items-center cursor-pointer gap-2 rounded-md py-1.5 px-3 select-none group-data-[selected]:bg-black group-data-[selected]:text-white data-[focus]:bg-gray-200 hover:bg-gray-200"
              >
                <div className="text-sm/6 text-black">
                  {renderOption(value)}
                </div>
              </ComboboxOption>
            ))
          )}
        </ComboboxOptions>
      </Combobox>
      {error && <p className="text-red-500 text-xs">{error}</p>}
    </div>
  );
}
