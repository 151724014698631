import { useState } from "react";
import GetSvg from "../../helpers/GetSvg";
import { FormMode } from "../../types/CommonType";
import useFormMode from "../../redux/hooks/useFormMode";

interface InputFieldProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  inputClassName?: string;
  label?: string;
  error?: string;
  register?: any;
  required?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
  inputClassName,
  label,
  error,
  register,
  required = false,
  ...props
}) => {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const { formMode } = useFormMode();
  return (
    <div className="flex w-full flex-col gap-1">
      {label && (
        <label className="text-xs md:text-sm font-medium">
          {label} {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <div className="relative">
        <input
          {...register}
          className={`text-black border p-2 rounded-md text-xs md:text-sm font-normal md:w-64 ${inputClassName} ${
            error &&
            `border-red-500 focus-visible:border-red-500 focus-visible:outline-red-500`
          }`}
          disabled={formMode === "read"}
          {...props}
          type={
            props?.type === "password"
              ? passwordVisible
                ? "text"
                : "password"
              : props?.type
          }
        />
        {props?.type === "password" ? (
          <div
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 max-w-full"
            onClick={() => {
              setPasswordVisible(!passwordVisible);
            }}
          >
            {passwordVisible ? (
              <GetSvg type="EYECLOSE" className="text-gray-400" />
            ) : (
              <GetSvg type="EYEOPEN" className="text-gray-400" />
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      {error && (
        <p
          className={`text-red-500 text-xs ${
            props?.type === "password" ? "max-w-60" : ""
          }`}
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default InputField;
